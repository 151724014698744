import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Ord_Client } from '../_model/ord_client';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class OrdClienteService {

  Ord_ClientCambio = new Subject<Ord_Client[]>();
  mensajeCambio = new Subject<string>();

  url: string = `${environment.HOST}/ordclientes`;
  
  constructor(private http: HttpClient) { }

  listar(){
    return this.http.get<Ord_Client[]>(this.url,this.getToken());
  }
  
  buscarTodosPedidos(){
    return this.http.get<Ord_Client[]>(`${this.url}/buscartodos`,this.getToken());
  }
  
  buscarPedidosVendedor(idccmaven : number){
    return this.http.get<Ord_Client[]>(`${this.url}/vendedor/${idccmaven}`,this.getToken());
  }

  listarPorId(idOrd_Client : number){
    return this.http.get<Ord_Client>(`${this.url}/${idOrd_Client}`,this.getToken());
  }

  registrar(famaord : Ord_Client){
    return this.http.post(this.url, famaord,this.getToken());
  }

  modificar(famaord : Ord_Client){
    return this.http.put(this.url, famaord,this.getToken());
  }

  eliminar(idOrd_Client : number){
    return this.http.delete(`${this.url}/${idOrd_Client}`,this.getToken());
  }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }
}
