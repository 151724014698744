import { Usuario } from '../../_model/seguridad/usuario';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt/src/jwthelper.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  usuarioCambio = new Subject<Usuario[]>();
  mensajeCambio = new Subject<string>();

  url: string = `${environment.HOST}/usuarios`;
  http_Options : HttpHeaders;

  constructor(private http: HttpClient) {
   }

  listar(){
    return this.http.get<Usuario[]>(this.url,this.getToken());
  }

  listarPorId(idUsuario : number){
    return this.http.get<Usuario>(`${this.url}/${idUsuario}`,this.getToken());
  }

  buscarPorNombre(nombreUsuario : string){
    return this.http.get<Usuario>(`${this.url}/username/${nombreUsuario}`,this.getToken());
  }

  registrar(usuario : Usuario){
    return this.http.post(this.url, usuario,this.getToken());
  }

  modificar(usuario : Usuario){
    return this.http.put(this.url, usuario,this.getToken());
  }

  eliminar(idUsuario : number){
    return this.http.delete(`${this.url}/${idUsuario}`,this.getToken());
  }

  validarUsuario(username : string, password : string){
    return this.http.get<boolean>(`${this.url}/validar/${username}/${password}`,this.getToken());
  }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }
}
