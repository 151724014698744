<!DOCTYPE html>
<html lang="en">

<head>
  <!-- Simple HttpErrorPages | MIT X11 License | https://github.com/AndiDittrich/HttpErrorPages -->
  <meta charset="utf-8" />
  <meta http-equiv="X-UA-Compatible" content="IE=edge" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />

  <title>403 - Access Denegado</title>
</head>

<body>
  <div class="cover">
    <h1>Acceso Denegado
      <small>Error 403</small>
    </h1>
    <p class="lead">¡ {{usuario }} no tienes permisos para entrar !</p>
    <a routerLink="/paciente">Ir a pacientes</a>
  </div>

</body>

</html>