export class Ccmacli{
    idccmacl : number;
	codclie : string;
	rutclie : string;
	apecli1 : string;
	apecli2 : string;
	nomclie : string;
	nomnit : string;
	telclie : string;
	dirclie : string;
	numclie : string;
	deppais : number;
	depempr : number;
	nomnego : string;
	dirnego : string;
	telnego : string;
	girnego : string;
	tipnego : number;
	nomecom : string;
	fecicre : string;
	limcred : number;
	credisp : number;
	diaplaz : number;
	docgara : string;
	vsalini : number;//double;
	vcarmes : number;//double;
	vabomes : number;//double;
	vcartem : number;//double;
	vabotem : number;//double;
	vsalact : number;//double;
	vsalian : number;//double;
	vcaracu : number;//double;
	vaboacu : number;//double;
	tpdocid : string;
	nitclie : string;
	nrcclie : string;
	nmdocid : string;
	fecumov : string;
	documov : number;
	valumov : number;
	claclie : number;
	salcorr : number;//double;
	salmas0 : number;//double;
	salmas3 : number;//double;
	salmas6 : number;//double;
	salmas9 : number;//double;
	codcobl : string;
	clacont : string;
	retniva : string;
	estclie : string;
	feccort : string;
	estcort : string;
	codaux1 : string;
	codorig : string;
	ctrlsal : string;
	lisprec : string;
	fultant : string;
	candocs : string;
    nitnomb : string;
	sucursal : string;
	munclie : string;

	codizon : string;
	mail_dte : string;
	mail_alt : string;
	celwapp : string;
	celcontac : string;
	fecalta : string;

	mhcodgiro : string;
	mhtpperso : string;
	mhidclasi : string;
	mhiddpto : string;
	mhidmuni : string;
	mhiddistri : string;
	mhcondic : string;

	email : string;
	whatsapp : number;
	departamento_DTE : string;
	municipio_DTE : string;
}
