import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Archivo } from 'src/app/_model/archivo';
import { Message } from 'src/app/_model/utils/message';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubirreportesService {

  url: string = `${environment.HOST}/reportes`;

  constructor(private http: HttpClient) { }

  subirJson(data: File) {
    let formdata : FormData = new FormData();
    formdata.append('jasperfile',data);

    return this.http.post<Message[]>(`${this.url}/subir_repote`, formdata,this.getToken());
  }

  actulizarjson(data: File, jasperFile : Archivo) {
    let formdata : FormData = new FormData();
    formdata.append('jasperfile',data);
    formdata.append('idjasperfile',jasperFile.idArchivo.toString());
    console.log(jasperFile);
    return this.http.post<Message[]>(`${this.url}/modificar_reporte`, formdata,this.getToken());
  }

  listar(){
    return this.http.get<Archivo[]>(this.url,this.getToken());
  }

  listarPorId(id : number){
    return this.http.get<Archivo>(`${this.url}/${id}`,this.getToken());
  }

  registrar(jsonFileMH : Archivo){
    return this.http.post(this.url, jsonFileMH,this.getToken());
  }

  modificar(jsonFileMH : Archivo){
    return this.http.put(this.url, jsonFileMH,this.getToken());
  }

  

  eliminar(id : number){
    return this.http.delete(`${this.url}/${id}`,this.getToken());
  }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }
}

