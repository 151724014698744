import { Famadoc } from './../../_model/famadoc';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FacturasService {

  famadocCambio = new Subject<Famadoc[]>();
  mensajeCambio = new Subject<string>();

  url: string = `${environment.HOST}/detalle_facturacion`;
  
  constructor(private http: HttpClient) { }

  importarDBF(id : number,fecha1 : string,fecha2 : string){
    return this.http.get<Famadoc[]>(`${this.url}/importardbf/${id}/${fecha1}/${fecha2}`,this.getToken());
  }

  guardarDBF(lista : Famadoc[]){
    return this.http.post<Famadoc[]>(`${this.url}/guardardbf`, lista, this.getToken());
  }

  buscardocumento(id : number, coddocu : string){
    return this.http.get<Famadoc[]>(`${this.url}/buscardocumento/${id}/${coddocu}`,this.getToken());
  }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }
}
