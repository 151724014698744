
    <H2>Documento a generar</H2>
    <mat-grid-list cols="2" rowHeight="50px" >
        <mat-grid-tile>
            <button mat-raised-button color="primary"  (click)="descargarPDF()" >DESCARGAR PEDIDO</button>
        </mat-grid-tile>
        <!--
        <mat-grid-tile *ngIf="getIsfactura()">
            <button mat-raised-button color="primary"  (click)="factura_DTE()" >FACTURA DTE</button>
        </mat-grid-tile>
        <mat-grid-tile *ngIf="getIsccf()">
            <button mat-raised-button color="primary"  (click)="ccf_DTE()" >CREDITO FISCAL DTE</button>
        </mat-grid-tile>
        -->
        <mat-grid-tile *ngIf="isAndroid()">
            <button mat-flat-button color="primary"  (click)="impresionTicket()" >IMPRIMIR TICKET</button>
        </mat-grid-tile>
    </mat-grid-list>
    <!-- <br>
    <div class="example-button-row">
        <button mat-flat-button color="accent"  (click)="impresionRemota()" >IMPRESION REMOTA</button>
    </div>
    <br>
    <div class="example-button-row">
        <button mat-flat-button color="accent"  (click)="impresionTicket()" >IMPRIMIR TICKET</button>
    </div>
    <br>
    <div class="example-button-row">
        <button mat-flat-button color="accent"  (click)="impresionRecibo()" >IMPRIMIR RECIBO</button>
    </div> -->

