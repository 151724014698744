<div class="example-loading-shade"
       *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
      
    </div>
  </div>

<mat-toolbar>Asignar clientes a vendedores</mat-toolbar>

<div class="example-container mat-elevation-z8">

  <div class="example-header">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
    </mat-form-field>
  </div>

  <mat-table [dataSource]="dataSource" matSort >

    <ng-container matColumnDef="usuario">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Codigo </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.codruta}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="empleado">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Vendedor </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.nomruta}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="acciones">
      <mat-header-cell *matHeaderCellDef>Acciones</mat-header-cell>
      <mat-cell *matCellDef="let row">        
        <button mat-button color="white" (click)="clientesDialog(row)">Clientes</button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;">
    </mat-row>
  </mat-table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>