<h4>
  Cliente: {{sucursal}}
  <br>
  Direccion de facturacion: {{dirfac}}
</h4>
<mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="cantidad">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Cant</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.cantidad}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="descripcion">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Producto</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.descripcion}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="precioUni">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Precio</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.precioUni | currency}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="montoDescu">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Descuento</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.montoDescu | currency}} </mat-cell>
      </ng-container>

    <ng-container matColumnDef="ventaGravada">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Total</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.ventaGravada | currency}} </mat-cell>
    </ng-container>      

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"> 
    </mat-row>
  </mat-table>

  <div class="total-container">
    <table>
        <tr>
          <td>SUB-TOTAL:</td>
          <td style="text-align: right;">{{resumen.subTotal | currency}}</td>
        </tr>
        <tr>
          <td>TOTAL A PAGAR:</td>
          <td style="text-align: right;">{{resumen.totalPagar | currency}}</td>
        </tr>
      </table>
  </div>

  <div class="button-container">
    <button  mat-flat-button color="primary" (click)="facturar();" >Facturar</button>
    <button mat-flat-button color="warn" (click)="cerrarDialog();">Cerrar</button>
  </div>