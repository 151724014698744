import { Rol } from './rol';

export class Usuario{
    usr_idUsuario: number;
    username: string;
    password: string;
    roles : Rol[] = []
    usr_estado: boolean;
    nombre : string;
    email : string;
}