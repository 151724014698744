import { UsuarioService } from './../../../_service/seguridad/usuario.service';
import { UsuariorolService } from './../../../_service/rol/usuariorol.service';
import { Usuario } from '../../../_model/seguridad/usuario';
import { Component, OnInit, Inject } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { Rol } from 'src/app/_model/seguridad/rol';
import { RolService } from 'src/app/_service/seguridad/rol.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsuarioRol } from 'src/app/_model/seguridad/usuariorol';

@Component({
  selector: 'app-dialog-usuario',
  templateUrl: './dialog-usuario.component.html',
  styleUrls: ['./dialog-usuario.component.css']
})
export class DialogUsuarioComponent implements OnInit {
  isLoadingResults = false;
  isRateLimitReached = false;

  usuario : Usuario;
  roles : Rol[] = [];
  rol_idRolSelected : number;

  constructor(private dialogRef: MatDialogRef<DialogUsuarioComponent>, @Inject(MAT_DIALOG_DATA) private data: Usuario, private usuarioService : UsuarioService, private rolService : RolService,
  private usuariorolService : UsuariorolService ) { }

  ngOnInit() {
    this.listarRoles();
    this.usuario = new Usuario();
    this.usuario = this.data;
    if(this.usuario.roles.length > 0){
      this.rol_idRolSelected = this.usuario.roles[0].rol_idRol
    }
  }

  listarRoles(){
    this.rolService.listar().subscribe(data => {
      this.roles = data;
    });
  }

  onChange(){

  }

  cancelar() {
    this.dialogRef.close();
  }

  guardar() {
    this.isLoadingResults = true;
    this.isRateLimitReached = true;
    if (this.usuario != null && this.usuario.usr_idUsuario > 0) {
      this.usuarioService.modificar(this.usuario).pipe(switchMap(() => {        
        return this.usuarioService.listar();
      })).subscribe(usuarios => {
        this.usuariorolService.buscarporusuario(this.usuario.usr_idUsuario).subscribe(p_userrol => {
          console.log(p_userrol);
          if(p_userrol.length > 0){
            p_userrol[0].rol_idrol = this.rol_idRolSelected;
            this.usuariorolService.modificar(p_userrol[0]).subscribe(result => {
              this.usuarioService.usuarioCambio.next(usuarios);
              this.usuarioService.mensajeCambio.next("SE MODIFICO");
              this.isLoadingResults = false;
              this.isRateLimitReached = false;
            })
          }else{
            let userrol : UsuarioRol = new UsuarioRol();
            userrol.rol_idrol = this.rol_idRolSelected;
            userrol.usr_idUsuario = this.usuario.usr_idUsuario;
            this.usuariorolService.registrar(userrol).subscribe(result => {
              this.usuarioService.usuarioCambio.next(usuarios);
              this.usuarioService.mensajeCambio.next("SE MODIFICO");
              this.isLoadingResults = false;
              this.isRateLimitReached = false;
            })
          }
        })
        
      });
    } else {
      
      this.usuarioService.registrar(this.usuario).subscribe(() => {
        this.usuarioService.listar().subscribe(usuarios => {
          let userrol : UsuarioRol = new UsuarioRol();
          userrol.rol_idrol = this.rol_idRolSelected;
          userrol.usr_idUsuario = usuarios[usuarios.length -1].usr_idUsuario;
          this.usuariorolService.registrar(userrol).subscribe(result => {
            this.usuarioService.usuarioCambio.next(usuarios);
            this.usuarioService.mensajeCambio.next("SE REGISTRO");
            this.isLoadingResults = false;
            this.isRateLimitReached = false;
          })
        });
      });
    }
    this.dialogRef.close();
  }
}
