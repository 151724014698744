<div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"> Cargando </mat-spinner>
    <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
        
    </div>
  </div>
  <h2>FACTURA EXPORTACION</h2>
  <div *ngIf="activarFormulario">
    <div class="main-div">
      <mat-card>
        <mat-grid-list cols="2" rowHeight="5:1">
          <mat-grid-tile>
            Nombre o razón social: {{selectCliente.nomclie}}
            <br>
            Nombre Comercial: {{selectCliente.nomnego}}
            <br>
            Num Doc: {{selectCliente.nitclie}}
            <br>
            Act. Economica: {{selectCliente.pvgirng}}
            <br>
            Telefono: {{getTelefono()}}
          </mat-grid-tile>
          <mat-grid-tile>
            Nit: {{selectCliente.nitclie}}
            <br>
            Correo: {{selectCliente.pvmail}}
            <br>
            Pais:
            <br>
            Direccion: {{selectCliente.pvdirec}}
          </mat-grid-tile>
        </mat-grid-list>
  
      </mat-card>
    </div>
</div>

