import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Inbogrl } from 'src/app/_model/inbogrl';
import { InbogrlService } from 'src/app/_service/inbogrl.service';

@Component({
  selector: 'app-dialogselectinbogrl',
  templateUrl: './dialogselectinbogrl.component.html',
  styleUrls: ['./dialogselectinbogrl.component.css']
})
export class DialogselectinbogrlComponent implements OnInit {

  displayedColumns = ['codigo_ln', 'descrip_ln','acciones'];
  dataSource: MatTableDataSource<Inbogrl>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private inbogrlService : InbogrlService,
              private snackBar: MatSnackBar,
              private dialogRef: MatDialogRef<DialogselectinbogrlComponent>
            ) { }

  ngOnInit() {
    this.inbogrlService.ListarProductosActivos().subscribe(productos => {
      this.inbogrlService.InbogrlCambio.next(productos);
    })

    this.inbogrlService.InbogrlCambio.subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });

    this.inbogrlService.mensajeCambio.subscribe(data => {
      this.snackBar.open(data, 'AVISO',{
        duration:2000
      });
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  closeDialog(row : Inbogrl){
    if(row != undefined){
      this.dialogRef.close({event:'close',data:row});
    }else{
      this.dialogRef.close();
    }
  }
}
