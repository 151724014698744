import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Cpmaprv } from 'src/app/_model/cpmaprv';
import { CpmaprvService } from 'src/app/_service/proveedores/cpmaprv.service';

@Component({
  selector: 'app-dialogselecionarprov',
  templateUrl: './dialogselecionarprov.component.html',
  styleUrls: ['./dialogselecionarprov.component.css']
})
export class DialogselecionarprovComponent implements OnInit {

  isLoadingResults = false;
  isRateLimitReached = false;

  activeTable = false;
  msj_spinner : string = "Cargando";
  isActualizarProvedores : boolean = false;


  displayedColumns = ['pvcodig','pvnombr','pvnit','pvmail'];
  dataSource: MatTableDataSource<Cpmaprv>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  selection = new SelectionModel<Cpmaprv>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Cpmaprv): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.pvcodig + 1}`;
  }

  constructor(private cpmaprvService : CpmaprvService,
    private snackBar: MatSnackBar, private dialog: MatDialog,
    private dialogRef: MatDialogRef<DialogselecionarprovComponent>
  ) { }

  ngOnInit(): void {
    this.cpmaprvService.listar().subscribe( data => {
      if(data != null){
        console.log(data)
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.activeTable = true;
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
      }
    })
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  cerrarDialog(){
    this.dialogRef.close();
  }
/*
  guardarDialog(){
    this.dialogRef.close({event:'close',data:this.selection});
  }
*/
  getRowClass(row: Cpmaprv): string {
    return row.pvcodig === '' || row.pvnombr === '' ? 'special-row' : '';
  }

  selectProv(row: Cpmaprv){
    this.dialogRef.close({event:'close',data:row});
  }

  

}
