import { Inbogrl } from '../../../_model/inbogrl';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialogimportarinvendbf',
  templateUrl: './dialogimportarinvendbf.component.html',
  styleUrls: ['./dialogimportarinvendbf.component.css']
})
export class DialogimportarinvendbfComponent implements OnInit {

  productos : Inbogrl[];

  constructor(private dialogRef: MatDialogRef<DialogimportarinvendbfComponent>,@Inject(MAT_DIALOG_DATA) private data: Inbogrl[]) {
    this.productos = data;
  }

  ngOnInit(): void {

  }

  closeDialog(opcion : number){
    if(opcion==1){
      this.dialogRef.close({event:'close',data:this.productos});
    }else{
      this.dialogRef.close();
    } 
  }

  numeroproductos(){
    return this.productos.length;
  }

}
