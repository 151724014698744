import { Famaord } from '../../../_model/famaord';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JwtHelperService } from '@auth0/angular-jwt';
import { InborutService } from 'src/app/_service/inventario/inborut.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dialog-producto',
  templateUrl: './dialog-producto.component.html',
  styleUrls: ['./dialog-producto.component.css']
})
export class DialogProductoComponent implements OnInit {

  famaord : Famaord;
  existencia : number;
  existencia_cajas : number;
  isAdmin: boolean;

  isLoadingResults = false;
  isRateLimitReached = false;

  constructor(private dialogRef: MatDialogRef<DialogProductoComponent>, @Inject(MAT_DIALOG_DATA) private data: Famaord,
  private inborutService : InborutService) { 
    let token;
    let decodedToken;
    const helper = new JwtHelperService();
    token = sessionStorage.getItem(environment.TOKEN_NAME);
    decodedToken = helper.decodeToken(token);
    
    this.famaord = new Famaord();
    this.famaord = this.data;
    this.famaord.codprod = this.data.inbogrl.codigo_ln;
    this.famaord.valnuni = this.data.inbogrl.preunid_ln;
    console.log("Antes de if");
    if(decodedToken.user_name.includes("RUTA")){
      let usuario : string = decodedToken.user_name
      console.log(usuario.slice(4));
      let codruta : number = Number(usuario.slice(4));
      console.log("Cod prod: "+this.data.inbogrl.codigo_ln)
      this.inborutService.ExistenciaProductoBodega(codruta,this.data.inbogrl.codigo_ln).subscribe(data => {
        this.existencia = data;
        this.existencia_cajas = this.existencia/this.data.inbogrl.unixcaj_ln; 
      });
    }else{  

      this.existencia = this.data.inbogrl.exiante_ln+
                        this.data.inbogrl.uniingr_ln-
                        this.data.inbogrl.uniegre_ln+
                        this.data.inbogrl.uingtem_ln-
                        this.data.inbogrl.uegrtem_ln;
      //salini+carmes-abomes+cartem-abotem
      console.log(this.existencia);
      this.existencia_cajas = this.existencia/this.data.inbogrl.unixcaj_ln; 
    }
  }

  ngOnInit() {
    let token;
    let decodedToken;
    const helper = new JwtHelperService();
    token = sessionStorage.getItem(environment.TOKEN_NAME);
    decodedToken = helper.decodeToken(token);
    if(decodedToken.authorities[0].includes("ADMIN") || decodedToken.authorities[0].includes("G.Ventas")){
      this.isAdmin = true;
    }else{
      this.isAdmin = false;
    }
  }

  closeDialog(){
    this.dialogRef.close({event:'close',data:this.famaord});
  }
}
