<div class="loader-container">
        <svg class="logo-image" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 1920 1080" style="enable-background:new 0 0 1920 1080;" xml:space="preserve" (animationend)="onAnimationEnd()">
                    
                <path d="M927.06,267.71c1.22,2.34,0.75,4.38,0.76,6.33c0.08,80.83,0.13,161.66,0.19,242.49
                    c0.01,12.8,5.44,18.25,18.19,18.33c9.5,0.06,18.99,0.17,28.49,0.26c9.76,0.1,16.39-5.98,17.15-15.74c0.08-0.99,0.03-2,0.03-3
                    c0.05-64.5,0.1-129,0.14-193.49c0-7.67-0.15-15.33-0.12-23c0.03-11.11,4.93-16.11,16.19-16.26c21.49-0.29,42.97-0.51,64.46-0.64
                    c15.63-0.1,31.26-0.02,46.78-0.02c1.16,1.76,0.69,3.47,0.69,5.08c0.02,108,0.03,216,0.02,323.99c0,12.28-7.36,18.68-19.59,17.1
                    c-5.86-0.76-10.15-4.61-11.32-10.37c-0.36-1.78-0.42-3.64-0.44-5.47c-0.28-41.48-0.56-82.97-0.79-124.45
                    c-0.13-23.33-0.1-46.66-0.25-69.99c-0.2-32.99-0.5-65.97-0.77-98.96c-0.01-1.13-0.1-2.26-0.16-3.64c-20.91,0-41.5,0-62.2,0
                    c-1.24,1.97-0.62,4.18-0.62,6.26c-0.04,70.83-0.03,141.66-0.03,212.5c0,19.13-11.32,31.04-30.56,31.29
                    c-22.16,0.29-44.33,0.28-66.49-0.02c-17.88-0.24-31.14-14.51-31.15-32.97c-0.04-66.67-0.01-133.33-0.01-200
                    c0-10.96,0-21.92,0-33.34c-2.36,0-4.15,0-5.94,0c-34,0-68,0.01-102,0c-2.33,0-4.68,0.04-6.99-0.19
                    c-9.24-0.92-12.85-4.98-12.85-14.29c0-25,0-50,0-75c0-1.64,0-3.27,0-4.73c2.05-1.28,3.93-0.77,5.7-0.77c88-0.03,176-0.03,264-0.02
                    c3.16,0,6.32-0.09,9.42,0.8c7.68,2.19,11.99,8.79,11.15,17.22c-0.68,6.85-6.92,12.61-14.22,13.09c-1.16,0.08-2.33,0.04-3.5,0.04
                    c-73.66,0.07-147.32,0.15-220.99,0.22c-1.5,0-3.01-0.05-4.5,0.09c-10.4,0.96-15.2,6.19-14.9,16.15c0.26,8.78,6.53,15.12,15.9,15.18
                    c24.16,0.16,48.33-0.02,72.49-0.05C901.34,267.7,914.3,267.71,927.06,267.71z" fill="none" stroke="#59AE68" stroke-width="5"/>
    
                <path d="M1182.18,245.25c-0.23,4.01,0.19,8.37-0.93,12.62c-1.22,4.67-4.23,7.48-8.93,8.3
                    c-5.53,0.97-9.05-2.53-8.53-8.55c1.2-13.83,0.04-27.38-6.1-39.99c-12.25-25.14-33-37.39-60.55-38.79
                    c-3.65-0.19-7.33,0.15-10.99,0.1c-6-0.08-9.14-4.46-7.15-10.11c1.57-4.45,4.82-7.35,9.56-7.67c32.63-2.2,60.18,7.48,79.49,35.2
                    C1178.17,210.87,1182.3,227.35,1182.18,245.25z" fill="none" stroke="#59AE68" stroke-width="5"/>
                
                <path d="M1087.25,207.97c-0.5,0-1,0-1.5,0c-7.05,0.06-10.09-1.76-10.55-6.33c-0.55-5.43,4.11-10.85,10.26-11.45
                    c8.18-0.8,16.34-0.2,24.27,2.02c31.34,8.78,46.07,37.15,42.84,66.41c-0.37,3.32-1.88,6.04-4.55,8.02c-2.97,2.21-6.22,3.3-9.78,1.41
                    c-3.48-1.85-3.82-4.93-3.55-8.54c1.17-15.2-2.63-28.78-14.06-39.47C1111.27,211.29,1099.93,207.69,1087.25,207.97z" fill="none" stroke="#59AE68" stroke-width="5"/>
                
                <path d="M1105.65,260.02c0.87-12.19-9.02-22.87-22.91-23.04c-7.88-0.1-10.4-1.64-10.73-6.56
                    c-0.38-5.67,4.07-10.5,10.38-11.28c23.71-2.93,44.05,17.32,41.2,40.99c-0.34,2.86-0.97,5.6-3.1,7.71
                    c-3.04,3.01-6.62,4.66-10.83,2.97C1105.51,269.15,1105.17,265.46,1105.65,260.02z" fill="none" stroke="#59AE68" stroke-width="5"/>
            
                <path d="M767.4,331.49c1.9-1.13,3.6-0.68,5.22-0.69c28.49-0.03,56.99-0.03,85.48,0c2.49,0,5.05-0.05,7.46,0.46
                    c8.22,1.74,12.83,8.61,12,17.49c-0.78,8.27-6.33,13.19-15.19,13.23c-15,0.06-29.99,0-44.99,0.02
                    c-12.22,0.02-17.81,5.54-17.81,17.55c0,64.32-0.02,128.64,0.01,192.96c0.01,12.82,0.42,25.65,0.28,38.46
                    c-0.15,13.15-9.08,20.63-21.02,18.05c-6.23-1.34-10.11-5.46-11.08-11.88c-0.25-1.64-0.35-3.31-0.35-4.97
                    c-0.02-92.15-0.02-184.29-0.02-276.44C767.4,334.26,767.4,332.78,767.4,331.49z" fill="none" stroke="#05435B" stroke-width="5"/>
        </svg>
      <p class="loading-text">Cargando...</p>
</div>