import { CcmacliService } from 'src/app/_service/ccmacli.service';
import { EncabeadoFacturasService } from './../../../_service/facturacion/encabeado-facturas.service';
import { Ccmasuc } from 'src/app/_model/cliente/ccmasuc';
import { CuerpoDocumentoCF } from './../../../_model/dte/cf/cuerpodocumentocf';
import { CtMaDoc } from 'src/app/_model/ctmadoc';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DetalleFacturaService } from './../../../_service/facturacion/detalle-factura.service';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Famadoc } from 'src/app/_model/famadoc';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CuerpoDocumento } from 'src/app/_model/dte/cuerpodocumento';
import { Resumen } from 'src/app/_model/dte/resumen';
import { CcmasucService } from 'src/app/_service/cliente/ccmasuc.service';

@Component({
  selector: 'app-dialogvalidacion-dte',
  templateUrl: './dialogvalidacion-dte.component.html',
  styleUrls: ['./dialogvalidacion-dte.component.css']
})
export class DialogvalidacionDTEComponent implements OnInit {

  displayedColumns = ['cantidad','descripcion','precioUni','montoDescu','ventaGravada'];
  dataSource: MatTableDataSource<CuerpoDocumento>;
  dataSource2: MatTableDataSource<CuerpoDocumentoCF>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  detalleDoc : CuerpoDocumento[] = [];
  detalleDocCF : CuerpoDocumentoCF[] = [];
  total : number = 0;
  totalDescu : number =0;
  resumen : Resumen;
  isVisible : boolean = false;

  sucursal : string;
  dirfac: string;
  constructor(
    private detalleFacturaService :DetalleFacturaService,
    private dialogRef: MatDialogRef<DialogvalidacionDTEComponent> ,@Inject(MAT_DIALOG_DATA)private ctmadoc :CtMaDoc,
    private ccmasucService : CcmasucService, private encabeadoFacturasService : EncabeadoFacturasService, private ccmacliService : CcmacliService
  ) {
    console.log(ctmadoc.codclie)
    this.ccmasucService.buscarporcodigocliente(ctmadoc.codclie).subscribe(sucursal => {
      console.log(sucursal)
      if(sucursal != undefined && sucursal !=null){
        this.sucursal = sucursal.nomsucs;
        this.dirfac = sucursal.dirsucs;
      }else{
        this.ccmacliService.buscarPorCodclie(ctmadoc.codclie).subscribe(cliente => {
          if(cliente.nomnego != undefined && cliente.nomnego != null){
            this.sucursal = cliente.nomnego;
            this.dirfac = cliente.dirnego;
          }else{
            this.sucursal = cliente.nomclie;
            this.dirfac = cliente.dirclie;
          }
        })
      }
    });
   }

  ngOnInit(): void {
    
        this.isVisible = true;
        this.detalleFacturaService.detalle_dte_ccf(this.ctmadoc.coddocu).subscribe(detalle => {
          
          detalle.forEach(element => {
            this.totalDescu=this.totalDescu+element.montoDescu
          });
          this.dataSource = new MatTableDataSource(detalle);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          console.log(this.totalDescu)
          this.detalleFacturaService.resumen_dte_ccf(this.ctmadoc.coddocu,this.totalDescu).subscribe(resumen_p =>{
            this.resumen = resumen_p;
          })
        })
  }

  cerrarDialog(){
    this.dialogRef.close();
  }

  facturar(){
    this.dialogRef.close({event:'close',data:true});
  }

}
