<mat-toolbar>Menus</mat-toolbar>

<div class="example-container mat-elevation-z8">

  <div class="example-header">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar Menu">
    </mat-form-field>
  </div>

  <mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="id_menu">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Id Menu </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.idMenu}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="icono">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Icono </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.icono}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="nombre">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.nombre}} </mat-cell>
    </ng-container>
 
    <ng-container matColumnDef="url">
      <mat-header-cell *matHeaderCellDef mat-sort-header> URL </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.url}} </mat-cell>
    </ng-container>


    <ng-container matColumnDef="acciones">
      <mat-header-cell *matHeaderCellDef>Acciones</mat-header-cell>
      <mat-cell *matCellDef="let row">        
        <button mat-button color="primary" (click)="openDialog(row)">Actualizar</button>
        <button mat-button color="accent" (click)="eliminar(row)">Eliminar</button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;">
    </mat-row>
  </mat-table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>

<br>

<button mat-fab color="accent" (click)="openDialog()" style="float: right; margin-right: 20px">
  <mat-icon>create</mat-icon>
</button>