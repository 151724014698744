import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Famaord_Enc } from 'src/app/_model/famaord_enc';
import { Btn } from 'src/app/_model/utils/btn';
import { SucursaldialogComponent } from '../../clientes/sucursaldialog/sucursaldialog.component';
import { GetosService } from 'src/app/_service/utils/getos.service';

@Component({
  selector: 'app-dialogmenuimpresion',
  templateUrl: './dialogmenuimpresion.component.html',
  styleUrls: ['./dialogmenuimpresion.component.css']
})
export class DialogmenuimpresionComponent implements OnInit {

  famaord_enc: Famaord_Enc;
  sistema_operativo : String;

  constructor(private dialogRef: MatDialogRef<SucursaldialogComponent> ,@Inject(MAT_DIALOG_DATA) private p_famaord_enc: Famaord_Enc, private getosService : GetosService) {
    this.famaord_enc = new Famaord_Enc();
    this.famaord_enc = p_famaord_enc;
    //console.log(this.famaord_enc.cladocu)
    this.sistema_operativo = this.getosService.getOS().toUpperCase();
   }

  ngOnInit() {
  }

  isAndroid(){
    //console.log(this.sistema_operativo)
    let str_os = "Android";
    if(this.sistema_operativo === str_os.toUpperCase()){
      return true;
    }else{
      return false;
    }
  }

  descargarPDF(){
    this.cerrarDialog(1);
  }

  impresionRemota(){
    this.cerrarDialog(2);
  }

  impresionTicket(){
    this.cerrarDialog(3);
  }

  impresionRecibo(){
    this.cerrarDialog(4);
  }

  factura_DTE(){
    this.cerrarDialog(5);
  }

  ccf_DTE(){
    this.cerrarDialog(6);
  }

  cerrarDialog(rs : number){
    this.dialogRef.close({event:'close',data:rs});
  }

  getIsfactura(){
    if(this.famaord_enc.cladocu == "1"){
      return true
    }else{
      return false
    }
  }

  getIsccf(){
    if(this.famaord_enc.cladocu == "0"){
      return true
    }else{
      return false
    }
  }
}
