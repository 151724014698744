import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inborut } from 'src/app/_model/invetario/inborut';
import { DialogimportarinvendbfComponent } from '../dialogimportarinvendbf/dialogimportarinvendbf.component';

@Component({
  selector: 'app-dialogimportarinvenrutadbf',
  templateUrl: './dialogimportarinvenrutadbf.component.html',
  styleUrls: ['./dialogimportarinvenrutadbf.component.css']
})
export class DialogimportarinvenrutadbfComponent implements OnInit {

  productos : Inborut[];

  constructor(private dialogRef: MatDialogRef<DialogimportarinvenrutadbfComponent>,
    @Inject(MAT_DIALOG_DATA) private data: Inborut[]) {
      this.productos = data;
     }

  ngOnInit(): void {

  }

  closeDialog(opcion : number){
    if(opcion==1){
      this.dialogRef.close({event:'close',data:this.productos});
    }else{
      this.dialogRef.close();
    } 
  }

  numeroproductos(){
    return this.productos.length;
  }
}
