<div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
        {{msj_spinner}}
    </div>
</div>
<div class="example-container">
    <mat-card-header class="centerContent">
        <mat-card-title>{{famaord.inbogrl.codigo_ln}} - {{famaord.inbogrl.descrip_ln}}</mat-card-title>
    </mat-card-header>
    <mat-grid-list cols="3" >
        <mat-grid-tile colspan="1"  >
            <div class="example-container center">
                <img src="{{famaord.inbogrl.imgurl_ln}}"  width="100px"  height="100px">
            </div>
        </mat-grid-tile>
        <mat-grid-tile colspan="2" >
            Codigo : {{famaord.inbogrl.codigo_ln}}
            <br>    
            Precio : {{famaord.inbogrl.preunid_ln}}
            <br>
            Presentacion : {{famaord.inbogrl.uni_d_vta}}
            <br>
            Unidades X Caja: {{famaord.inbogrl.present_ln}}
            <br>
            Existencias: {{existencia}}U / {{existencia_cajas | number : '1.2-2'}} Cajas
        </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list cols="2" rowHeight="50px">
        <mat-grid-tile  colspan="1" rowspan="1" >
            <mat-form-field>
                <label for="famaord.cancaja">Cajas</label>
                <input type="number" matInput [(ngModel)]="famaord.cancaja" name="famaord.cancaja" disabled=false>
            </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile  colspan="1" rowspan="1" >
            <mat-form-field>
                <label for="famaord.totunid">Unidades</label>
                <input type="number" matInput [(ngModel)]="famaord.totunid" name="famaord.totunid" disabled=false>
            </mat-form-field>
            </mat-grid-tile>
        <mat-grid-tile colspan="2" >
            <button type="submit" mat-raised-button color="primary" (click)="closeDialog()" >Agregar</button>
        </mat-grid-tile>
    </mat-grid-list>
    
    <!--./assets/Imagen_no_disponible.png-->
     <!--
    <form #myform="ngForm" class="example-form">

        <mat-grid-list cols="1" rowHeight="60px">
           
            <mat-grid-tile  colspan="1" rowspan="1" >
                <mat-form-field>
                    <label for="famaord.valnuni">Precio</label>
                    <input type="number" matInput [(ngModel)]="famaord.valnuni" name="famaord.valnuni" disabled=true>
                </mat-form-field>
            </mat-grid-tile>
            
            <mat-grid-tile  colspan="1" rowspan="1" >
            <mat-form-field>
                <label for="famaord.cancaja">Cajas</label>
                <input type="number" matInput [(ngModel)]="famaord.cancaja" name="famaord.cancaja" disabled=false>
            </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile  colspan="1" rowspan="1" >
            <mat-form-field>
                <label for="famaord.totunid">Unidades</label>
                <input type="number" matInput [(ngModel)]="famaord.totunid" name="famaord.totunid" disabled=false>
            </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile  colspan="1" rowspan="1" *ngIf="isAdmin">
                <mat-form-field>
                    <label for="famaord.desprod">Desc. Producto (%)</label>
                    <input type="number" matInput [(ngModel)]="famaord.desprod" name="famaord.desprod" disabled=false>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile  colspan="1" rowspan="1" *ngIf="isAdmin">
                <mat-form-field>
                    <label for="famaord.desespe">Desc. Especial ($)</label>
                    <input type="number" matInput [(ngModel)]="famaord.desespe" name="famaord.desespe" disabled=false>
                </mat-form-field>
            </mat-grid-tile>
        </mat-grid-list>
        </form>
        -->
</div>