import { Famaord } from 'src/app/_model/famaord';
import { CalcularDocumentoDTO } from './../_model/DTO/calculartotaldocumentodto';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Famaord_Enc } from '../_model/famaord_enc';
import { Inbogrl } from '../_model/inbogrl';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class FamaordService {

  famaordCambio = new Subject<Famaord[]>();
  mensajeCambio = new Subject<string>();

  url: string = `${environment.HOST}/ordenes`;
  
  constructor(private http: HttpClient) { }

  listar(){
    return this.http.get<Famaord[]>(this.url,this.getToken());
  }

  listarPorId(idFamaord : number){
    return this.http.get<Famaord>(`${this.url}/${idFamaord}`,this.getToken());
  }

  buscarPorNumPedido(numpedido : number){
    return this.http.get<Famaord[]>(`${this.url}/pedido/${numpedido}`,this.getToken());
  }

  registrar(famaord : Famaord){
    return this.http.post(this.url, famaord,this.getToken());
  }

  modificar(famaord : Famaord){
    return this.http.put(this.url, famaord,this.getToken());
  }

  eliminar(idFamaord : number){
    return this.http.delete(`${this.url}/${idFamaord}`,this.getToken());
  }

  generarImpresion(idfamaord_enc : number){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    return this.http.get(`${this.url}/generarImpresion/${idfamaord_enc}`, {
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token,
        responseType: 'blob'
      })
    });
  }

  generarImpresionRemota(idfamaord_enc : number){
    return this.http.get(`${this.url}/generarImpresionRemota/${idfamaord_enc}`,this.getToken());
  }

  enviarNotificacion(mensaje : String){
    return this.http.get(`${this.url}/enviarnotificacion/${mensaje}`,this.getToken())
  }

  calcularTotalDoumento(p_CalcularDocumentoDTO : CalcularDocumentoDTO){
    if(p_CalcularDocumentoDTO.famaord.cancaja != undefined && p_CalcularDocumentoDTO.famaord.totunid != undefined){
      return this.http.get<number>(`${this.url}/calculartotaldocumento/${p_CalcularDocumentoDTO.ord_ciente.famaord_enc.idFamaord_Enc}/${p_CalcularDocumentoDTO.famaord.cancaja}/${p_CalcularDocumentoDTO.famaord.inbogrl.idinbogrl}/${p_CalcularDocumentoDTO.famaord.valnuni}/${p_CalcularDocumentoDTO.famaord.totunid}`,this.getToken())
    }else if(p_CalcularDocumentoDTO.famaord.cancaja != undefined && p_CalcularDocumentoDTO.famaord.totunid == undefined){
      return this.http.get<number>(`${this.url}/calculartotaldocumento/${p_CalcularDocumentoDTO.ord_ciente.famaord_enc.idFamaord_Enc}/${p_CalcularDocumentoDTO.famaord.cancaja}/${p_CalcularDocumentoDTO.famaord.inbogrl.idinbogrl}/${p_CalcularDocumentoDTO.famaord.valnuni}/0`,this.getToken())
    }else if(p_CalcularDocumentoDTO.famaord.cancaja == undefined && p_CalcularDocumentoDTO.famaord.totunid != undefined){
      return this.http.get<number>(`${this.url}/calculartotaldocumento/${p_CalcularDocumentoDTO.ord_ciente.famaord_enc.idFamaord_Enc}/0/${p_CalcularDocumentoDTO.famaord.inbogrl.idinbogrl}/${p_CalcularDocumentoDTO.famaord.valnuni}/${p_CalcularDocumentoDTO.famaord.totunid}`,this.getToken())
    }
  }
/*
  agregarProducto(numpedi : number,
    codprod : string,
    lineart : number,
    totunid : number,
    cancaja : number,
    fracaja : number,
    cajafac : number,
    fracfac : number,
    valnuni : number,
    selereg : number,
    cdprinv : string,
    tipdesc : string,
    desprod : number,
    desfact : number,
    desespe : number,
    valinco : number,
    clasvta : string,
    tipvtas : string,
    tipempq : string,
    porimpu : number,
    ctrlcvs : string,
    cocobol : string,
    fecdocu : string,
    ctrlrec : string,
    conpart : string,
    bodsale : string,
    ctrlret : number,
    estaord : number,
    coment1 : string,
    coment2 : string,
    coment3 : string,
    limiret : number,
    poriret : number,
    nunmesa : string,
    valefec : number,
    porcomi : number,
    idusuario : number,
    famaord_enc : Famaord_Enc,
    inbogrl : Inbogrl,
    uniped : number,
    subtotal : number,
    latitud : string,
    longitud : string,
    altitud : string){
      return this.http.get(`${this.url}/agregarproducto/${codprod}`)
  }
*/
  agregarProducto(p_famaord : Famaord){
    return this.http.post<Famaord[]>(`${this.url}/agregarproducto`, p_famaord,this.getToken());
  }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }
}
