
 <div class="example-loading-shade"
       *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
      {{msj_spinner}}
    </div>
  </div>
<div *ngIf="!isAndroid()">
  {{sistema_operativo}}

<mat-toolbar>
  Cliente: {{ccmacli.nomclie}}
  <div style="right: 0px; position: absolute;">Credito Disponible: {{credito_disponible | currency}}</div>
</mat-toolbar>
<div class="example-container">
  <mat-grid-list cols="3" rowHeight="25px" >
    <mat-grid-tile
        *ngFor="let tile of tiles"
        [colspan]="tile.cols"
        [rowspan]="tile.rows"
        [style.background]="tile.color">
        <div class="text-inside-grid">
          {{tile.text}}
        </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <div class="text-inside-grid">
        Docs. Pendientes: {{cantidaddocumentospendientes}}
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <div class="text-inside-grid">Fecha de Entrega:  </div>
      <div class="picker-inside-grid">
        <mat-form-field>
          <mat-label>Seleccione fecha</mat-label>
          <input matInput [(ngModel)]="fechaSeleccionada" [matDatepicker]="picker" [matDatepickerFilter]="myFilter" (ngModelChange)="guardarFecha()">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="text-inside-grid">
          Fecha y hora: {{dt | date: 'MMM d, y H:mm:ss' : '-1200'}}
        </div>
      </mat-grid-tile>
      
      <mat-grid-tile>
          <div class="btn-inside-grid">
            <button mat-button (click)="cincodocmasantiguos()">Saldo pendiente: {{SaldoPendienteTotal | currency}}</button>
          </div>
      </mat-grid-tile>
      <mat-grid-tile  [colspan]="2"> 
        <div class="btn-inside-grid">
          <button mat-raised-button color="primary" (click)="guardarDireccion()">Entregar en:</button> {{ord_cliente.famaord_enc.direntrega}}
        </div>  
      </mat-grid-tile>
      <mat-grid-tile> 
        <div class="btn-inside-grid">
          <button mat-raised-button color="primary" (click)="openLink()">Catalogo Web</button>
        </div>  
      </mat-grid-tile>
      <mat-grid-tile [colspan]="3">
      </mat-grid-tile>
    </mat-grid-list>
</div>



  <div class="example-header">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar Productos">
    </mat-form-field>
    <button mat-fab color="primary" *ngIf="isAutorizado()" (click)="openDialog()" style="float: right; margin-right: 20px">
      <mat-icon>add</mat-icon>
    </button>
    <button mat-fab color="primary" *ngIf="getprintAct()" (click)="opendialogMenuImpresion(ord_cliente.famaord_enc)" style="float: right; margin-right: 20px" >
      <mat-icon>print</mat-icon>
    </button>
    <button mat-fab color="primary" *ngIf="!getprintAct()" (click)="enviarSoicitudAutorizacion()" style="float: right; margin-right: 20px" >
      <mat-icon>schedule_send</mat-icon>
    </button>
    <button mat-fab color="primary" (click)="enviarWhatsapp()" style="float: right; margin-right: 20px" >
      <mat-icon svgIcon="whatsapp" aria-hidden="false" aria-label="Example thumbs up SVG icon"></mat-icon>
    </button>
    <!--
    <button mat-fab color="primary" *ngIf="isAndroid()" (click)="sendToQuickPrinter()" style="float: right; margin-right: 20px;" >
      <mat-icon>receipt</mat-icon>
    </button>
   -->
    <button mat-raised-button color="primary" *ngIf="isAutorizado() && isAdmin" (click)="autorizarPedido(1)" style="float: right; margin-right: 20px;" >
      AUTORIZAR
    </button>
    <button mat-raised-button color="warm" *ngIf="isAutorizado() && isAdmin" (click)="autorizarPedido(2)" style="float: right; margin-right: 20px;" >
      DESAUTORIZAR
    </button>
    <!--
    <button mat-fab color="primary" *ngIf="isAdmin" (click)="desbloquearPedido(ord_cliente.famaord_enc)" style="float: right; margin-right: 20px">
      <mat-icon *ngIf="this.ord_cliente.famaord_enc.estado == 1">lock_open</mat-icon>
      <mat-icon *ngIf="this.ord_cliente.famaord_enc.estado == 2">lock</mat-icon>
    </button>
    -->
  </div>


  
  <mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="codprod">
      <mat-header-cell *matHeaderCellDef mat-sort-header >Codigo Producto</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.codprod}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="producto">
        <mat-header-cell *matHeaderCellDef mat-sort-header >Producto</mat-header-cell>
        <mat-cell (click)="dialogProducto(row)" *matCellDef="let row" >{{row.coment1}} </mat-cell>
      </ng-container>

    <ng-container matColumnDef="cancaja">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Cant. Cajas</mat-header-cell>
      <mat-cell (click)="actualizarCantidadCaja(row)" *matCellDef="let row" > {{row.cancaja}} </mat-cell>
    </ng-container>  

    <ng-container matColumnDef="totunid" >
      <mat-header-cell *matHeaderCellDef mat-sort-header>Cant. Unidades</mat-header-cell>
      <mat-cell (click)="actualizarCantidad(row)" *matCellDef="let row" > {{row.totunid}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="unidadestotales" >
      <mat-header-cell *matHeaderCellDef mat-sort-header >Unidades Totales</mat-header-cell>
      <mat-cell *matCellDef="let row" > {{getCantidadTotal(row)}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="valunid" >
      <mat-header-cell *matHeaderCellDef mat-sort-header >Precio</mat-header-cell>
      <mat-cell (click)="actualizarPrecio(row)" *matCellDef="let row" > {{getPrecio(row.valnuni) | currency}} </mat-cell>
      <!--<mat-footer-cell *matFooterCellDef> Sumas </mat-footer-cell>-->
    </ng-container>

    <ng-container matColumnDef="descuentoProducto">
      <mat-header-cell *matHeaderCellDef mat-sort-header >Desc. Prod</mat-header-cell>
      <mat-cell (click)="actualizarDescProd(row)" *matCellDef="let row" > {{row.desprod/100 | percent:digitInfo}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="descuentoEspecial" >
      <mat-header-cell *matHeaderCellDef mat-sort-header  >Desc. Esp</mat-header-cell>
      <mat-cell (click)="actualizarDescProd(row)" *matCellDef="let row" > {{row.desespe | currency}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="subtotal">
      <mat-header-cell *matHeaderCellDef mat-sort-header >SubTotal</mat-header-cell>
      <mat-cell *matCellDef="let row" > {{getSubTotal(row)  | currency}} 
        <div>
          <button mat-icon-button color="primary" *ngIf="isAutorizado()" style="float: right; margin-right: 0px;" (click)="eliminarProducto(row)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-cell>
    </ng-container>


     <!-- empty footer row-->
     <ng-container matColumnDef="emptyFooter">
      <mat-footer-cell *matFooterCellDef class="mobile-hide"></mat-footer-cell>
    </ng-container>

    <!-- set footer for sumas amount-->
    <ng-container matColumnDef="sumastitle">
      <mat-footer-cell *matFooterCellDef class="columna-concep">Sumas</mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="valsumas">
      <mat-footer-cell *matFooterCellDef class="columna-subtotal">
        <!--<span class="mobile-label">BTW (21%)</span>-->
        <span class="mobile-value"> {{ getSumas() | currency }}</span>
      </mat-footer-cell>
    </ng-container>
    <!-- set footer for DESCFACTU amount-->
    <ng-container matColumnDef="descfactutitle">
      <mat-footer-cell *matFooterCellDef class="columna-concep" (click)="actualizarDescfact()">Descuentos</mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="valdescfactu">
      <mat-footer-cell *matFooterCellDef class="columna-subtotal">
        <!--<span class="mobile-label">BTW (21%)</span>-->
        <span class="mobile-value"> {{ getDescuentoFactu() | currency }}</span>
      </mat-footer-cell>
    </ng-container>
    <!-- set footer for 1% de RETENCION amount-->
    <ng-container matColumnDef="ret1title">
      <mat-footer-cell *matFooterCellDef class="columna-concep">Ret</mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="valret1">
      <mat-footer-cell *matFooterCellDef class="columna-subtotal">
        <!--<span class="mobile-label">BTW (21%)</span>-->
        <span class="mobile-value"> {{ getRet1() | currency }}</span>
      </mat-footer-cell>
    </ng-container>
    <!-- set footer for IVA amount-->
    <ng-container matColumnDef="ivatitle" >
      <mat-footer-cell *matFooterCellDef class="columna-concep">
        <span class="mobile-value" *ngIf="ivaActCampo">IVA</span>
      </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="valiva" >
      <mat-footer-cell *matFooterCellDef class="columna-subtotal">
        <!--<span class="mobile-label">BTW (21%)</span>-->
        <span class="mobile-value" *ngIf="ivaActCampo"> {{ getIVA() | currency }}</span>
      </mat-footer-cell>
    </ng-container>
    <!-- set footer for TOTAL amount-->
    <ng-container matColumnDef="totaltitle">
      <mat-footer-cell *matFooterCellDef class="columna-concep">Total</mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="valtotal" >
      <mat-footer-cell *matFooterCellDef class="columna-subtotal">
        <!--<span class="mobile-label">BTW (21%)</span>-->
        <!--<span class="mobile-value" >--> {{ getTOTAL() | currency }} <!--</span>-->
      </mat-footer-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    <!--<mat-footer-row *matFooterRowDef="displayedColumns" ></mat-footer-row>-->
    <mat-footer-row *matFooterRowDef="displayedSumColumns" ></mat-footer-row>
    <mat-footer-row *matFooterRowDef="displayedDescFactuColumns" ></mat-footer-row>
    <mat-footer-row *matFooterRowDef="displayRet1Columns" ></mat-footer-row>
    <mat-footer-row *matFooterRowDef="displayedIVAColumns" ></mat-footer-row>
    <mat-footer-row *matFooterRowDef="displayedTOTALColumns" ></mat-footer-row>
    
  </mat-table>
  <mat-paginator [pageSizeOptions]="[50, 100]"></mat-paginator>
</div>

<!--------------------------------------------------------------------------------------------------------------------------->
<div  *ngIf="isAndroid()">
  <mat-toolbar>
    Cliente: {{ccmacli.nomclie}}
    <br>
    Credito Disponible: {{credito_disponible | currency}}
  </mat-toolbar>
  <div>
    <mat-grid-list cols="1" rowHeight="30px" >
      <mat-grid-tile
            [colspan]="tiles[0].cols"
            [rowspan]="tiles[0].rows"
            [style.background]="tiles[0].color">
            <div class="text-inside-grid">
              {{tiles[0].text}}
            </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="text-inside-grid">
          Docs. Pendientes: {{cantidaddocumentospendientes}}
        </div>
        <button mat-raised-button color="primary" (click)="cincodocmasantiguos()">Saldo pendiente: {{SaldoPendienteTotal | currency}}</button>
      </mat-grid-tile>
      
      <mat-grid-tile>
        <div class="text-inside-grid">Fecha de Entrega:  </div>
        <div class="picker-inside-grid">
          <mat-form-field>
            <mat-label>Seleccione fecha</mat-label>
            <input matInput [(ngModel)]="fechaSeleccionada" [matDatepicker]="picker" [matDatepickerFilter]="myFilter" (ngModelChange)="guardarFecha()">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </mat-grid-tile>
      
      <mat-grid-tile>
        <div class="text-inside-grid">
          Fecha y hora: {{dt | date: 'MMM d, y H:mm:ss' : '-1200'}}
        </div>
      </mat-grid-tile>
      <!--
      <mat-grid-tile>
        <div class="btn-inside-grid">
          <button mat-button color="primary" (click)="cincodocmasantiguos()">Saldo pendiente: {{SaldoPendienteTotal | currency}}</button>
        </div>
      </mat-grid-tile>
      -->
      <mat-grid-tile> 
        <div class="btn-inside-grid">
          <button mat-raised-button color="primary" (click)="guardarDireccion()">Entregar en:</button> {{ord_cliente.famaord_enc.direntrega}}
        </div>  
      </mat-grid-tile>

      <mat-grid-tile> 
        <div class="btn-inside-grid">
          <button mat-raised-button color="primary" (click)="openLink()">Catalogo Web</button>
        </div>  
      </mat-grid-tile>
        
    </mat-grid-list>

    <div class="example-header">
      
      <button mat-fab color="primary" *ngIf="isAutorizado()" (click)="openDialog()" style="float: right; margin-right: 20px">
        <mat-icon>add</mat-icon>
      </button>
      <button mat-fab color="primary" *ngIf="getprintAct()" (click)="opendialogMenuImpresion(ord_cliente.famaord_enc)" style="float: right; margin-right: 20px" >
        <mat-icon>print</mat-icon>
      </button>
      <button mat-fab color="primary" *ngIf="!getprintAct()" (click)="enviarSoicitudAutorizacion()" style="float: right; margin-right: 20px" >
        <mat-icon>schedule_send</mat-icon>
      </button>
      <button mat-fab color="primary" (click)="enviarWhatsapp()" style="float: right; margin-right: 20px" >
        <mat-icon svgIcon="whatsapp" aria-hidden="false" aria-label="Example thumbs up SVG icon"></mat-icon>
      </button>
      <!--
      <button mat-fab color="primary" *ngIf="isAndroid()" (click)="sendToQuickPrinter()" style="float: right; margin-right: 20px;" >
        <mat-icon>receipt</mat-icon>
      </button>
     -->
      <button mat-raised-button color="primary" *ngIf="isAutorizado() && isAdmin" (click)="autorizarPedido(1)" style="float: right; margin-right: 20px;" >
        AUTORIZAR
      </button>
      <button mat-raised-button color="warm" *ngIf="isAutorizado() && isAdmin" (click)="autorizarPedido(2)" style="float: right; margin-right: 20px;" >
        DESAUTORIZAR
      </button>
      <!--
      <button mat-fab color="primary" *ngIf="isAdmin" (click)="desbloquearPedido(ord_cliente.famaord_enc)" style="float: right; margin-right: 20px">
        <mat-icon *ngIf="this.ord_cliente.famaord_enc.estado == 1">lock_open</mat-icon>
        <mat-icon *ngIf="this.ord_cliente.famaord_enc.estado == 2">lock</mat-icon>
      </button>
      -->
    </div>

    <div class="example-header">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar Productos">
      </mat-form-field>
    </div>
  </div>

  <mat-table [dataSource]="dataSourceAndroid" matSort class="androidtable">
    <ng-container matColumnDef="android-producto">
      <mat-header-cell *matHeaderCellDef mat-sort-header >Producto</mat-header-cell>
      <mat-cell (click)="dialogProducto(row)" *matCellDef="let row" >
        <div>
          <img src="{{row.inbogrl.imgurl_ln}}"  width="90px"  height="90px">
        </div>
        <br>
        {{row.codprod}} 
        <br>
        {{row.coment1}}
        <br>
        {{row.inbogrl.uni_d_vta}}.
        <br>
        <!--Unidades X Caja: {{row.inbogrl.present_ln}}-->
        <br/>
        
        
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="android-unidades">
      <mat-header-cell  *matHeaderCellDef mat-sort-header >Unids</mat-header-cell>
      <mat-cell *matCellDef="let row" >
        {{getCantidadTotal(row)}}
      </mat-cell>  
    </ng-container>
    
    <ng-container matColumnDef="android-precio">
      <mat-header-cell  *matHeaderCellDef mat-sort-header >Precio</mat-header-cell>
      <mat-cell *matCellDef="let row" >
        {{getPrecio(row.valnuni) | currency}}
      </mat-cell>  
    </ng-container>

    <ng-container matColumnDef="android-descuentoEspecial" >
      <mat-header-cell *matHeaderCellDef mat-sort-header  >Desc. Esp</mat-header-cell>
      <mat-cell (click)="actualizarDescProd(row)" *matCellDef="let row" > {{row.desespe | currency}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="android-subtotal">
      <mat-header-cell *matHeaderCellDef mat-sort-header >SubTotal</mat-header-cell>
      <mat-cell *matCellDef="let row" > {{getSubTotal(row)  | currency}} 
        <div>
          <button mat-icon-button color="primary" *ngIf="isAutorizado()" style="float: right; margin-right: 0px;" (click)="eliminarProducto(row)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-cell>
    </ng-container>

    <mat-footer-cell *matFooterCellDef >dsds</mat-footer-cell>
    <!------------------------------------------------------------------------------------------------------------------------->
    <!-- empty footer row-->
    <ng-container matColumnDef="emptyFooter">
      <mat-footer-cell *matFooterCellDef class="mobile-hide"></mat-footer-cell>
    </ng-container>

    <!-- set footer for sumas amount-->
    <ng-container matColumnDef="sumastitle">
      <mat-footer-cell *matFooterCellDef class="columna-concep">Sumas</mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="valsumas">
      <mat-footer-cell *matFooterCellDef class="columna-subtotal">
        <!--<span class="mobile-label">BTW (21%)</span>-->
        <span class="mobile-value"> {{ getSumas() | currency }}</span>
      </mat-footer-cell>
    </ng-container>
    <!-- set footer for DESCFACTU amount-->
    <ng-container matColumnDef="descfactutitle">
      <mat-footer-cell *matFooterCellDef class="columna-concep" (click)="actualizarDescfact()">Descuentos</mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="valdescfactu">
      <mat-footer-cell *matFooterCellDef class="columna-subtotal">
        <!--<span class="mobile-label">BTW (21%)</span>-->
        <span class="mobile-value"> {{ getDescuentoFactu() | currency }}</span>
      </mat-footer-cell>
    </ng-container>
    <!-- set footer for 1% de RETENCION amount-->
    <ng-container matColumnDef="ret1title">
      <mat-footer-cell *matFooterCellDef class="columna-concep">1% Ret</mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="valret1">
      <mat-footer-cell *matFooterCellDef class="columna-subtotal">
        <!--<span class="mobile-label">BTW (21%)</span>-->
        <span class="mobile-value"> {{ getRet1() | currency }}</span>
      </mat-footer-cell>
    </ng-container>
    <!-- set footer for IVA amount-->
    <ng-container matColumnDef="ivatitle" >
      <mat-footer-cell *matFooterCellDef class="columna-concep">
        <span class="mobile-value" *ngIf="ivaActCampo">IVA</span>
      </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="valiva" >
      <mat-footer-cell *matFooterCellDef class="columna-subtotal">
        <!--<span class="mobile-label">BTW (21%)</span>-->
        <span class="mobile-value" *ngIf="ivaActCampo"> {{ getIVA() | currency }}</span>
      </mat-footer-cell>
    </ng-container>
    <!-- set footer for TOTAL amount-->
    <ng-container matColumnDef="totaltitle">
      <mat-footer-cell *matFooterCellDef class="columna-concep">Total</mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="valtotal" >
      <mat-footer-cell *matFooterCellDef class="columna-subtotal">
        <!--<span class="mobile-label">BTW (21%)</span>-->
        <!--<span class="mobile-value" >--> {{ getTOTAL() | currency }} <!--</span>-->
      </mat-footer-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumnsAndroid"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumnsAndroid;"></mat-row>
    <mat-footer-row *matFooterRowDef="displayedSumColumnsAndroid" ></mat-footer-row>
    <mat-footer-row *matFooterRowDef="displayedDescFactuColumnsAndroid" ></mat-footer-row>
    <mat-footer-row *matFooterRowDef="displayRet1ColumnsAndroid" ></mat-footer-row>
    <mat-footer-row *matFooterRowDef="displayedIVAColumnsAndroid" ></mat-footer-row>
    <mat-footer-row *matFooterRowDef="displayedTOTALColumnsAndroid" ></mat-footer-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[50, 100]"></mat-paginator>
  
</div>