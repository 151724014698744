<div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"> Cargando </mat-spinner>
    <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
        
    </div>
</div>

<div class="form-group">
    <label>Fecha Inicial</label>
    <input matInput [(ngModel)]="fechaIni" type="date">

    <label>Fecha Final</label>
    <input matInput [(ngModel)]="fechaFin" type="date">
</div>

<div class="example-header">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
    </mat-form-field>
    <button mat-flat-button color="primary" (click)="buscarPorFecha();" style="float: right; margin-right: 20px">
      Buscar
    </button>
    <button mat-flat-button color="warm" (click)="cerrarDialog();" style="float: right; margin-right: 20px">
      Cerrar
    </button>
  </div>

<mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="no_pedido" >
        <mat-header-cell *matHeaderCellDef>No Doc</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.docinterno}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="dte_fecha" >
        <mat-header-cell *matHeaderCellDef>Fecha</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.fhProcesamiento}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="dte_codigo_generacion" >
        <mat-header-cell *matHeaderCellDef>Codigo Generacion</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.codigoGeneracion}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="dte_numero_control" >
        <mat-header-cell *matHeaderCellDef>Numero de Control</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.numeroControl}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="dte_sello" >
        <mat-header-cell *matHeaderCellDef>Sello</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.selloRecibido}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectDoc(row)">
    </mat-row>
</mat-table>
<mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>