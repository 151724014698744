import { Direccion } from "./direccion";

export class SujetoExcluido{
    tipoDocumento   : string;
    numDocumento    : string;
    nombre          : string;
    codActividad    : string;
    descActividad   : string;
    direccion       : Direccion;
    telefono        : string;
    correo          : string;
}