import { Pago } from "../Pago";

export class Resumen{

    totalCompra : number;
    descu : number;
    totalDescu : number;
    subTotal : number;
    ivaRete1 : number;
    reteRenta : number;
    totalPagar : number;
    totalLetra : string;
    condicionOperacion : number;
    pagos: Pago[];
    observaciones : string;
}