<div *ngIf="opcionesInvalidacion">
    <p>Seleccione tipo de invalidacion</p>
    <div class="button-container" >
        <button mat-flat-button color="primary" (click)="invalidarXerror();">Error en informacion</button>
        <button mat-flat-button color="primary"(click)="invalidarRecindir();">Recindir Operacion</button>
        <button mat-flat-button color="primary" (click)="Otro();">Otro</button>
    </div>
</div>

<div *ngIf="invalidarXerrorDTE">
<p>Ingresar el Codigo de Generacion del nuevo documento</p>
    <mat-form-field class="example-full-width">
        <input matInput type="text" id="codigoGeneracionR" [(ngModel)]="codigoGeneracionR" name="codigoGeneracionR">
    </mat-form-field>
    <p>Ingresar el motivo de la invalidacion</p>
    <mat-form-field class="example-full-width">
        <input matInput type="text" id="motivo" [(ngModel)]="motivo" name="motivo">
    </mat-form-field>
    <div class="button-container" >
    <button mat-raised-button (click)="invalidarXerror_btn();">Invalidar</button>
    <button mat-raised-button (click)="cerrarDialog();">Cerrar</button>
    </div>
</div>

<div *ngIf="invalidarRecindirDTE">
        <p>Ingresar el motivo de la invalidacion</p>
        <mat-form-field class="example-full-width">
            <input matInput type="text" id="motivo" [(ngModel)]="motivo" name="motivo">
        </mat-form-field>
        <div class="button-container" >
        <button mat-raised-button (click)="invalidarRecindir_btn();">Invalidar</button>
        <button mat-raised-button (click)="cerrarDialog();">Cerrar</button>
        </div>
</div>

<div *ngIf="OtroDTE">
    <p>Ingresar el Codigo de Generacion del nuevo documento</p>
    <mat-form-field class="example-full-width">
        <input matInput type="text" id="codigoGeneracionR" [(ngModel)]="codigoGeneracionR" name="codigoGeneracionR">
    </mat-form-field>
    <p>Ingresar el motivo de la invalidacion</p>
    <mat-form-field class="example-full-width">
        <input matInput type="text" id="motivo" [(ngModel)]="motivo" name="motivo">
    </mat-form-field>
    <div class="button-container" >
    <button mat-raised-button (click)="Otro_btn();">Invalidar</button>
    <button mat-raised-button (click)="cerrarDialog();">Cerrar</button>
    </div>
</div>