<div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
      
    </div>
</div>

<div>
    <mat-card class="example-card center">
        <mat-card-header class="centerContent">
            <mat-card-title>Clientes</mat-card-title>
        </mat-card-header>
        <form #myform="ngForm" class="example-form">

        <mat-tab-group dynamicHeight>
            <mat-tab label="Actuales">
                <div class="example-container mat-elevation-z4">
                
                    <div class="example-header">
                        <mat-form-field>
                            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar Cliente">
                        </mat-form-field>
                    </div>
                    <mat-table [dataSource]="dataSourceActual" matSort>

                        <ng-container matColumnDef="codclie">
                          <mat-header-cell *matHeaderCellDef mat-sort-header>Codigo Cliente</mat-header-cell>
                          <mat-cell *matCellDef="let row"> {{row.ccmacli.codclie}} </mat-cell>
                        </ng-container>
                    
                        <ng-container matColumnDef="nomclie">
                          <mat-header-cell *matHeaderCellDef mat-sort-header>Cliente</mat-header-cell>
                          <mat-cell *matCellDef="let row"> {{row.ccmacli.nomclie}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="acciones">
                          <mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</mat-header-cell>
                           <mat-cell *matCellDef="let row">
                                <button mat-raised-button color="primary" (click)="eliminarCliente(row)">Quitar</button>
                           </mat-cell>
                        </ng-container>
                                        
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;">
                        </mat-row>
                    </mat-table>
                    <mat-paginator [pageSizeOptions]="[5, 10, 20]"></mat-paginator>
                </div>
            
            </mat-tab>

            <mat-tab label="Agregar">
                <div class="example-container mat-elevation-z4">
                    <div class="example-header">
                        <mat-form-field>
                            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar Cliente">
                        </mat-form-field>
                    </div>
                    <mat-table [dataSource]="dataSource" matSort>

                        <ng-container matColumnDef="codclie">
                          <mat-header-cell *matHeaderCellDef mat-sort-header>Codigo Cliente</mat-header-cell>
                          <mat-cell *matCellDef="let row"> {{row.codclie}} </mat-cell>
                        </ng-container>
                    
                        <ng-container matColumnDef="nomclie">
                          <mat-header-cell *matHeaderCellDef mat-sort-header>Cliente</mat-header-cell>
                          <mat-cell *matCellDef="let row"> {{row.nomclie}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="acciones">
                          <mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</mat-header-cell>
                           <mat-cell *matCellDef="let row">
                                <button mat-raised-button color="primary" (click)="selecionarCliente(row)">Asignar</button>
                                <button mat-raised-button color="primary" (click)="verSucursales(row)" *ngIf="false">Sucursal</button>
                           </mat-cell>
                        </ng-container>
                                        
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;">
                        </mat-row>
                      </mat-table>
                      <mat-paginator [pageSizeOptions]="[5, 10, 20]"></mat-paginator>
                </div>
                </mat-tab>
        </mat-tab-group>
        </form>
    </mat-card>
</div>
