import { CcmacobService } from './../../../_service/cliente/ccmacob.service';
import { CcmacliService } from './../../../_service/ccmacli.service';
import { Ord_Client } from './../../../_model/ord_client';
import { Famaord } from './../../../_model/famaord';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Inbogrl } from 'src/app/_model/inbogrl';

import { InbogrlService } from 'src/app/_service/inbogrl.service';
import { DialogProductoComponent } from '../dialog-producto/dialog-producto.component';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { UsuarioService } from 'src/app/_service/seguridad/usuario.service';
import { switchMap } from 'rxjs/operators';
import { FamaordService } from 'src/app/_service/famaord.service';
import { DialogErrorComponent } from '../../utils/dialog-error/dialog-error.component';
import { CalcularDocumentoDTO } from 'src/app/_model/DTO/calculartotaldocumentodto';
import { of, pipe } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { InborutService } from 'src/app/_service/inventario/inborut.service';

@Component({
  selector: 'app-dialog-productos',
  templateUrl: './dialog-productos.component.html',
  styleUrls: ['./dialog-productos.component.css']
})

export class DialogProductosComponent implements OnInit {
  ord_Client : Ord_Client;
  p_inbogrl : Inbogrl;
  saldoPendiente : number;
  displayedColumns = ['codigo_ln', 'descrip_ln'];
  dataSource: MatTableDataSource<Inbogrl>;
  limitecredito : number;
  Valido : boolean;
  p_compra : number;
  codruta : number;
  isLoadingResults = false;
  isRateLimitReached = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private dialogRef: MatDialogRef<DialogProductosComponent>,@Inject(MAT_DIALOG_DATA) private data: Ord_Client,
    private inbogrlService : InbogrlService, private dialog: MatDialog, private snackBar: MatSnackBar,
    private usuarioService : UsuarioService, private famaordService : FamaordService, private ccmacliService : CcmacliService,
    private ccmacobService : CcmacobService, private inborutService : InborutService ) {
      
      localStorage.setItem("numpedido",data.famaord_enc.numpedi.toString());
      this.ord_Client = new Ord_Client();
      this.ord_Client = data;
     }

  ngOnInit() {
    let token;
    let decodedToken;
    const helper = new JwtHelperService();
    token = sessionStorage.getItem(environment.TOKEN_NAME);
    decodedToken = helper.decodeToken(token);
    //console.log(decodedToken.user_name);

    if(decodedToken.user_name.includes("RUTA")){
      let usuario : string = decodedToken.user_name
      console.log(usuario.slice(4));
      this.codruta = Number(usuario.slice(4));
      this.inbogrlService.ProductoBodega(this.codruta).subscribe(data => {
        console.log(data)
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    }else{
      this.inbogrlService.ListarProductosActivos().subscribe(data => {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    }

    this.inbogrlService.InbogrlCambio.subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });

    this.inbogrlService.mensajeCambio.subscribe(data => {
      this.snackBar.open(data, 'AVISO',{
        duration:2000
      });
    });
    /*
    this.inbogrlService.ProductoBodega().subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
    */
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  closeDialog(){
    this.dialogRef.close();
  }
  
  openDialog(inbogrl?: Inbogrl) {
    this.p_inbogrl = new Inbogrl();
    this.p_inbogrl = inbogrl;

    let inbo = inbogrl != null ? inbogrl : new Inbogrl();
    let famaord : Famaord = new Famaord();
    famaord.inbogrl = inbo;
    famaord.famaord_enc = this.ord_Client.famaord_enc;
    const dialogRef = this.dialog.open(DialogProductoComponent, {
      width: '450px',
      data: famaord,
    });

    dialogRef.afterClosed().subscribe(result => {
      let fa : Famaord = new Famaord();
      if(result.data == undefined){
        this.snackBar.open("NO FUE PROCESADO, EXISTENCIA SUPERADA", 'ERROR',{
          duration:3000
        });
      }else{
        fa = result.data;
        
        this.ValidacionSaldo(fa,this.ord_Client);
        this.dialogRef.close();
      }
   });
  }

  ValidacionSaldo(p_fa : Famaord, p_Ord_Client : Ord_Client){
    this.ccmacobService.saldototalpendiente(p_Ord_Client.ccmacli.codclie).subscribe(data =>{
      console.log(data);
      this.agregarProducto(p_fa);
    })
  }

  agregarProducto(fa : Famaord){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    fa.numpedi = this.ord_Client.famaord_enc.numpedi;
    fa.famaord_enc = this.ord_Client.famaord_enc;
    fa.coment1 = this.p_inbogrl.descrip_ln;

    this.isLoadingResults = true;
    this.isRateLimitReached = true;

    this.usuarioService.buscarPorNombre(decodedToken.user_name).subscribe(data2 => {
      fa.idusuario = data2.usr_idUsuario;
      this.famaordService.agregarProducto(fa).subscribe(data=>{
        /*
        Registrar el la linea del pedido.
        Devolver la lista de las lineas del pedido para actualizar la vista.
        */
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
        this.famaordService.famaordCambio.next(data);
      })
    })
    
  }

  limiteSobreGirado(){
    let mensaje : String []= [
      "LIMITE DE CREDITO ALCANZADO",
      "SALDO DE CLIENTE ES:",
      this.saldoPendiente+"",
      "CREDITO DISPONIBLE ES:",
      this.limitecredito+""
    ]
    this.dialog.open(DialogErrorComponent, {
      width: '450px',
      data: mensaje
    });
  }

  actualizarInventarioRuta(){
    this.isLoadingResults = true;
    this.isRateLimitReached = true;
    this.inborutService.importarDBF(1).subscribe(data =>{
      console.log(data);
      this.inborutService.GuardarDBF(data).subscribe(data2 => {
        console.log(data2);
        if(data2.length == 0){
          this.snackBar.open("NO hay Productos con Actualizacion", 'AVISO',{
            duration:3000
          });
        }else{
          this.snackBar.open(data2.length +" Productos Actualizados", 'AVISO',{
            duration:3000
          });
          this.inbogrlService.ProductoBodega(this.codruta).subscribe(data => {
            console.log(data)
            this.dataSource = new MatTableDataSource(data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          });
        }
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
      })
    });
  }
}
