<div class="example-loading-shade"
       *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
      
    </div>
  </div>

<div class="example-container mat-elevation-z8">
    <mat-card class="example-card center">
    <mat-toolbar>Entrega de Pedidos</mat-toolbar>
        
    </mat-card>
</div>