export class Ccmaven{

    idCcmaven : number;
	codruta : string;
	tipruta : string;
	nomruta : string;
	supervi : string;
	vvtames : number;
	vvtaacu : number;
	vcobmes : number;
	vcobacu : number;
	vmetvta : number;
	vmetcob : number;
	codplan : string;
	bodruta : number;
	bodtras : number;
	ctaccaj : string;
	ctaccob : string;
	ctagas : string;
	ctacpag : string;
	ctacchq : string;
	comgral : number;
	estado : number;
}