<div class="example-loading-shade"
*ngIf="isLoadingResults || isRateLimitReached">
<mat-spinner *ngIf="isLoadingResults"></mat-spinner>
<div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
{{msj_spinner}}
</div>
</div>

<div class="main-div">
    <mat-card>
        <mat-grid-list cols="3" rowHeight="100px">
            <mat-grid-tile>
                <button mat-flat-button color="primary"  (click)="importarDBF()">Obtener datos</button>
            </mat-grid-tile>
            <mat-grid-tile>
                <button mat-flat-button color="primary"  (click)="guardarDBF()" *ngIf="isActualizarCliente">Actualizar</button>
            </mat-grid-tile>
        </mat-grid-list>
    </mat-card>    
    <div *ngIf="activeTable">
        <div class="example-header">
            <mat-form-field>
              <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar Sucursal">
            </mat-form-field>
        </div>
        <div>
            <mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                    <mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                                        [aria-label]="checkboxLabel()">
                        </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(row) : null"
                                        [checked]="selection.isSelected(row)"
                                        [aria-label]="checkboxLabel(row)">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="rutsucs" >
                    <mat-header-cell *matHeaderCellDef>Ruta</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.rutsucs}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="codsucs">
                    <mat-header-cell *matHeaderCellDef>CodSuc</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.codsucs}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="codclie">
                    <mat-header-cell *matHeaderCellDef>CodCliente</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.codclie}} </mat-cell>
                </ng-container>
            
                <ng-container matColumnDef="nomclie" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Cliente</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.nomsucs}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="deptosuc" >
                    <mat-header-cell *matHeaderCellDef>Departamento</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.deptosuc}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="munisuc" >
                    <mat-header-cell *matHeaderCellDef>Municipio</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.munisuc}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="dirsucs" >
                    <mat-header-cell *matHeaderCellDef>Direccion</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.dirsucs}} </mat-cell>
                </ng-container>
                
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)" [ngClass]="getRowClass(row)">
                </mat-row>
            </mat-table>
            <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
        </div>        
</div>
