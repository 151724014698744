import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-dialoginvalidar-dte',
  templateUrl: './dialoginvalidar-dte.component.html',
  styleUrls: ['./dialoginvalidar-dte.component.css']
})
export class DialoginvalidarDTEComponent implements OnInit {

  codigoGeneracionR : string;
  motivo : string;

  invalidarXerrorDTE : boolean = false;
  invalidarRecindirDTE : boolean = false;
  OtroDTE : boolean = false;
  opcionesInvalidacion : boolean = true;

  respose : string[] = [];
  constructor(private dialogRef: MatDialogRef<DialoginvalidarDTEComponent>,private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    
  }
  invalidarXerror(){
    this.opcionesInvalidacion = false;
    this.invalidarRecindirDTE = false;
    this.OtroDTE = false;
    this.invalidarXerrorDTE = true;
  }

  invalidarRecindir(){
    this.opcionesInvalidacion = false;
    this.invalidarXerrorDTE = false;
    this.OtroDTE = false;
    this.invalidarRecindirDTE = true;
  }

  Otro(){
    this.opcionesInvalidacion = false;
    this.invalidarXerrorDTE = false;
    this.invalidarRecindirDTE = false;
    this.OtroDTE = true;
  }

  invalidarXerror_btn(){
    this.respose.push(this.codigoGeneracionR);
    this.respose.push(this.motivo);
    this.respose.push("1");
    if(this.motivo != null && this.motivo.length>0 && this.codigoGeneracionR != null && this.codigoGeneracionR.length>0 && this.codigoGeneracionR.length<36){
      this.dialogRef.close({event:'close',data:this.respose});
    }else{
      this.snackBar.open('CAMPO VACIO', 'ERROR',{
        duration:3000
      });
    }
  }

  invalidarRecindir_btn(){
    this.respose.push(this.codigoGeneracionR);
    this.respose.push(this.motivo);
    this.respose.push("2");
    if(this.motivo != null && this.motivo.length>0){
      this.dialogRef.close({event:'close',data:this.respose});
    }else{
      this.snackBar.open('CAMPO VACIO', 'ERROR',{
        duration:3000
      });
    }
  }

  Otro_btn(){
    this.respose.push(this.codigoGeneracionR);
    this.respose.push(this.motivo);
    this.respose.push("3");
    if(this.motivo != null && this.motivo.length>0 && this.codigoGeneracionR != null && this.codigoGeneracionR.length>0 && this.codigoGeneracionR.length<36){
      this.dialogRef.close({event:'close',data:this.respose});
    }else{
      this.snackBar.open('CAMPO VACIO', 'ERROR',{
        duration:3000
      });
    }
  }

  cerrarDialog(){
    this.dialogRef.close();
  }
}
