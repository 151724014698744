<div class="example-loading-shade"
       *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
      CARGANDO
    </div>
  </div>

<mat-toolbar>Crear Pedido</mat-toolbar>

<div class="example-container mat-elevation-z8">

  <div class="example-header">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar cliente">
    </mat-form-field>
  </div>

  <mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="codclie">
      <mat-header-cell *matHeaderCellDef mat-sort-header>CodCliente</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.codclie}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="nomclie" >
      <mat-header-cell *matHeaderCellDef mat-sort-header>Cliente</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.nomclie}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="acciones">
      <mat-header-cell *matHeaderCellDef > Acciones </mat-header-cell>
      <mat-cell *matCellDef="let row"> 
          <button mat-raised-button color="primary" (click)="openDialog(row)">ORDEN</button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;">
    </mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</div>

<br>