import { DialogimportarinvendbfComponent } from './pages/contabilidad/dialogimportarinvendbf/dialogimportarinvendbf.component';
import { DialogselectvendedorComponent } from './pages/pedidos/dialogselectvendedor/dialogselectvendedor.component';

import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { MaterialModule } from './material/material.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { PedidosComponent } from './pages/pedidos/pedidos.component';
import { DialogPedidoComponent } from './pages/pedidos/dialog-pedido/dialog-pedido.component';
import { IngresarpedidoComponent } from './pages/pedidos/ingresarpedido/ingresarpedido.component';
import { DialogProductosComponent } from './pages/pedidos/dialog-productos/dialog-productos.component';
import { DialogProductoComponent } from './pages/pedidos/dialog-producto/dialog-producto.component';
import { UsuarioComponent } from './pages/usuario/usuario.component';
import { DialogUsuarioComponent } from './pages/usuario/dialog-usuario/dialog-usuario.component';
import { RolComponent } from './pages/rol/rol.component';
import { MenuComponent } from './pages/menu/menu.component';
import { RolDialogoComponent } from './pages/rol/rol-dialogo/rol-dialogo.component';
import { environment } from 'src/environments/environment';

import { ImpresionComponent } from './pages/pedidos/impresion/impresion.component';
import { ServerErrorsInterceptor } from './_shared/server-errors.interceptor';
//import { LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';
import { ConsultarpedidoComponent } from './pages/pedidos/consultarpedido/consultarpedido.component';
import { AsignarusauarioComponent } from './pages/usuario/asignarusauario/asignarusauario.component';
import { AsignarusauariodialogComponent } from './pages/usuario/asignarusauariodialog/asignarusauariodialog.component';
import { AsignarclienteComponent } from './pages/contabilidad/asignarcliente/asignarcliente.component';
import { SelectCcmaclidialogComponent } from './pages/clientes/select-ccmaclidialog/select-ccmaclidialog.component';
import { SucursalesComponent } from './pages/clientes/sucursales/sucursales.component';
import { ClientesComponent } from './pages/clientes/clientes/clientes.component';
import { ProcesodatosComponent } from './pages/procesos/procesodatos/procesodatos.component';
import { SucursaldialogComponent } from './pages/clientes/sucursaldialog/sucursaldialog.component';
import { DialogFamaordCantComponent } from './pages/pedidos/dialog-famaord-cant/dialog-famaord-cant.component';
import { DialogFamaordPreComponent } from './pages/pedidos/dialog-famaord-pre/dialog-famaord-pre.component';
import { DialogFamaordCantCajaComponent } from './pages/pedidos/dialog-famaord-cant-caja/dialog-famaord-cant-caja.component';
import { DialogFamaordDescProdComponent } from './pages/pedidos/dialog-famaord-desc-prod/dialog-famaord-desc-prod.component';
import { DialogFamaordDescFactuComponent } from './pages/pedidos/dialog-famaord-desc-factu/dialog-famaord-desc-factu.component';
import { Not403Component } from './pages/not403/not403.component';
import { DialogErrorComponent } from './pages/utils/dialog-error/dialog-error.component';
import { EntregapedidoComponent } from './pages/pedidos/entregapedido/entregapedido.component';
import { AsignarpedidoComponent } from './pages/pedidos/asignarpedido/asignarpedido.component';
import { DialogvalidarclaveComponent } from './pages/control/dialogvalidarclave/dialogvalidarclave.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { InventarioComponent } from './pages/contabilidad/inventario/inventario.component';
import { ImportarinventarioComponent } from './pages/contabilidad/importarinventario/importarinventario.component';
import { AsignarclientesComponent } from './pages/procesos/asignarclientes/asignarclientes.component';
import { DialogmenuimpresionComponent } from './pages/pedidos/dialogmenuimpresion/dialogmenuimpresion.component';
import { IngresoclientesComponent } from './pages/clientes/ingresoclientes/ingresoclientes.component';
import { IngresoInventarioComponent } from './pages/contabilidad/ingreso-inventario/ingreso-inventario.component';
import { DialogselectinbogrlComponent } from './pages/contabilidad/dialogselectinbogrl/dialogselectinbogrl.component';
import { DatePipe, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { JwtModule } from '@auth0/angular-jwt';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ImportarvendedorComponent } from './pages/ventas/importarvendedor/importarvendedor.component';
import { AutorizacionpedidoComponent } from './pages/pedidos/autorizacionpedido/autorizacionpedido.component';
import { ImportarcobrosComponent } from './pages/contabilidad/importarcobros/importarcobros.component';
import { HistorialPagosComponent } from './pages/pedidos/historial-pagos/historial-pagos.component';
import { ImportardbfComponent } from './pages/pedidos/clientes/importardbf/importardbf.component';
import { DialogimportardbfComponent } from './pages/pedidos/dialogimportardbf/dialogimportardbf.component';
import { WhatsappComponent } from './pages/utils/whatsapp/whatsapp.component';
import { FacturasComponent } from './pages/facturacion/facturas/facturas.component';
import { WebBluetoothModule } from '@manekinekko/angular-web-bluetooth';
import { ImportarinventariorutaComponent } from './pages/contabilidad/importarinventarioruta/importarinventarioruta.component';
import { DialogimportarinvenrutadbfComponent } from './pages/contabilidad/dialogimportarinvenrutadbf/dialogimportarinvenrutadbf.component';
import { DialogfacturasComponent } from './pages/facturacion/dialogfacturas/dialogfacturas.component';
import { DialogvalidacionDTEComponent } from './pages/facturacion/dialogvalidacion-dte/dialogvalidacion-dte.component';
import { SubirjsonschemasmhComponent } from './pages/procesos/subirjsonschemasmh/subirjsonschemasmh.component';
import { SubirreportesComponent } from './pages/procesos/subirreportes/subirreportes.component';
import { DialoginvalidarDTEComponent } from './pages/facturacion/dialoginvalidar-dte/dialoginvalidar-dte.component';
import { DialogvalidacionDTECFComponent } from './pages/facturacion/dialogvalidacion-dte-cf/dialogvalidacion-dte-cf.component';
import { SubirsucursalesComponent } from './pages/procesos/subirsucursales/subirsucursales.component';
import { DialogimportarccmasucComponent } from './pages/procesos/subirsucursales/dialogimportarccmasuc/dialogimportarccmasuc.component';
import { ConsultadteComponent } from './pages/facturacion/consultadte/consultadte.component';
import { SubirnotascreditoComponent } from './pages/procesos/subirnotascredito/subirnotascredito.component';
import { NotacreditoComponent } from './pages/facturacion/notacredito/notacredito.component';
import { DescargaDteComponent } from './pages/cliente_externo/descarga-dte/descarga-dte.component';
import { LoaderComponent } from './pages/utils/loader/loader.component';
import { SujetoexcluidoComponent } from './pages/facturacion/sujetoexcluido/sujetoexcluido.component';
import { SubirproveedoresComponent } from './pages/procesos/subirproveedores/subirproveedores.component';
import { DialogimportarcpmaprvComponent } from './pages/procesos/subirproveedores/dialogimportarcpmaprv/dialogimportarcpmaprv.component';
import { DialogselecionarprovComponent } from './pages/facturacion/sujetoexcluido/dialogselecionarprov/dialogselecionarprov.component';
import { DialognotacreditoComponent } from './pages/facturacion/notacredito/dialognotacredito/dialognotacredito.component';
import { FacturaexportacionComponent } from './pages/facturacion/facturaexportacion/facturaexportacion.component';
import { DialogselecionarclienteexportacionComponent } from './pages/facturacion/facturaexportacion/dialogselecionarclienteexportacion/dialogselecionarclienteexportacion.component';

export function tokenGetter() {
  let tk = sessionStorage.getItem(environment.TOKEN_NAME);
  let token = tk != null ? tk : '';
  return token;
}

@NgModule({
    declarations: [
        Not403Component,
        AppComponent,
        LoginComponent,
        DashboardComponent,
        PedidosComponent,
        DialogPedidoComponent,
        IngresarpedidoComponent,
        DialogProductosComponent,
        DialogProductoComponent,
        UsuarioComponent,
        DialogUsuarioComponent,
        RolComponent,
        MenuComponent,
        RolDialogoComponent,
        ImpresionComponent,
        ConsultarpedidoComponent,
        AsignarusauarioComponent,
        AsignarusauariodialogComponent,
        AsignarclienteComponent,
        SelectCcmaclidialogComponent,
        SucursalesComponent,
        ClientesComponent,
        ProcesodatosComponent,
        SucursaldialogComponent,
        DialogFamaordCantComponent,
        DialogFamaordPreComponent,
        DialogFamaordCantCajaComponent,
        DialogFamaordDescProdComponent,
        DialogFamaordDescFactuComponent,
        DialogErrorComponent,
        EntregapedidoComponent,
        AsignarpedidoComponent,
        DialogvalidarclaveComponent,
        PerfilComponent,
        InventarioComponent,
        ImportarinventarioComponent,
        AsignarclientesComponent,
        DialogmenuimpresionComponent,
        IngresoclientesComponent,
        IngresoInventarioComponent,
        DialogselectvendedorComponent,
        DialogselectinbogrlComponent,
        ImportarvendedorComponent,
        AutorizacionpedidoComponent,
        ImportarcobrosComponent,
        HistorialPagosComponent,
        ImportardbfComponent,
        DialogimportardbfComponent,
        DialogimportarinvendbfComponent,
        WhatsappComponent,
        FacturasComponent,
        ImportarinventariorutaComponent,
        DialogimportarinvenrutadbfComponent,
        DialogfacturasComponent,
        DialogvalidacionDTEComponent,
        SubirjsonschemasmhComponent,
        SubirreportesComponent,
        DialoginvalidarDTEComponent,
        DialogvalidacionDTECFComponent,
        SubirsucursalesComponent,
        DialogimportarccmasucComponent,
        ConsultadteComponent,
        SubirnotascreditoComponent,
        NotacreditoComponent,
        DescargaDteComponent,
        LoaderComponent,
        SujetoexcluidoComponent,
        SubirproveedoresComponent,
        DialogimportarcpmaprvComponent,
        DialogselecionarprovComponent,
        DialognotacreditoComponent,
        FacturaexportacionComponent,
        DialogselecionarclienteexportacionComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                whitelistedDomains: ['localhost:8080', '200.31.165.202:8080','25.81.172.224:8080','192.168.1.7:8080','10.3.10.131:8080','https://api.sis2000.online:8443','http://3.22.250.79:8080'],
                blacklistedRoutes: ['http://ec2-18-215-186-121.compute-1.amazonaws/login/enviarCorreo']
            }
        }),
        WebBluetoothModule.forRoot({
            enableTracing: true
          }),
    ],
    providers: [
        DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ServerErrorsInterceptor,
            multi: true,
        },
        { provide: LocationStrategy, useClass: HashLocationStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
