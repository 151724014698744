import { AsignarusauariodialogComponent } from './../asignarusauariodialog/asignarusauariodialog.component';
import { UsuarioCcmavenService } from './../../../_service/usuario/vendedor/usuario-ccmaven.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Ccmaven } from 'src/app/_model/ccmaven';
import { Ccmacli } from 'src/app/_model/ccmacli';
import { CcmacliService } from 'src/app/_service/ccmacli.service';
import { CcmavenService } from 'src/app/_service/ccmaven.service';
import { UsuarioService } from 'src/app/_service/seguridad/usuario.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { Usuario } from 'src/app/_model/seguridad/usuario';
import { UsuarioCcmaven } from 'src/app/_model/vendedor/UsuarioCcmaven';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-asignarusauario',
  templateUrl: './asignarusauario.component.html',
  styleUrls: ['./asignarusauario.component.css']
})
export class AsignarusauarioComponent implements OnInit {
  isLoadingResults = true;
  isRateLimitReached = true;

  displayedColumns = ['usuario', 'empleado', 'acciones'];
  dataSource: MatTableDataSource<UsuarioCcmaven>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private ccmacliService : CcmacliService, private snackBar: MatSnackBar, private dialog: MatDialog,
    private ccmavenService : CcmavenService, private usuarioService : UsuarioService, private usuarioCcmavenService : UsuarioCcmavenService) { }

  ngOnInit() {
    this.usuarioCcmavenService.usuarioCcmavenCambio.subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });

    this.usuarioCcmavenService.mensajeCambio.subscribe(data => {
      this.snackBar.open(data, 'AVISO',{
        duration:2000
      });
    });

    this.usuarioCcmavenService.listar().subscribe(data => {
      console.log(data);
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.isLoadingResults = false;
      this.isRateLimitReached = false;
    });
  }

  asignarDialog(){
    this.dialog.open(AsignarusauariodialogComponent, {
      width: '650px',
      //data: med
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
