<div>
    <mat-form-field>
        <input matInput placeholder="Rol" [(ngModel)]="rol.rol_nomrol">
    </mat-form-field>
    <mat-form-field>
        <input matInput placeholder="Description" [(ngModel)]="rol.rol_desrol">
    </mat-form-field>
    <mat-form-field>
        <input matInput placeholder="Estado" [(ngModel)]="rol.rol_estado" max="15" maxlength="15">
    </mat-form-field>
</div>
<button mat-raised-button color="primary" (click)="operar()">Aceptar</button>
<button mat-raised-button color="accent" (click)="cancelar()">Cancelar</button>