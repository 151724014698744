<div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
        {{msj_spinner}}
    </div>
</div>

<mat-card>
    <mat-grid-list cols="3" rowHeight="100px">
        <mat-grid-tile>
            <button type="button" mat-raised-button  color="primary" onclick="document.getElementById('flArchivo').click()" >Subir Json</button>
            <input id="flArchivo" type="file" style="display: none;" (change)="subirArchivo($event)">
        </mat-grid-tile>
        <mat-grid-tile>
            <button type="button" mat-raised-button  color="primary" onclick="document.getElementById('JsonArchivo').click()" >Actulizar Json</button>
                <input id="JsonArchivo" type="file" style="display: none;" (change)="actualizarJson($event)">
        </mat-grid-tile>
    </mat-grid-list>
</mat-card>  

<div>
    <mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                [aria-label]="checkboxLabel()">
                </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(row) : null"
                                [checked]="selection.isSelected(row)"
                                [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef>Id</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.id}} </mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="descripcion" >
            <mat-header-cell *matHeaderCellDef>Descripcion</mat-header-cell>
            <mat-cell *matCellDef="let row"> 
                <input matInput [(ngModel)]="row.descripcion" type="text" name="newuserName" id="newuserName" class="form-control" required>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="filetype" >
            <mat-header-cell *matHeaderCellDef>Tipo Archivo</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.filetype}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="acciones" >
            <mat-header-cell *matHeaderCellDef>Acciones</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <button mat-flat-button color="primary"  (click)="actualizar(row)">Actulizar</button>
                <button mat-flat-button color="primary"  (click)="borrar(row)">Borrar</button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</div>