import { InmapreService } from './../../../_service/inventario/inmapre.service';
import { CcmavenService } from 'src/app/_service/ccmaven.service';
import { Component, OnInit } from '@angular/core';
import { Ccmaven } from '../../../_model/ccmaven';
import { Router, ActivatedRoute } from '@angular/router';
import { Ccmacli } from 'src/app/_model/ccmacli';
import { CcmacliClasificacionService } from 'src/app/_service/ccmacli-clasificacion.service';
import { Ccmacli_Clasificacion } from 'src/app/_model/ccmacli_clasificacion';
import { DepartamentoService } from 'src/app/_service/departamento.service';
import { Departamento } from 'src/app/_model/utils/departamento';

@Component({
  selector: 'app-ingresoclientes',
  templateUrl: './ingresoclientes.component.html',
  styleUrls: ['./ingresoclientes.component.css']
})
export class IngresoclientesComponent implements OnInit {
  
  ccmacli : Ccmacli;
  ccmacliClasificacion : Ccmacli_Clasificacion [] = [];
  idccmacli_clasificacion_select : number;
  departamentos : Departamento [] = [];
  departamento_select : String;
  municipios : String [] = [];
  municipio_select : String;
  vendedor : Ccmaven;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private ccmacliClasificacionService : CcmacliClasificacionService,
              private departamentoService : DepartamentoService,
              private ccmavenService : CcmavenService,
              private InmapreService : InmapreService
            ) { 

    this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.ccmacli = new Ccmacli();
        this.ccmacli = this.router.getCurrentNavigation().extras.state.cliente;
        
        this.ccmacliClasificacionService.leerPorcodigo(this.ccmacli.codclie.substring(0,2)).subscribe(p_clase =>{
          this.idccmacli_clasificacion_select = p_clase.idccmacli_clasificacion;
        })
        
        this.departamentoService.leerporcodigo(this.convertNumber(this.ccmacli.deppais)).subscribe(dep => {
          this.departamento_select = dep.coddep;
          this.departamentoService.municipos_por_departamento(this.convertNumber(this.ccmacli.deppais)).subscribe(mun =>{
            this.municipios = mun;
            this.municipio_select = this.ccmacli.munclie;
          })
        })
        console.log(this.ccmacli.rutclie)
        this.ccmavenService.findbyCodruta(this.ccmacli.rutclie).subscribe(p_ccmaven => {
          this.vendedor = p_ccmaven;
        })       
      }else{
        this.router.navigate(['clientes']);
      }
    });
  }

  ngOnInit() {
    this.ccmacliClasificacionService.listar().subscribe(clases => {
      this.ccmacliClasificacion = clases;
    })

    this.departamentoService.listaDepartamentos().subscribe(depa => {
      this.departamentos = depa;
      console.log(this.departamentos);
    })
  }

  convertNumber(num : number){
    let strNum : String;
    if(num < 10){
      strNum = '0'.concat(num.toString());
    }else{
      strNum = num.toString();
    }
    return strNum;
  }

  leerListaPrecio(){
    this.InmapreService.importarDBF(1).subscribe(data =>{

    })
  }

}
