<app-loader *ngIf="isLoading" (animationCompleted)="onLoaderAnimationComplete()"></app-loader>
<div *ngIf="!isLoading">
  <!-- Contenido principal de la página -->
  <!-- Aquí va el contenido que se mostrará una vez que se cargue -->
  <div class="input-container">
    <div class="extra-buttons" *ngIf="showExtraButtons">
        <button class="square-button">
          <img src="assets/PDF_DONW.png" alt="Imagen 1">
        </button>
        <button class="square-button">
          <img src="assets/JSON_DONW.png" alt="Imagen 2">
        </button>
      </div>

    <input type="text" placeholder="Digite el codigo de generacion" class="custom-input">
    <button class="custom-button" (click)="showButtons()">Consultar</button>
  </div>
</div>
