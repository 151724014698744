import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Inborut } from './../../_model/invetario/inborut';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class InborutService {

  InbogrlCambio = new Subject<Inborut[]>();
  mensajeCambio = new Subject<string>();

  url: string = `${environment.HOST}/bodega_ruta`;
  
  constructor(private http: HttpClient) { }

  importarDBF(id : number){
    return this.http.get<Inborut[]>(`${this.url}/importardbf/${id}`,this.getToken());
  }

  GuardarDBF(lista : Inborut[]){
    return this.http.post<Inborut[]>(`${this.url}/guardardbf`, lista, this.getToken());
  }

  ProductoBodega(codrutart : number){
    return this.http.get<Inborut[]>(`${this.url}/producto_bodega/${codrutart}`,this.getToken());
  }

  ExistenciaProductoBodega(codrutart : number, codprod_rt : string){
    return this.http.get<number>(`${this.url}/existencia_producto_bodega/${codrutart}/${codprod_rt}`,this.getToken());
  }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }
}
