<h2>{{cliente.nomclie}}</h2>
<h4>Del mas antiguo al mas reciente.</h4>
<mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="codclie">
      <mat-header-cell *matHeaderCellDef mat-sort-header>CodCliente</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.codclie}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="nomclie" >
      <mat-header-cell *matHeaderCellDef mat-sort-header>Num. Doc</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.numdocu}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="fechadocumento" >
      <mat-header-cell *matHeaderCellDef mat-sort-header>Fecha. Doc</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.fecdocu | date}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="fechacobro" >
      <mat-header-cell *matHeaderCellDef mat-sort-header>Fecha. Cob</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.feccobr | date}} </mat-cell>
    </ng-container>  
    
    <ng-container matColumnDef="fultabo" >
      <mat-header-cell *matHeaderCellDef mat-sort-header>F. Ultimo Abono</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{revisarFecha(row)}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="ultimoabono" >
      <mat-header-cell *matHeaderCellDef mat-sort-header>Ultimo Abono</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.valactu | currency}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="acciones">
      <mat-header-cell *matHeaderCellDef > Acciones </mat-header-cell>
      <mat-cell *matCellDef="let row"> 
          <button mat-raised-button color="primary" (click)="abonar(row)">Abonar</button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;">
    </mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]"></mat-paginator>