import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subject } from 'rxjs';
import { CuerpoDocumentoCF } from 'src/app/_model/dte/cf/cuerpodocumentocf';
import { CuerpoDocumento } from 'src/app/_model/dte/cuerpodocumento';
import { Resumen } from 'src/app/_model/dte/resumen';
import { Famadoc } from 'src/app/_model/famadoc';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DetalleFacturaService {

  ctmadocCambio = new Subject<CuerpoDocumento[]>();
  mensajeCambio = new Subject<string>();

  url: string = `${environment.HOST}/detalle_facturacion`;
  
  constructor(private http: HttpClient) { }

  detalle_dte_ccf(coddocu : string){
    return this.http.get<CuerpoDocumento[]>(`${this.url}/detalle_dte_ccf/${coddocu}`,this.getToken());
  }

  resumen_dte_ccf(coddocu : string, totalDescu : number){
    return this.http.get<Resumen>(`${this.url}/resumen_dte_ccf/${coddocu}/${totalDescu}`,this.getToken());
  }

  detalle_dte_cf(coddocu : string){
    return this.http.get<CuerpoDocumentoCF[]>(`${this.url}/detalle_dte_cf/${coddocu}`,this.getToken());
  }

  resumen_dte_cf(coddocu : string, totalDescu : number){
    return this.http.get<Resumen>(`${this.url}/resumen_dte_cf/${coddocu}/${totalDescu}`,this.getToken());
  }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }
}
