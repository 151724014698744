<p>Ingresar Clave para:</p>
<p>Activar pedido: {{famaord_enc.numpedi}}</p>
<form #myform="ngForm" class="example-form">
<mat-form-field>
    <label for="usuario">Usuario</label>
    <input type="text" matInput [(ngModel)]="usuario" name="usuario">
</mat-form-field>
<mat-form-field>
    <label for="clave">Clave</label>
    <input type="password" matInput [(ngModel)]="clave" name="clave">
</mat-form-field>
<button type="submit" mat-raised-button [disabled]="myform.invalid" color="primary" (click)="validarClave()" >Validar</button>
</form>