import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CcmacobService } from 'src/app/_service/cliente/ccmacob.service';

@Component({
  selector: 'app-importarcobros',
  templateUrl: './importarcobros.component.html',
  styleUrls: ['./importarcobros.component.css']
})
export class ImportarcobrosComponent implements OnInit {

  isLoadingResults = false;
  isRateLimitReached = false;

  constructor(private ccmacobService : CcmacobService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.ccmacobService.mensajeCambio.subscribe(data => {
      this.snackBar.open(data, 'AVISO',{
        duration:2000
      });
    });
  }

  importarDBF(){
    this.ccmacobService.importarDBF(1).subscribe(data => {
      
    })
  }

}
