import { ImportardbfComponent } from './pages/pedidos/clientes/importardbf/importardbf.component';
import { HistorialPagosComponent } from './pages/pedidos/historial-pagos/historial-pagos.component';
import { ImportarcobrosComponent } from './pages/contabilidad/importarcobros/importarcobros.component';
import { AutorizacionpedidoComponent } from './pages/pedidos/autorizacionpedido/autorizacionpedido.component';
import { ImportarvendedorComponent } from './pages/ventas/importarvendedor/importarvendedor.component';
import { IngresoclientesComponent } from './pages/clientes/ingresoclientes/ingresoclientes.component';
import { ImportarinventarioComponent } from './pages/contabilidad/importarinventario/importarinventario.component';
import { InventarioComponent } from './pages/contabilidad/inventario/inventario.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { AsignarpedidoComponent } from './pages/pedidos/asignarpedido/asignarpedido.component';
import { EntregapedidoComponent } from './pages/pedidos/entregapedido/entregapedido.component';
import { ProcesodatosComponent } from './pages/procesos/procesodatos/procesodatos.component';
import { ClientesComponent } from './pages/clientes/clientes/clientes.component';
import { SucursalesComponent } from './pages/clientes/sucursales/sucursales.component';
import { AsignarclienteComponent } from './pages/contabilidad/asignarcliente/asignarcliente.component';
import { AsignarusauarioComponent } from './pages/usuario/asignarusauario/asignarusauario.component';
import { ConsultarpedidoComponent } from './pages/pedidos/consultarpedido/consultarpedido.component';
import { RolComponent } from './pages/rol/rol.component';
import { UsuarioComponent } from './pages/usuario/usuario.component';
import { PedidosComponent } from './pages/pedidos/pedidos.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { IngresarpedidoComponent } from './pages/pedidos/ingresarpedido/ingresarpedido.component';
import { GuardService } from './_service/seguridad/guard.service';
import { AsignarclientesComponent } from './pages/procesos/asignarclientes/asignarclientes.component';
import { IngresoInventarioComponent } from './pages/contabilidad/ingreso-inventario/ingreso-inventario.component';
import { FacturasComponent } from './pages/facturacion/facturas/facturas.component';
import { ImportarinventariorutaComponent } from './pages/contabilidad/importarinventarioruta/importarinventarioruta.component';
import { SubirjsonschemasmhComponent } from './pages/procesos/subirjsonschemasmh/subirjsonschemasmh.component';
import { SubirreportesComponent } from './pages/procesos/subirreportes/subirreportes.component';
import { SubirsucursalesComponent } from './pages/procesos/subirsucursales/subirsucursales.component';
import { ConsultadteComponent } from './pages/facturacion/consultadte/consultadte.component';
import { NotacreditoComponent } from './pages/facturacion/notacredito/notacredito.component';
import { DescargaDteComponent } from './pages/cliente_externo/descarga-dte/descarga-dte.component';

const routes: Routes = [
  
  {path: 'login' , component: LoginComponent},
  {path: 'dashboard' , component: DashboardComponent, canActivate : [GuardService]},
  {path: 'pedidos' , component: PedidosComponent},
  {path: 'ingresarpedido' , component: IngresarpedidoComponent, canActivate : [GuardService]},
  {path: 'usuarios', component: UsuarioComponent, canActivate : [GuardService]},
  {path: 'roles', component: RolComponent, canActivate : [GuardService]},

  {path: 'consultarpedido', component: ConsultarpedidoComponent, canActivate : [GuardService]},
  {path: 'asignarusauario', component: AsignarusauarioComponent, canActivate : [GuardService]},
  {path: 'asignarcliente', component: AsignarclienteComponent, canActivate : [GuardService]},
  {path: 'sucursales', component: SucursalesComponent, canActivate : [GuardService]},
  {path: 'clientes', component: ClientesComponent, canActivate : [GuardService]},
  {path: 'procesos', component: ProcesodatosComponent, canActivate : [GuardService]},
  {path: 'entregapedido', component: EntregapedidoComponent, canActivate : [GuardService]},
  {path: 'asignarpedido', component: AsignarpedidoComponent, canActivate : [GuardService]},
  {path: 'perfil', component: PerfilComponent, canActivate : [GuardService]},
  {path: 'inventario', component: InventarioComponent, canActivate : [GuardService]},
  {path: 'importarinventario', component: ImportarinventarioComponent, canActivate : [GuardService]},
  {path: 'importarvendedor', component: ImportarvendedorComponent, canActivate : [GuardService]},
  {path: 'asignarclientes', component: AsignarclientesComponent, canActivate : [GuardService]},
  {path: 'ingresoclientes', component: IngresoclientesComponent, canActivate : [GuardService]},
  {path: 'ingresoinventario', component: IngresoInventarioComponent, canActivate: [GuardService]} ,
  {path: 'autorizacionpedido', component: AutorizacionpedidoComponent, canActivate: [GuardService]} ,
  {path: 'importarcobros', component: ImportarcobrosComponent, canActivate: [GuardService]} ,
  {path: 'historialpagos', component: HistorialPagosComponent, canActivate: [GuardService]} ,
  {path: 'importardbf', component: ImportardbfComponent, canActivate: [GuardService]} ,
  {path: 'importarinventarioruta', component: ImportarinventariorutaComponent, canActivate: [GuardService]},
  {path: 'facturas', component: FacturasComponent, canActivate:[GuardService]},
  {path: 'subirjsonschemasmh', component: SubirjsonschemasmhComponent, canActivate:[GuardService]},
  {path: 'subirsucursales', component: SubirsucursalesComponent, canActivate:[GuardService]},
  {path: 'subirreportes', component: SubirreportesComponent, canActivate:[GuardService]},
  {path: 'consultardte', component: ConsultadteComponent, canActivate:[GuardService]},
  {path: 'notacredito', component: NotacreditoComponent, canActivate:[GuardService]},
  {path: 'descarga_dte' , component: DescargaDteComponent},
  {path: '**' , redirectTo: 'login', pathMatch: 'full' }
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
