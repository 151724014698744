import { NotacreditodteService } from './../../../_service/dte/notacreditodte.service';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CtMaDoc } from 'src/app/_model/ctmadoc';
import { ResponseCCfDTE } from 'src/app/_model/dte/responseccfdte';
import { Famadoc } from 'src/app/_model/famadoc';
import { FacturadteService } from 'src/app/_service/dte/facturadte.service';
import { ResponseCCFDTEService } from 'src/app/_service/dte/response-ccf-dte.service';
import { EncabeadoFacturasService } from 'src/app/_service/facturacion/encabeado-facturas.service';
import { FacturasService } from 'src/app/_service/facturacion/facturas.service';
import { DialogfacturasComponent } from '../dialogfacturas/dialogfacturas.component';
import { DialoginvalidarDTEComponent } from '../dialoginvalidar-dte/dialoginvalidar-dte.component';
import { DialogvalidacionDTECFComponent } from '../dialogvalidacion-dte-cf/dialogvalidacion-dte-cf.component';
import { DialogvalidacionDTEComponent } from '../dialogvalidacion-dte/dialogvalidacion-dte.component';

@Component({
  selector: 'app-notacredito',
  templateUrl: './notacredito.component.html',
  styleUrls: ['./notacredito.component.css']
})
export class NotacreditoComponent implements OnInit {

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  isLoadingResults = false;
  isRateLimitReached = false;

  encabezados : CtMaDoc[] = [];
  detalles : Famadoc[] = [];

  documentos : CtMaDoc[] = [];

  displayedColumns = ['tipodoc','coddocu','codclie','cliente','fecdocu','rutavta','acciones'];
  dataSource: MatTableDataSource<CtMaDoc>;

  fechaIni : Date;
  fechaFin : Date;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private facturasService : FacturasService, private encabeadoFacturasService : EncabeadoFacturasService,
    private facturadteService : FacturadteService,private dialog: MatDialog,private datePipe: DatePipe, private responseCCFDTEService : ResponseCCFDTEService
    , private notacreditodteService : NotacreditodteService) {
      this.encabeadoFacturasService.ctmadocCambio.subscribe(data =>{
        this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          // Configurar la dirección de ordenación inicial
          const sortState: Sort = {active: 'fecdocu', direction: 'desc'};
          this.sort.active = sortState.active;
          this.sort.direction = sortState.direction;
          this.sort.sortChange.emit(sortState);
      })
   }

  ngOnInit(): void {
    const dialogRef = this.dialog.open(DialogfacturasComponent, {
      width: '450px',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result.data!=null){
        this.range = result.data;
        this.fechaIni = this.range.value.start;
        this.fechaFin = this.range.value.end;
        this.fechaIni.setDate(this.fechaIni.getDate() - 1);
        this.fechaFin.setDate(this.fechaFin.getDate() + 1);
        this.isLoadingResults = true;
        this.isRateLimitReached = true;
        this.encabeadoFacturasService.listarRangoFechaNC(this.datePipe.transform(this.fechaIni,"yyyy-MM-dd"),this.datePipe.transform(this.fechaFin,"yyyy-MM-dd")).subscribe(data => {
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          // Configurar la dirección de ordenación inicial
          const sortState: Sort = {active: 'fecdocu', direction: 'desc'};
          this.sort.active = sortState.active;
          this.sort.direction = sortState.direction;
          this.sort.sortChange.emit(sortState); 

          this.isLoadingResults = false;
          this.isRateLimitReached = false;
        })
    }})
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ImportarFacturas(){
    const dialogRef = this.dialog.open(DialogfacturasComponent, {
      width: '650px',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result.data!=null){
        this.range = result.data;
        this.fechaIni = this.range.value.start;
        this.fechaFin = this.range.value.end;
        this.fechaIni.setDate(this.fechaIni.getDate() - 1);
        this.fechaFin.setDate(this.fechaFin.getDate() + 1);
        this.isLoadingResults = true;
        this.isRateLimitReached = true;
        this.encabeadoFacturasService.importarDBF(1,this.datePipe.transform(this.fechaIni,"yyyy-MM-dd"),this.datePipe.transform(this.fechaFin,"yyyy-MM-dd")).subscribe(encabezado => {
          this.dataSource = new MatTableDataSource(encabezado);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          console.log(encabezado);
          this.encabezados = encabezado;
          this.facturasService.importarDBF(1,this.datePipe.transform(this.fechaIni,"yyyy-MM-dd"),this.datePipe.transform(this.fechaFin,"yyyy-MM-dd")).subscribe(detalles => {
            console.log(detalles);
            this.detalles = detalles;
            this.isLoadingResults = false;
            this.isRateLimitReached = false;
          })
        })
      }
    })
  }

  GuardarFacturas(){
    this.isLoadingResults = true;
    this.isRateLimitReached = true;
    this.encabeadoFacturasService.guardarDBF(this.encabezados).subscribe(data => {
      //console.log(data);
      this.GuardarDetalle();

    })
  }

  ImportarDetalle(){
    const dialogRef = this.dialog.open(DialogfacturasComponent, {
      width: '650px',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.range = result.data;
      this.fechaIni = this.range.value.start;
      this.fechaFin = this.range.value.end;
      this.fechaIni.setDate(this.fechaIni.getDate() - 1);
      this.fechaFin.setDate(this.fechaFin.getDate() + 1);
      this.isLoadingResults = true;
      this.isRateLimitReached = true;
      this.facturasService.importarDBF(1,this.datePipe.transform(this.fechaIni,"yyyy-MM-dd"),this.datePipe.transform(this.fechaFin,"yyyy-MM-dd")).subscribe(data => {
        console.log(data)
        this.detalles = data;
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
      })
    })
  }

  GuardarDetalle(){
    
    this.facturasService.guardarDBF(this.detalles).subscribe(data => {
      console.log(data);
      this.isLoadingResults = false;
      this.isRateLimitReached = false;
    })
      
  }

  GetTipoDoc( ctmadoc : CtMaDoc ){
    let tipoDocumento = ctmadoc.tipdoca+ctmadoc.cladoca
    //console.log(tipoDocumento)
    let tip : String;
    switch(tipoDocumento) { 
      case '10': { 
        tip = 'CCF'
         break; 
      } 
      case '11': { 
        tip = 'FAC'
         break; 
      } 
      case '30': { 
        tip = 'NC'
         break; 
      }  
      default: {
        tip = 'N/A'
         break; 
      }  
   }
   return tip; 
  }

  GenerarDTE(ctmadoc : CtMaDoc){
    let tipoDocumento = ctmadoc.tipdoca+ctmadoc.cladoca
    console.log(ctmadoc)
    let tip : String;
    this.isLoadingResults = true;
    this.isRateLimitReached = true;

    switch(tipoDocumento) { 
      case '30': {
       
        break; 
      } 
      default: { 
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
         break; 
      }  
    }
   
  }
x 

  descargarPDF(ctmadoc : CtMaDoc){
    let responseCCF_DTE : ResponseCCfDTE;
    ///this.responseCCFDTEService.buscarcoddocu(ctmadoc.coddocu).subscribe(obj => {
      console.log(ctmadoc.url_pdf_notacredito)
      const a = document.createElement('a');
      a.setAttribute('style', 'display:none');
      document.body.appendChild(a);
      a.href = ctmadoc.url_pdf_notacredito;
      window.open(a.href,'_blank');
   // })
    
  }

  descargarJson(ctmadoc : CtMaDoc){
    let responseCCF_DTE : ResponseCCfDTE;
    //this.responseCCFDTEService.buscarcoddocu(ctmadoc.coddocu).subscribe(obj => {
      const a = document.createElement('a');
      a.setAttribute('style', 'display:none');
      document.body.appendChild(a);
      a.href = ctmadoc.url_json_notacredito;
      window.open(a.href,'_blank');
    //})
    
  }

   getestadofac(ctmadoc : CtMaDoc){
    switch (ctmadoc.facesta) {
      case 0:
        //0 = A no generado
        return true
        break;
      case 1:
        //1 = A no generado
        return false
        break;
      default:
        return false
        break;
    }
  }

  buscarFecha(){
    const dialogRef = this.dialog.open(DialogfacturasComponent, {
      width: '450px',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result.data!=null){
        this.range = result.data;
        this.fechaIni = this.range.value.start;
        this.fechaFin = this.range.value.end;
        this.fechaIni.setDate(this.fechaIni.getDate() - 1);
        this.fechaFin.setDate(this.fechaFin.getDate() + 1);
        this.isLoadingResults = true;
        this.isRateLimitReached = true;
        this.encabeadoFacturasService.listarRangoFechaNC(this.datePipe.transform(this.fechaIni,"yyyy-MM-dd"),this.datePipe.transform(this.fechaFin,"yyyy-MM-dd")).subscribe(data => {
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          // Configurar la dirección de ordenación inicial
          const sortState: Sort = {active: 'fecdocu', direction: 'desc'};
          this.sort.active = sortState.active;
          this.sort.direction = sortState.direction;
          this.sort.sortChange.emit(sortState); 

          this.isLoadingResults = false;
          this.isRateLimitReached = false;
        })
    }})
  }

  esInvalidado(ctmadoc : CtMaDoc){
    if(ctmadoc.notacredito == '1'){
      return false;
    }else{
      return true;
    }
  }

  generarNotaCredito(ctmadoc : CtMaDoc){
    this.isLoadingResults = true;
    this.isRateLimitReached = true;
    this.notacreditodteService.generarnotacreditoDTE(ctmadoc.coddocu).subscribe({
      next: (data: Blob) => {
          const url = window.URL.createObjectURL(data);
          const a = document.createElement('a');
          a.setAttribute('style', 'display:none');
          document.body.appendChild(a);
          a.href = url;
          window.open(a.href, '_blank');
  
          this.encabeadoFacturasService.listarRangoFechaNC(
              this.datePipe.transform(this.fechaIni, "yyyy-MM-dd"),
              this.datePipe.transform(this.fechaFin, "yyyy-MM-dd")
          ).subscribe(ctmadocs => {
              this.encabeadoFacturasService.ctmadocCambio.next(ctmadocs);
              this.isLoadingResults = false;
              this.isRateLimitReached = false;
          });
      },
      error: (err: any) => {
          if (err instanceof Blob) {
              const reader = new FileReader();
              reader.onload = () => {
                  const text = reader.result as string;
                  try {
                      const jsonError = JSON.parse(text);
                      console.log("Error JSON:", jsonError);
                      alert(`Error: ${jsonError.message || 'Se produjo un error al procesar la solicitud'}`);
                  } catch (e) {
                      console.error("No se pudo analizar el error del JSON:", e);
                      alert(`Se produjo un error: ${text}`);
                  }
              };
              this.encabeadoFacturasService.listarRangoFechaNC(this.datePipe.transform(this.fechaIni,"yyyy-MM-dd"),this.datePipe.transform(this.fechaFin,"yyyy-MM-dd")).subscribe(ctmadocs =>{
                this.encabeadoFacturasService.ctmadocCambio.next(ctmadocs);
                this.isLoadingResults = false;
                this.isRateLimitReached = false;
              })
              reader.readAsText(err);
          } else {
            this.encabeadoFacturasService.listarRangoFechaNC(this.datePipe.transform(this.fechaIni,"yyyy-MM-dd"),this.datePipe.transform(this.fechaFin,"yyyy-MM-dd")).subscribe(ctmadocs =>{
              this.encabeadoFacturasService.ctmadocCambio.next(ctmadocs);
              this.isLoadingResults = false;
              this.isRateLimitReached = false;
            })
            console.error("Error:", err);
            alert(`Se produjo un error: ${err}`);
          }
      }
  });
  }

  InvalidarDTE(ctmadoc : CtMaDoc){
    let tipoDocumento = ctmadoc.tipdoca+ctmadoc.cladoca
    let tip : String;
    this.isLoadingResults = true;
    this.isRateLimitReached = true;

    const dialogRef = this.dialog.open(DialoginvalidarDTEComponent, {
      width: '650px',
      disableClose: true
    })
    
    dialogRef.afterClosed().subscribe(result => {
      if(result.data!=null){
        console.log(result.data)
        
            switch(result.data[2]){
              case '1':{
                this.facturadteService.invalidarnccop1(ctmadoc.coddocu,result.data,result.data[1],'1').subscribe(data => {
                  this.encabeadoFacturasService.listarRangoFechaNC(this.datePipe.transform(this.fechaIni,"yyyy-MM-dd"),this.datePipe.transform(this.fechaFin,"yyyy-MM-dd")).subscribe(ctmadocs =>{
                    this.encabeadoFacturasService.ctmadocCambio.next(ctmadocs);
                    this.isLoadingResults = false;
                    this.isRateLimitReached = false;
                  })
                })
                break
              }
              case '2':{
                this.facturadteService.invalidarnccop2(ctmadoc.coddocu,result.data[1],'2').subscribe(data => {
                  this.encabeadoFacturasService.listarRangoFechaNC(this.datePipe.transform(this.fechaIni,"yyyy-MM-dd"),this.datePipe.transform(this.fechaFin,"yyyy-MM-dd")).subscribe(ctmadocs =>{
                    this.encabeadoFacturasService.ctmadocCambio.next(ctmadocs);
                    this.isLoadingResults = false;
                    this.isRateLimitReached = false;
                  })
                })
                break
              }
              case '3':{
                this.facturadteService.invalidarnccop3(ctmadoc.coddocu,result.data,result.data[1],'3').subscribe(data => {
                  this.encabeadoFacturasService.listarRangoFechaNC(this.datePipe.transform(this.fechaIni,"yyyy-MM-dd"),this.datePipe.transform(this.fechaFin,"yyyy-MM-dd")).subscribe(ctmadocs =>{
                    this.encabeadoFacturasService.ctmadocCambio.next(ctmadocs);
                    this.isLoadingResults = false;
                    this.isRateLimitReached = false;
                  })
                })
                break
              }
            } 
          }
        }    
    )}
}