import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { factura } from 'src/app/_model/dte/factura';
import { Receptor } from 'src/app/_model/dte/receptor';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FacturadteService {

  famaordCambio = new Subject<factura[]>();
  mensajeCambio = new Subject<string>();

  url: string = `${environment.HOST}/factura_dte`;
  
  constructor(private http: HttpClient) { }

  generarfacturadte(idfamaord_enc : number){
    console.log('generarfacturadte')
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    return this.http.get(`${this.url}/generarfacturadte/${idfamaord_enc}`, {
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    });
  }

  generarccfdte(idfamaord_enc : number){
    console.log('generarccfdte')
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    return this.http.get(`${this.url}/generarccfdte/${idfamaord_enc}`, {
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
        
      })
    });
  }

  generarfacturadteVtas(coddocu : string){
    console.log('generarfacturadteVtas');
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    return this.http.get(`${this.url}/generarfacturadtevtas/${coddocu}`, {
        responseType: 'blob' as 'json',
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    }).pipe(
        catchError((error: HttpErrorResponse) => {
            if (error.status === 400 && error.error instanceof Blob) {
                // Assuming the error response is a JSON string in a Blob
                return new Observable(observer => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        const text = reader.result as string;
                        //const jsonResponse = JSON.parse(text);
                        observer.error(text);  // Emit the JSON error response
                    };
                    reader.onerror = () => {
                        observer.error('Failed to read error response');
                    };
                    reader.readAsText(error.error);
                });
            } else {
                return throwError(error);
            }
        })
    );
  }

  verificarccfdteVtas(coddocu : string){
    
    console.log('generarccfdteVtas')
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    return this.http.get(`${this.url}/verificarccfdteVtas/${coddocu}`, {
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
        
      })
    });
  }

  generarccfdteVtas(coddocu : string){
    console.log('generarccfdtevtas');
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    return this.http.get(`${this.url}/generarccfdtevtas/${coddocu}`, {
        responseType: 'blob' as 'json',
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    }).pipe(
        catchError((error: HttpErrorResponse) => {
            if (error.status === 400 && error.error instanceof Blob) {
                // Assuming the error response is a JSON string in a Blob
                return new Observable(observer => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        const text = reader.result as string;
                        console.log(text);
                        //const jsonResponse = JSON.parse(text);
                        observer.error(text);  // Emit the JSON error response
                    };
                    reader.onerror = () => {
                        observer.error('Failed to read error response');
                    };
                    reader.readAsText(error.error);
                });
            } else {
                return throwError(error);
            }
        })
    );
    /*
    console.log('generarccfdteVtas')
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    return this.http.get(`${this.url}/generarccfdtevtas/${coddocu}`, {
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
        
      })
    });*/
  }

  generapdfccf_reposicion(coddocu : string){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    return this.http.get(`${this.url}/generapdfccf_reposicion/${coddocu}`, {
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
        
      })
    });
  }

  generapdfcf_reposicion(coddocu : string){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    return this.http.get(`${this.url}/generapdfcf_reposicion/${coddocu}`, {
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
        
      })
    });
  }

  invalidarccfop1(coddocu : string, codigogeneracionr : string, motivo : string, tipoanulacion : string){
    return this.http.get(`${environment.HOST}/invalidar_dte/invalidar_ccf/op1/${coddocu}/${codigogeneracionr}/${motivo}/${tipoanulacion}`,this.getToken());
  }

  invalidarccfop2(coddocu : string, motivo : string, tipoanulacion : string){
    let codigogeneracionr : string = null;
    return this.http.get(`${environment.HOST}/invalidar_dte/invalidar_ccf/op1/${coddocu}/${codigogeneracionr}/${motivo}/${tipoanulacion}`,this.getToken());
  }

  invalidarccfop3(coddocu : string, codigogeneracionr : string, motivo : string, tipoanulacion : string){
    return this.http.get(`${environment.HOST}/invalidar_dte/invalidar_ccf/op1/${coddocu}/${codigogeneracionr}/${motivo}/${tipoanulacion}`,this.getToken());
  }

  invalidarfacop1(coddocu : string, codigogeneracionr : string, motivo : string, tipoanulacion : string){
    return this.http.get(`${environment.HOST}/invalidar_dte/invalidar_fac/op1/${coddocu}/${codigogeneracionr}/${motivo}/${tipoanulacion}`,this.getToken());
  }

  invalidarfacop2(coddocu : string, motivo : string, tipoanulacion : string){
    let codigogeneracionr : string = null;
    return this.http.get(`${environment.HOST}/invalidar_dte/invalidar_fac/op1/${coddocu}/${codigogeneracionr}/${motivo}/${tipoanulacion}`,this.getToken());
  }

  invalidarfacop3(coddocu : string, codigogeneracionr : string, motivo : string, tipoanulacion : string){
    return this.http.get(`${environment.HOST}/invalidar_dte/invalidar_fac/op1/${coddocu}/${codigogeneracionr}/${motivo}/${tipoanulacion}`,this.getToken());
  }

  invalidarnccop1(coddocu : string, codigogeneracionr : string, motivo : string, tipoanulacion : string){
    return this.http.get(`${environment.HOST}/invalidar_dte/invalidar_nc/op1/${coddocu}/${codigogeneracionr}/${motivo}/${tipoanulacion}`,this.getToken());
  }

  invalidarnccop2(coddocu : string, motivo : string, tipoanulacion : string){
    let codigogeneracionr : string = null;
    return this.http.get(`${environment.HOST}/invalidar_dte/invalidar_nc/op1/${coddocu}/${codigogeneracionr}/${motivo}/${tipoanulacion}`,this.getToken());
  }

  invalidarnccop3(coddocu : string, codigogeneracionr : string, motivo : string, tipoanulacion : string){
    return this.http.get(`${environment.HOST}/invalidar_dte/invalidar_nc/op1/${coddocu}/${codigogeneracionr}/${motivo}/${tipoanulacion}`,this.getToken());
  }

  contingencia(coddocu : string){
    return this.http.get(`${environment.HOST}/contingencia_dte/contingencia/${coddocu}`,this.getToken());
  }

  verificardatosccf(coddocu : string){
    return this.http.get<string>(`${this.url}/verificardatosccf/${coddocu}`,this.getToken());
  }

  verificardatoscf(coddocu : string){
    return this.http.get<string>(`${this.url}/verificardatoscf/${coddocu}`,this.getToken());
  }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }
}
