import { SelectionModel } from '@angular/cdk/collections';
import { InborutService } from './../../../_service/inventario/inborut.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Inbogrl } from 'src/app/_model/inbogrl';
import { Inborut } from 'src/app/_model/invetario/inborut';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogimportarinvendbfComponent } from '../dialogimportarinvendbf/dialogimportarinvendbf.component';
import { DialogimportarinvenrutadbfComponent } from '../dialogimportarinvenrutadbf/dialogimportarinvenrutadbf.component';

@Component({
  selector: 'app-importarinventarioruta',
  templateUrl: './importarinventarioruta.component.html',
  styleUrls: ['./importarinventarioruta.component.css']
})
export class ImportarinventariorutaComponent implements OnInit {

  selectedFile : FileList;
  currentfileUpload : File;

  isLoadingResults = false;
  isRateLimitReached = false;
  msj_spinner : string = "Cargando";

  activeTable : boolean = false;
  productos : Inborut[];

  isActualizarInvetario : boolean = false;

  displayedColumns = ['select','codRutaRt', 'codProdRt','preUnitRt','existencia','estadoRt'];
  dataSource: MatTableDataSource<Inborut>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  selection = new SelectionModel<Inborut>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Inborut): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.codRutaRt + 1}`;
  }

  constructor(private inborutService : InborutService,
    private snackBar: MatSnackBar, private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.inborutService.mensajeCambio.subscribe(data => {
      this.snackBar.open(data, 'AVISO',{
        duration:2000
      });
    });

    this.inborutService.InbogrlCambio.subscribe(data=>{
      if(data != null){
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.activeTable = true;
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
      }
    })
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
   
  }

  importarDBF(){
    this.isLoadingResults = true;
    this.isRateLimitReached = true;
    this.inborutService.importarDBF(1).subscribe(data =>{
      this.inborutService.InbogrlCambio.next(data);
      this.activeTable = true;
      this.productos = data;
      this.isLoadingResults = false;
      this.isRateLimitReached = false;
      this.isActualizarInvetario = true;
    })
  }

  guardarDBF(){
    const dialogRef = this.dialog.open(DialogimportarinvenrutadbfComponent, {
      width: '650px',
      height: '450px',
      data: this.selection.selected
    });

    dialogRef.afterClosed().subscribe(result => {
      this.isLoadingResults = true;
      this.isRateLimitReached = true;
      if(result != null){
        this.inborutService.GuardarDBF(result.data).subscribe(data => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.dataSource.data = [];
          
          this.activeTable = false;
          this.snackBar.open(data.length +" Productos Actualizados", 'AVISO',{
            duration:2000
          });
        });
      }else{
        // TODO document why this block is empty
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
      }
    })
  }

  getExistencia(row : Inborut){
    //uexiini_rt+uingmes_rt-uegrmes_rt+uingtem_rt-uegrtem_rt
    return row.uExiIniRt+row.uIngMesRt-row.uIngMesRt+row.uIngTemRt-row.uEgrTemRt
  }
}
