import { CcmavenService } from 'src/app/_service/ccmaven.service';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-importarvendedor',
  templateUrl: './importarvendedor.component.html',
  styleUrls: ['./importarvendedor.component.css']
})
export class ImportarvendedorComponent implements OnInit {

  selectedFile : FileList;
  currentfileUpload : File;

  isLoadingResults = false;
  isRateLimitReached = false;

  constructor( private ccmavenService : CcmavenService, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.ccmavenService.mensajeCambio.subscribe(data => {
      this.snackBar.open(data, 'AVISO',{
        duration:2000
      });
    });
  }

  subirArchivo(e : any){
    console.log(e);

    this.selectedFile = e.target.files;
    this.currentfileUpload = this.selectedFile.item(0);
    this.isLoadingResults = true;
    this.isRateLimitReached = true
    this.ccmavenService.ImportarCSV(this.currentfileUpload).subscribe(data => {
      console.log(data);
      this.isLoadingResults = false;
      this.isRateLimitReached = false;
      this.ccmavenService.mensajeCambio.next("Exitoso");
    })
    
  }

}
