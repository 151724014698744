import { Ccmacli } from '../ccmacli';
import { Ccmaven } from '../ccmaven';
import { Ccmasuc } from '../cliente/ccmasuc';

export class Ccmacli_Ccmaven{
    idccmaclie_ccmaven : number;
    ccmacli : Ccmacli;
    ccmaven : Ccmaven;
    estado : number;
    ccmasuc : Ccmasuc;
}