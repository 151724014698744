import { Injectable } from '@angular/core';
import { Departamento } from '../_model/utils/departamento';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class DepartamentoService {

  ccmacliCambio = new Subject<Departamento[]>();
  mensajeCambio = new Subject<string>();

  url: string = `${environment.HOST}/dapartamento`;
  
  constructor(private http: HttpClient) { }

  listar(){
    return this.http.get<Departamento[]>(this.url,this.getToken());
  }

  listarPorId(idDepartamento : number){
    return this.http.get<Departamento>(`${this.url}/${idDepartamento}`,this.getToken());
  }

  registrar(departamento : Departamento){
    return this.http.post(this.url, departamento,this.getToken());
  }

  modificar(departamento : Departamento){
    return this.http.put(this.url, departamento,this.getToken());
  }

  eliminar(idDepartamento : number){
    return this.http.delete(`${this.url}/${idDepartamento}`,this.getToken());
  }

  listaDepartamentos(){
    return this.http.get<Departamento[]>(`${this.url}/listardepartamentos`,this.getToken());
  }

  leerporcodigo(codigo : String){
    return this.http.get<Departamento>(`${this.url}/leerporcodigo/${codigo}`,this.getToken());
  }

  municipos_por_departamento(codigo : String){
    return this.http.get<String[]>(`${this.url}/municipos_por_departamento/${codigo}`,this.getToken());
  }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }
}
