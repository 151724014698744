import { Injectable } from '@angular/core';
import { Inbogrl } from '../_model/inbogrl';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Message } from '../_model/utils/message';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class InbogrlService {

  InbogrlCambio = new Subject<Inbogrl[]>();
  mensajeCambio = new Subject<string>();

  url: string = `${environment.HOST}/productos`;
  
  constructor(private http: HttpClient) { }

  listar(){
    return this.http.get<Inbogrl[]>(this.url,this.getToken());
  }

  listarPorId(idInbogrl : number){
    return this.http.get<Inbogrl>(`${this.url}/${idInbogrl}`,this.getToken());
  }

  buscarPorCodigo(codigo_ln : string){
    return this.http.get<Inbogrl>(`${this.url}/producto/${codigo_ln}`,this.getToken());
  }

  registrar(Inbogrl : Inbogrl){
    return this.http.post(this.url, Inbogrl,this.getToken());
  }

  modificar(Inbogrl : Inbogrl){
    return this.http.put(this.url, Inbogrl,this.getToken());
  }

  eliminar(idInbogrl : number){
    return this.http.delete(`${this.url}/${idInbogrl}`,this.getToken());
  }

  ImportarCSV(data: File) {
    let formdata : FormData = new FormData();
    formdata.append('csvfile',data);

    return this.http.post<Message[]>(`${this.url}/importarcsv`, formdata,this.getToken());
  }

  importarDBF(id : number){
    return this.http.get<Inbogrl[]>(`${this.url}/importardbf/${id}`,this.getToken());
  }

  GuardarDBF(lista : Inbogrl[]){
    //return this.http.get<Ccmacli[]>(`${this.url}/guardardbf/${lista}`);
    
    return this.http.post<Inbogrl[]>(`${this.url}/guardardbf`, lista, this.getToken());
  }

  ProductosConExistencia(){
    return this.http.get<Inbogrl[]>(`${this.url}/productosconexistencia`,this.getToken());
  }

  ListarProductosActivos(){
    return this.http.get<Inbogrl[]>(`${this.url}/ListarActivos`,this.getToken());
  }

  ProductoBodega(codrutart : number){
    return this.http.get<Inbogrl[]>(`${this.url}/productos_vendedor/${codrutart}`);
  }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }
}
