<mat-card-header class="centerContent">
    <mat-card-title>Productos</mat-card-title>
</mat-card-header>
    <form #myform="ngForm" class="example-form">
        

            <div class="example-header">
              <mat-form-field>
                <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar Productos">
              </mat-form-field>
            </div>
          
            <mat-table [dataSource]="dataSource" matSort>
          
              <ng-container matColumnDef="codigo_ln">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Codigo Producto</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.codigo_ln}} </mat-cell>
              </ng-container>
          
              <ng-container matColumnDef="descrip_ln" >
                <mat-header-cell *matHeaderCellDef mat-sort-header>Producto</mat-header-cell>
                <mat-cell  *matCellDef="let row"> {{row.descrip_ln}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="acciones">
                <mat-header-cell *matHeaderCellDef > Acciones </mat-header-cell>
                <mat-cell *matCellDef="let row"> 
                    <button mat-raised-button color="primary" (click)="closeDialog(row)">Seleccionar</button>
                </mat-cell>
              </ng-container>
          
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;">
              </mat-row>
            </mat-table>
            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
          
    </form>