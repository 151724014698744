<div id="print-section-pedido" class="example-container">
    <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="codprod">
            <mat-header-cell *matHeaderCellDef>Codigo Producto</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.codprod}} </mat-cell>
          </ng-container>
      
          <ng-container matColumnDef="producto">
              <mat-header-cell *matHeaderCellDef>Producto</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{getProductoNombre(row.codprod)}} </mat-cell>
            </ng-container>
      
          <ng-container matColumnDef="totunid" >
            <mat-header-cell *matHeaderCellDef>Cantidad</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.totunid}} </mat-cell>
          </ng-container>
      
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;">
          </mat-row>
    </table>
</div>
<button mat-button (click)="print()">Imprimir</button>