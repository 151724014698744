import { DialogimportardbfComponent } from './../../dialogimportardbf/dialogimportardbf.component';
import { Ccmacli } from './../../../../_model/ccmacli';
import { CcmacliService } from 'src/app/_service/ccmacli.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-importardbf',
  templateUrl: './importardbf.component.html',
  styleUrls: ['./importardbf.component.css']
})
export class ImportardbfComponent implements OnInit {

  isLoadingResults = false;
  isRateLimitReached = false;
  activeTable = false;
  msj_spinner : string = "Cargando";
  lista : Ccmacli[];
  isActualizarCliente : boolean = false;
  clientesConError : number = 0;

  displayedColumns = ['select','rutclie','codclie', 'nomclie','nrcclie','nitclie','mail_dte','celwapp'];
  dataSource: MatTableDataSource<Ccmacli>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  selection = new SelectionModel<Ccmacli>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Ccmacli): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.codclie + 1}`;
  }

  constructor(private ccmacliService : CcmacliService, private snackBar: MatSnackBar, private dialog: MatDialog) { 
    //this.importarDBF();
  }

  ngOnInit(): void {
    this.ccmacliService.mensajeCambio.subscribe(data => {
      this.snackBar.open(data, 'AVISO',{
        duration:2000
      });
    });

    this.ccmacliService.ccmacliCambio.subscribe(data=>{
      if(data != null){
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.activeTable = true;
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
      }
    })
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
   
  }

  importarDBF(){
    this.isLoadingResults = true;
    this.isRateLimitReached = true;
    this.ccmacliService.importarDBF(1).subscribe(data => {
      this.ccmacliService.ccmacliCambio.next(data);
      data.forEach(element => {
        if(element.nrcclie === null || element.nitclie === '' || element.mail_dte === '' || element.celwapp === ''){
          this.clientesConError++;
        }
      });
      this.isLoadingResults = false;
      this.isRateLimitReached = false;
      this.isActualizarCliente = true;
    });
  }

  guardarDBF(){
    const dialogRef = this.dialog.open(DialogimportardbfComponent, {
      width: '650px',
      height: '450px',
      data: this.selection.selected
    });

    dialogRef.afterClosed().subscribe(result => {
      this.isLoadingResults = true;
      this.isRateLimitReached = true;
      if(result != null){
        this.ccmacliService.GuardarDBF(result.data).subscribe(data => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.dataSource.data = [];
          
          this.activeTable = false;
          this.snackBar.open(data.length +" Clientes Actualizados", 'AVISO',{
            duration:2000
          });
        });
      }else{
        // TODO document why this block is empty
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
      }
    })
  }

  getRowClass(row: Ccmacli): string {
    return row.nrcclie === null || row.nitclie === '' || row.mail_dte === '' || row.celwapp === ''? 'special-row' : '';
  }
}
