<div>
    <div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
        <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
            {{msj_spinner}}
        </div>
    </div>
    
    <mat-card class="example-card center">
        <mat-card-header class="centerContent">
            <mat-card-title>Producto</mat-card-title>
        </mat-card-header>

        <form #myform="ngForm" class="example-form">
            <mat-tab-group dynamicHeight>
                <mat-tab label="Productos">
                    <mat-grid-list cols="5" rowHeight="120px">

                        <mat-grid-tile colspan="1" rowspan="1">
                            <button mat-raised-button color="primary" (click)="buscarProductos()">Producto</button>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field>
                                <label></label>
                                <input matInput type="text" [(ngModel)]="inbogrl.codigo_ln" name="inbogrl.codigo_ln" placeholder="Codigo de Producto">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="3" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <label></label>
                                <input matInput type="text" [(ngModel)]="inbogrl.descrip_ln" name="inbogrl.descrip_ln" placeholder="Descripcion">
                            </mat-form-field>
                        </mat-grid-tile>

                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <input matInput type="text" [(ngModel)]="inbogrl.pesoxun_ln" name="inbogrl.pesoxun_ln" placeholder="Precio x Unidad">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <mat-label>Unidad de Peso</mat-label>
                                <mat-select >
                                    <mat-option>
                                        Milligramo
                                    </mat-option>
                                    <mat-option>
                                        Gramo
                                    </mat-option>
                                    <mat-option>
                                        KiloGramo
                                    </mat-option>
                                    <mat-option>
                                        Tonelada
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <mat-label>Linea</mat-label>
                                <mat-select [(ngModel)]="inbogrl.codline_ln" name="inbogrl.codline_ln">
                                    <mat-option>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <input matInput type="text" [(ngModel)]="inbogrl.present_ln" name="inbogrl.present_ln" placeholder="Presentacion">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <mat-label>Familia</mat-label>
                                <mat-select>
                                    <mat-label>MHs</mat-label>
                                    <mat-option>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-grid-tile>

                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <input matInput type="text" [(ngModel)]="inbogrl.unixcaj_ln" name="inbogrl.unixcaj_ln" placeholder="Paquete x Caja">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <input matInput type="text" [(ngModel)]="inbogrl.idmarca" name="inbogrl.idmarca" placeholder="Marca">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <input matInput type="text" [(ngModel)]="inbogrl.unixdis_ln" name="inbogrl.unixdis_ln" placeholder="Unidad x Paquete">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <input matInput type="text" [(ngModel)]="inbogrl.impuest_ln" name="inbogrl.impuest_ln" placeholder="IVA">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <!--espacio libres-->
                        </mat-grid-tile>

                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <input matInput type="text" [(ngModel)]="inbogrl.impuest_ln" name="inbogrl.impuest_ln" placeholder="Consolida">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <mat-label>Categoria</mat-label>
                                <mat-select>
                                    <mat-option>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <input matInput type="text" placeholder="Ubicacion">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <mat-label>Estado</mat-label>
                                <mat-select [(ngModel)]="inbogrl.estado_ln" name="inbogrl.estado_ln" >
                                    <mat-option>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <!--espacio libres-->
                        </mat-grid-tile>

                        <mat-grid-tile colspan="2" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <input matInput type="text" [(ngModel)]="inbogrl.cbarrde_ln" name="inbogrl.cbarrde_ln" placeholder="Codigo de Barra">
                            </mat-form-field>
                        </mat-grid-tile>
                        
                        <mat-grid-tile colspan="1" rowspan="1">
                            <section class="example-section">
                                <mat-checkbox class="example-margin">Bloquear</mat-checkbox>
                                <mat-checkbox class="example-margin" [checked] = "checked_Bloquear">Controlar Saldo</mat-checkbox>
                            </section>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="2" rowspan="1">
                            
                        </mat-grid-tile>
                    </mat-grid-list>
                </mat-tab>
                <mat-tab label="Precio Unitario de Ventas">
                    <mat-grid-list cols="5" rowHeight="120px">

                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <input matInput type="text" [(ngModel)]="inbogrl.preunid_ln" name="inbogrl.preunid_ln" placeholder="Unitario">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <input matInput type="text" [(ngModel)]="inbogrl.preuni1_ln" name="inbogrl.preuni1_ln" placeholder="Lista 1">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <input matInput type="text" [(ngModel)]="inbogrl.preuni2_ln" name="inbogrl.preuni2_ln" placeholder="Lista 2">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <input matInput type="text" [(ngModel)]="inbogrl.preuni3_ln" name="inbogrl.preuni3_ln" placeholder="Lista 3">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <input matInput type="text" [(ngModel)]="inbogrl.preuni4_ln" name="inbogrl.preuni4_ln" placeholder="Lista 4">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <input matInput type="number" [(ngModel)]="inbogrl.desc_max" name="inbogrl.desc_max" placeholder="Precio Maximo de descuento">
                            </mat-form-field>
                        </mat-grid-tile>
                    </mat-grid-list>
                </mat-tab>
                <mat-tab label="Existencia">
                    <mat-grid-list cols="5" rowHeight="120px">

                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <input matInput type="text" [(ngModel)]="inbogrl.eximini_ln" name="inbogrl.eximini_ln" placeholder="Minima">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <input matInput type="text" [(ngModel)]="inbogrl.eximaxi_ln" name="inbogrl.eximaxi_ln" placeholder="Maxima">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <input matInput type="text" [(ngModel)]="inbogrl.exiaver_ln" name="inbogrl.exiaver_ln" placeholder="Averias">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <input matInput type="text" [(ngModel)]="inbogrl.exiaver_ln" name="inbogrl.exiaver_ln" placeholder="Conteo">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field>
                                <mat-label>Fecha de Corte</mat-label>
                                <input matInput [matDatepicker]="Fcorte">
                                <mat-datepicker-toggle matSuffix [for]="Fcorte"></mat-datepicker-toggle>
                                <mat-datepicker #Fcorte></mat-datepicker>
                            </mat-form-field>
                        </mat-grid-tile>

                    </mat-grid-list>
                </mat-tab>
                <mat-tab label="Movimientos">
                    <mat-grid-list cols="5" rowHeight="120px">

                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <input matInput type="text" placeholder="Saldo Inicial">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <input matInput type="text" placeholder="Entradas">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <input matInput type="text" placeholder="Entradas 2">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <input matInput type="text" placeholder="Salidas">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <input matInput type="text" placeholder="Salidas 2">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <input matInput type="text" placeholder="Saldo Actual">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <input matInput type="text" placeholder="Saldo Actual 2">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field>
                                <mat-label>Ultimo</mat-label>
                                <input matInput [matDatepicker]="ULT">
                                <mat-datepicker-toggle matSuffix [for]="ULT"></mat-datepicker-toggle>
                                <mat-datepicker #ULT></mat-datepicker>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <input matInput type="text" placeholder="Ultimo 2">
                            </mat-form-field>
                        </mat-grid-tile>

                    </mat-grid-list>
                </mat-tab>
                <mat-tab label="Cuentas Contables">
                    <mat-grid-list cols="4" rowHeight="120px">

                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <input matInput type="text" placeholder="Inventario">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <input matInput type="text" placeholder="Proceso">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <input matInput type="text" placeholder="Costo">
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <input matInput type="text" placeholder="Ventas">
                            </mat-form-field>
                        </mat-grid-tile>

                    </mat-grid-list>
                </mat-tab>
                <mat-tab label="Multimedia">
                    <mat-grid-list cols="2" rowHeight="120px">
                        <mat-grid-tile>
                            <img src="{{inbogrl.imgurl_ln}}"  width="100px"  height="100px">
                        </mat-grid-tile>
                        <mat-grid-tile>
                            <button type="button" mat-raised-button  color="primary" onclick="document.getElementById('flArchivo').click()">Subir Imagen</button>
                            <input id="flArchivo" type="file" style="display: none;" (change)="subirArchivo($event)">
                        </mat-grid-tile>
                        <mat-grid-tile>
                            <mat-form-field class="example-full-width">
                                <input matInput type="text" [(ngModel)]="inbogrl.imgurl_ln" name="inbogrl.imgurl_ln" placeholder="Url de Imagen">
                            </mat-form-field>
                        </mat-grid-tile>
                    </mat-grid-list>
                </mat-tab>

            </mat-tab-group>

            <mat-card-actions align="center">
                <button type="submit" mat-raised-button [disabled]="myform.invalid" color="primary" (click)="guardar(inbogrl)">Guardar</button>
                <!--<button type="submit" mat-raised-button [disabled]="myform.invalid" color="warn">Eliminar</button>-->
            </mat-card-actions>

        </form>

    </mat-card>
</div>