import { WhatsAppConfig } from './../../_model/utils/whatsappconfig';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class WhastappconfigService {

  url: string = `${environment.HOST}/WhatsAppConfig`;
  
  constructor(private http: HttpClient) { }

  enviarWS(wsc_id : number){
    return this.http.get<WhatsAppConfig>(`${this.url}/whastapp/${wsc_id}`,this.getToken());
  }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }
}
