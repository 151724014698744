import { Ccmacob } from './../../../_model/CCTES/ccmacob';
import { Ccmacli } from './../../../_model/ccmacli';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationExtras, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { switchMap } from 'rxjs/operators';
import { Ccmaven } from 'src/app/_model/ccmaven';
import { Ord_Client } from 'src/app/_model/ord_client';
import { CcmavenService } from 'src/app/_service/ccmaven.service';
import { FamaordService } from 'src/app/_service/famaord.service';
import { OrdClienteService } from 'src/app/_service/ord-cliente.service';
import { UsuarioService } from 'src/app/_service/seguridad/usuario.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-autorizacionpedido',
  templateUrl: './autorizacionpedido.component.html',
  styleUrls: ['./autorizacionpedido.component.css']
})
export class AutorizacionpedidoComponent implements OnInit {

  isLoadingResults = true;
  isRateLimitReached = true;

  ccmaven : Ccmaven;

  displayedColumns = ['numpedi', 'vendedor','nomclie','fecha','limcred','sobregiro','acciones'];
  dataSource: MatTableDataSource<Ord_Client>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private ordClienteService : OrdClienteService,private ccmavenService : CcmavenService, private usuarioService : UsuarioService
    ,private famaordService : FamaordService,private router: Router) { 
      this.getDatosIniciales();
    }

  ngOnInit(): void {
    this.ordClienteService.Ord_ClientCambio.subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.filterPredicate = function(data, filter: string): boolean {
        return data.ccmacli.nomclie.toLowerCase().includes(filter) || data.famaord_enc.ccmaven.nomruta.toLowerCase().includes(filter);
      };
    })

  }

  getDatosIniciales(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    console.log(decodedToken.authorities[0]);
    if(decodedToken.authorities[0] === 'Vendedor Mayorista' || decodedToken.authorities[0] === 'Vendedor Rutero'){
      this.usuarioService.buscarPorNombre(decodedToken.user_name).pipe(switchMap(data => {
        return this.ccmavenService.listarPorId(data.usr_idUsuario);
      })).pipe(switchMap(data => {
        this.ccmaven = data;
        return this.ordClienteService.buscarPedidosVendedor(this.ccmaven.idCcmaven);
      })).subscribe( data => {
        //console.log(data);
        this.ordClienteService.Ord_ClientCambio.next(data);

        this.isLoadingResults = false;
        this.isRateLimitReached = false;
      })
    }else if(decodedToken.authorities[0] === 'ADMIN' || decodedToken.authorities[0] === 'Gerente Vtas' || decodedToken.authorities[0] === 'Supervisor Vtas'){
      this.ordClienteService.buscarTodosPedidos().subscribe( data => {
        //console.log(data);
        this.ordClienteService.Ord_ClientCambio.next(data);

        this.isLoadingResults = false;
        this.isRateLimitReached = false;
      })
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  CalcularSaldo(ccmacli? : Ccmacli){
    return ccmacli.vsalini + ccmacli.vcarmes - ccmacli.vabomes;
  }

  

  modificarPedido(row : Ord_Client){
    let navigationExtras: NavigationExtras = {
      state: {
        orden: row
        //cl: ord_Client.ccmacli,
        //ac: "actualizar"
      }
    };
    this.router.navigate(['historialpagos'],navigationExtras)
  }
}
