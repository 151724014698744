import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { JsonFileMH } from 'src/app/_model/jsonfilemh';
import { Message } from 'src/app/_model/utils/message';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubirjsonschemamhService {

  url: string = `${environment.HOST}/jsonfilemh`;

  constructor(private http: HttpClient) { }

  subirJson(data: File) {
    let formdata : FormData = new FormData();
    formdata.append('jsonfile',data);

    return this.http.post<Message[]>(`${this.url}/subir_json_schema`, formdata,this.getToken());
  }

  actulizarjson(data: File, jsonFile : JsonFileMH) {
    let formdata : FormData = new FormData();
    formdata.append('jsonfile',data);
    formdata.append('idjsonfilemh',jsonFile.id.toString());
    console.log(jsonFile);
    return this.http.post<Message[]>(`${this.url}/modificar_json_schema`, formdata,this.getToken());
  }

  listar(){
    return this.http.get<JsonFileMH[]>(this.url,this.getToken());
  }

  listarPorId(id : number){
    return this.http.get<JsonFileMH>(`${this.url}/${id}`,this.getToken());
  }

  registrar(jsonFileMH : JsonFileMH){
    return this.http.post(this.url, jsonFileMH,this.getToken());
  }

  modificar(jsonFileMH : JsonFileMH){
    return this.http.put(this.url, jsonFileMH,this.getToken());
  }

  

  eliminar(id : number){
    return this.http.delete(`${this.url}/${id}`,this.getToken());
  }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }
}
