<div class="example-loading-shade"
       *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
        Procesando
    </div>
</div>

<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab label="Usuario">
        <mat-grid-list cols="1" rowHeight="100px">
            <mat-grid-tile
                [colspan]="1"
                [rowspan]="1">
                <div class="text-inside-grid">
                <mat-form-field>
                    <input matInput placeholder="Nombre" [(ngModel)]="usuario.nombre">
                </mat-form-field>
                </div>
            </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="2" rowHeight="100px">
            <mat-grid-tile>
                <div class="text-inside-grid">
                <mat-form-field>
                    <input matInput placeholder="Usuario" [(ngModel)]="usuario.username">
                </mat-form-field>
                </div>
            </mat-grid-tile>
            <mat-grid-tile>
                <div class="text-inside-grid">
                <mat-form-field>
                    <input matInput placeholder="Clave" [(ngModel)]="usuario.password">
                </mat-form-field>
                </div>
            </mat-grid-tile>
            <mat-grid-tile>
                <div class="text-inside-grid">
                <mat-form-field>
                    <input matInput placeholder="E-Mail" [(ngModel)]="usuario.email">
                </mat-form-field>
                </div>
            </mat-grid-tile>
            <mat-grid-tile>
                <div class="text-inside-grid">
                <section class="example-section">
                    <mat-slide-toggle
                        class="example-margin"
                        [checked]="true"
                        [disabled]="false"
                        (change)="onChange()"
                        [(ngModel)]="usuario.usr_estado">
                    Estado
                    </mat-slide-toggle>
                </section>
                </div>
            </mat-grid-tile>
            
        </mat-grid-list>
    </mat-tab>
  <mat-tab label="Rol">
    <mat-grid-list cols="2" rowHeight="100px">
        <mat-grid-tile>
            <div class="text-inside-grid">
                <mat-form-field class="example-full-width">
                    <mat-label>Rol</mat-label>
                    <mat-select [(ngModel)]="rol_idRolSelected" >
                        <mat-option *ngFor="let rol of roles" [value]="rol.rol_idRol">
                        {{rol.rol_nomrol}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </mat-grid-tile>
        <mat-grid-tile>
            <button mat-raised-button color="primary" (click)="guardar()">Guardar</button>
            <button mat-raised-button color="accent" (click)="cancelar()">Cerrar</button>
        </mat-grid-tile>
    </mat-grid-list>
  </mat-tab>
  <mat-tab label="Third">Content 3</mat-tab>
</mat-tab-group>