import { Component, OnInit } from '@angular/core';
import { CcmavenService } from 'src/app/_service/ccmaven.service';

@Component({
  selector: 'app-asignarclientes',
  templateUrl: './asignarclientes.component.html',
  styleUrls: ['./asignarclientes.component.css']
})
export class AsignarclientesComponent implements OnInit {
  isLoadingResults = false;
  isRateLimitReached = false;

  constructor(private ccmavenService : CcmavenService) { }

  ngOnInit() {

  }

  procesarClientes(){
    this.isLoadingResults = true;
    this.isRateLimitReached = true;
    this.ccmavenService.procesarcliente().subscribe(()=>{
      this.isLoadingResults = false;
      this.isRateLimitReached = false;
    })
  }

}
