import { CcmasucService } from 'src/app/_service/cliente/ccmasuc.service';
import { DialogErrorComponent } from './../../utils/dialog-error/dialog-error.component';
import { Component, OnInit, ViewChild } from '@angular/core';

import { Ccmacli } from 'src/app/_model/ccmacli';
import { CcmacliService } from 'src/app/_service/ccmacli.service';
import { Router, NavigationExtras } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.css']
})
export class ClientesComponent implements OnInit {

  ccmacliList : Ccmacli [] = [];

  displayedColumns = ['codclie', 'nomclie', 'acciones'];
  dataSource: MatTableDataSource<Ccmacli>

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  selectedFile : FileList;
  currentfileUpload : File;

  isLoadingResults = true;
  isRateLimitReached = true;

  constructor(private ccmacliService : CcmacliService,private router: Router, private dialog: MatDialog,
              private ccmasucService : CcmasucService) { }

  ngOnInit() {
    
    this.ccmacliService.listar().subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.isLoadingResults = false;
      this.isRateLimitReached = false;
    });

  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  selecionarCliente(ccmacli : Ccmacli){
    let navigationExtras: NavigationExtras = {
      state: {
        cliente: ccmacli,
        //cl: ccmacli
      }
    };
    this.router.navigate(['sucursales'],navigationExtras);
  }

  consultaCliente(ccmacli : Ccmacli){
    let navigationExtras: NavigationExtras = {
      state: {
        cliente: ccmacli,
        //cl: ccmacli
      }
    };
    this.router.navigate(['ingresoclientes'],navigationExtras);
  }

  subirArchivo(e : any){
    console.log(e);
    this.selectedFile = e.target.files;
    this.currentfileUpload = this.selectedFile.item(0);
    this.isLoadingResults = true;
    this.isRateLimitReached = true
    this.ccmacliService.ImportarCSV(this.currentfileUpload).subscribe(data => {
      console.log(data);
      this.isLoadingResults = false;
      this.isRateLimitReached = false;
      this.ccmacliService.mensajeCambio.next("Exitoso");
    })
  }

  subirArchivoSucursales(e : any){
    //console.log(e);
    this.isLoadingResults = true;
    this.isRateLimitReached = true;
    this.selectedFile = e.target.files;
    this.currentfileUpload = this.selectedFile.item(0);

    this.ccmasucService.ImportarCSV(this.currentfileUpload).subscribe(data => {
      //console.log(data);
      this.isLoadingResults = false;
      this.isRateLimitReached = false;
      this.ccmacliService.mensajeCambio.next("Exitoso");
    })

  }

  IngresarClientes(){
    this.router.navigate(['ingresoclientes']);
  }
}
