import { S3AwsService } from './../../../_service/utils/s3-aws.service';
import { InbogrlService } from 'src/app/_service/inbogrl.service';
import { Inbogrl } from './../../../_model/inbogrl';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogselectinbogrlComponent } from '../dialogselectinbogrl/dialogselectinbogrl.component';
import { MatDialog } from '@angular/material/dialog';
import { GetosService } from 'src/app/_service/utils/getos.service';

@Component({
  selector: 'app-ingreso-inventario',
  templateUrl: './ingreso-inventario.component.html',
  styleUrls: ['./ingreso-inventario.component.css']
})
export class IngresoInventarioComponent implements OnInit {

  inbogrl : Inbogrl = new Inbogrl();
  selectedFile : FileList;
  currentfileUpload : File;
  isLoadingResults = false;
  isRateLimitReached = false;
  msj_spinner : string = "Cargando";
  checked_Bloquear : boolean;

  constructor(private dialog: MatDialog,
    private inbogrlService : InbogrlService,
    private s3AwsService : S3AwsService,
    private getos : GetosService
    ) {
   }

  ngOnInit() {
    console.log(this.getos)
  }

  buscarProductos(){
    const dialog = this.dialog.open(DialogselectinbogrlComponent, {
      width: '600px',
      
    });

    dialog.afterClosed().subscribe(result => {
      if(result.data != undefined){
        this.inbogrl = result.data;
        if(this.inbogrl.controla === 'S'){
          this.checked_Bloquear = true;
        }else if(this.inbogrl.controla === 'N'){
          this.checked_Bloquear = false;
        }
      }else{

      }
    })
  }

  guardar(p_inbogrl : Inbogrl){
    if(p_inbogrl.idinbogrl == undefined){
      this.inbogrlService.registrar(p_inbogrl).subscribe(data =>{

      })
    }else{
      this.inbogrlService.modificar(p_inbogrl).subscribe(data =>{

      })
    }
  }

  subirArchivo(e : any){
    this.selectedFile = e.target.files;
    this.currentfileUpload = this.selectedFile.item(0);
    this.isLoadingResults = true;
    this.isRateLimitReached = true
    this.s3AwsService.subirImagen(this.currentfileUpload).subscribe(data => {
      console.log(data);
      this.inbogrl.imgurl_ln = data[0].message.toString();
      this.isLoadingResults = false;
      this.isRateLimitReached = false;
      this.inbogrlService.mensajeCambio.next("Exitoso");
    })
  }
}
