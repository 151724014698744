import { SujetoexcluidodteService } from './../../../_service/dte/sujetoexcluidodte.service';
import { SujetoExcluido } from './../../../_model/dte/SujetoExcluido/sujetoexcluido';
import { SelectionModel } from '@angular/cdk/collections';
import { CpmaprvService } from './../../../_service/proveedores/cpmaprv.service';
import { tipo_item } from './../../../_model/tipo_item';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Dialog } from '@angular/cdk/dialog';
import { DialogselecionarprovComponent } from './dialogselecionarprov/dialogselecionarprov.component';
import { Cpmaprv } from 'src/app/_model/cpmaprv';
import { CuerpoDocumento } from 'src/app/_model/dte/SujetoExcluido/cuerpodocumento';
import { DtoSujetoExcluido } from 'src/app/_model/DTO/DTO_SujetoExcluido';
import { Resumen } from 'src/app/_model/dte/SujetoExcluido/resumen';
import { Direccion } from 'src/app/_model/dte/SujetoExcluido/direccion';

@Component({
  selector: 'app-sujetoexcluido',
  templateUrl: './sujetoexcluido.component.html',
  styleUrls: ['./sujetoexcluido.component.css']
})
export class SujetoexcluidoComponent implements OnInit {

  isLoadingResults = false;
  isRateLimitReached = false;

  selectProv : Cpmaprv;
  sujetoExcluido : SujetoExcluido = new SujetoExcluido();

  proveedor_nombre : string;
  selectedItem : number;
  selectedUMedida : number;

  activarFormulario : boolean = false;

  itemCorrelativo : number = 1;

  tipoItem : tipo_item [] = [
    {codigo : 1, valor : 'Bienes'},
    {codigo : 2, valor : 'Servicios'},
    {codigo : 3, valor : 'Ambos'},
  ];

  u_medida : tipo_item [] = [
    {codigo : 59, valor : 'Unidad'},
    {codigo : 99, valor : 'Otro'},
  ]


  detalle : CuerpoDocumento[] = [];
  item_create : CuerpoDocumento = new CuerpoDocumento();

  displayedColumns: string[] = ['No', 'Cantidad', 'Unidad', 'Descripcion', 'PrecioUnitario', 'Descuento', 'OtrosMontosNoAfectos', 'VentasAfectas'];
  displayedColumnsSubtotal: string[] = ['emptyFooter', 'emptyFooter', 'emptyFooter', 'emptyFooter', 'emptyFooter', 'emptyFooter', 'titulo', 'monto'];
  displayedColumnsTotal: string[] = ['emptyFooter', 'emptyFooter', 'emptyFooter', 'emptyFooter', 'emptyFooter', 'emptyFooter', 'tituloTotal', 'montoTotal'];
  displayedColumnsTotalPagar: string[] = ['emptyFooter', 'emptyFooter', 'emptyFooter', 'emptyFooter', 'emptyFooter', 'emptyFooter', 'tituloTotalPagar', 'montoTotalPagar'];
  dataSource: MatTableDataSource<CuerpoDocumento>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  habilitarBtnAgregar = false;
  habilitarBtnActualizar = false;

  resumen : Resumen = new Resumen();
  
  constructor(private cpmaprvService : CpmaprvService,private snackBar: MatSnackBar, private dialog: MatDialog,
    private sujetoexcluidodteService : SujetoexcluidodteService
   ) { }

  ngOnInit(): void {
    this.obtenerProveedores();
    //this.cargarTabla();
  }

  agregarItem(item : CuerpoDocumento){
    item.numItem = this.itemCorrelativo;
    item.compra = item.cantidad * item.precioUni;
    item.tipoItem = this.selectedItem;
    item.uniMedida = this.selectedUMedida
    item.montoDescu = 0;
    console.log(item);
    this.detalle.push(item)
    
    this.cargarTabla();
    this.item_create = new CuerpoDocumento();
    this.itemCorrelativo++;

  }

  actualizarItem(item : CuerpoDocumento){
    item.compra = item.cantidad * item.precioUni;
    this.item_create = new CuerpoDocumento();
  }

  obtenerProveedores(){
    const dialogRef = this.dialog.open(DialogselecionarprovComponent, {
      width: '1000px',
      height: '550px',
      disableClose: true
      /*,
      data: this.selection.selected*/
    });

    dialogRef.afterClosed().subscribe(result => {
      this.isLoadingResults = true;
      this.isRateLimitReached = true;
      if(result != null){
        this.activarFormulario = true;
        this.selectProv = result.data;
        console.log(this.selectProv)
        this.sujetoExcluido.numDocumento = this.selectProv.pvcodig
        this.sujetoExcluido.nombre = this.selectProv.pvnombr;
        this.sujetoExcluido.correo = this.selectProv.pvmail;
        this.sujetoExcluido.telefono = this.selectProv.pvtelef;
        let dirc : Direccion = new Direccion();
          dirc.municipio = this.selectProv.pvmunic+"";
          dirc.departamento = this.selectProv.pvdepto+"";
          dirc.complemento = this.selectProv.pvdirec+"";
        this.sujetoExcluido.direccion = dirc;
      }
      this.isLoadingResults = false;
      this.isRateLimitReached = false;
    })
  }

  onEdit(item_p : CuerpoDocumento){
    this.item_create = item_p;
    this.habilitarBtnActualizar = true;
  }

  onDelete(item_p : CuerpoDocumento){
    let index = this.detalle.findIndex(objeto => objeto.numItem === item_p.numItem);

    if (index !== -1) {
      // Eliminar el objeto si se encuentra
      this.detalle.splice(index, 1);
      this.itemCorrelativo--;
      this.cargarTabla();
    }

  }

  cargarTabla(){
    this.dataSource = new MatTableDataSource(this.detalle);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  // Verificar si todos los campos están completos
  verificarCampos(): void {
    this.habilitarBtnAgregar = 
      this.selectedItem !== null && 
      this.selectedUMedida !== null && 
      this.item_create.cantidad !== null && 
      this.item_create.descripcion.trim() !== '' && 
      this.item_create.precioUni !== null && 
      this.item_create.montoDescu !== null;
  }

  getSumatoria(){
    let sumatoria = 0;
    this.detalle.forEach(element => {
      sumatoria = sumatoria + element.compra
    });
    this.resumen.totalCompra = Number((sumatoria).toFixed(2));
    this.resumen.subTotal = Number((sumatoria).toFixed(2));
    return sumatoria;
  }

  getRetencion(){
    let sumatoria = 0;
    this.detalle.forEach(element => {
      sumatoria = sumatoria + element.compra
    });
    this.resumen.reteRenta = Number((sumatoria*0.1).toFixed(2));
    return sumatoria*0.1;
  }

  getTotalPagar(){
    let sumatoria = 0;
    this.detalle.forEach(element => {
      sumatoria = sumatoria + element.compra
    });
    let retencion = sumatoria*0.1;
    this.resumen.totalPagar = Number((sumatoria - retencion).toFixed(2));
    return sumatoria - retencion;
  }

  facturar(){
    this.isLoadingResults = true;
    this.isRateLimitReached = true;
    let sujetoexcluido_p : DtoSujetoExcluido = new DtoSujetoExcluido();
    sujetoexcluido_p.cuerpodocumento = this.detalle
    sujetoexcluido_p.sujetoexcluido = this.sujetoExcluido

    this.resumen.totalDescu = 0;
    this.resumen.descu = 0;
    this.resumen.ivaRete1 = 0;
    this.resumen.totalLetra = '';
    this.resumen.pagos = null;
    this.resumen.observaciones = null;
    this.resumen.condicionOperacion = 1;

    sujetoexcluido_p.resumen = this.resumen;

    console.log(sujetoexcluido_p);
    this.sujetoexcluidodteService.generarJsonDteFe_Fse(sujetoexcluido_p).subscribe({
      next: (data: Blob) => {
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none');
        document.body.appendChild(a);
        a.href = url;
        window.open(a.href, '_blank');
        this.isLoadingResults = false;
              this.isRateLimitReached = false;
    },
    error: (err: any) => {
        if (err instanceof Blob) {
            const reader = new FileReader();
            reader.onload = () => {
                const text = reader.result as string;
                try {
                    const jsonError = JSON.parse(text);
                    console.log("Error JSON:", jsonError);
                    alert(`Error: ${jsonError.message || 'Se produjo un error al procesar la solicitud'}`);
                } catch (e) {
                    console.error("No se pudo analizar el error del JSON:", e);
                    alert(`Se produjo un error: ${text}`);
                }
            };
            this.isLoadingResults = false;
              this.isRateLimitReached = false;
            reader.readAsText(err);
        } else {
          this.isLoadingResults = false;
              this.isRateLimitReached = false;
          console.error("Error:", err);
          alert(`Se produjo un error: ${err}`);
        }
    }
    })
  }

  getTelefono(){
    if(this.selectProv.pvtelef != undefined && this.selectProv.pvtelef != null){
      return this.selectProv.pvtelng
    }else{
      return this.selectProv.pvtelef
    }
  }
  
}
