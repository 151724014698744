import { UsuarioCcmavenService } from './../../../_service/usuario/vendedor/usuario-ccmaven.service';
import { switchMap } from 'rxjs/operators';
import { OrdClienteService } from './../../../_service/ord-cliente.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Ord_Client } from 'src/app/_model/ord_client';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { CcmavenService } from 'src/app/_service/ccmaven.service';
import { UsuarioService } from 'src/app/_service/seguridad/usuario.service';
import { Ccmaven } from 'src/app/_model/ccmaven';
import { FamaordService } from 'src/app/_service/famaord.service';
import { Router, NavigationExtras } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-consultarpedido',
  templateUrl: './consultarpedido.component.html',
  styleUrls: ['./consultarpedido.component.css']
})
export class ConsultarpedidoComponent implements OnInit {

  isLoadingResults = true;
  isRateLimitReached = true;

  ccmaven : Ccmaven;

  displayedColumns = ['numpedi', 'vendedor','nomclie','fecha','acciones'];
  dataSource: MatTableDataSource<Ord_Client>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private ordClienteService : OrdClienteService,private ccmavenService : CcmavenService, private usuarioService : UsuarioService
    ,private famaordService : FamaordService,private router: Router, private usuarioCcmavenService : UsuarioCcmavenService) { 
    this.getDatosIniciales();
  }

  ngOnInit() {
    this.ordClienteService.Ord_ClientCambio.subscribe(data => {
      //console.log(data);
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.filterPredicate = function(data, filter: string): boolean {
        return data.ccmacli.nomclie.toLowerCase().includes(filter) || data.famaord_enc.ccmaven.nomruta.toLowerCase().includes(filter);
      };
    })
    
  }

  getDatosIniciales(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    //console.log(decodedToken);
    let rol_user = decodedToken.authorities[0];
    if( rol_user === 'Vendedor Mayorista' || rol_user === 'Vendedor Rutero'){
      this.usuarioService.buscarPorNombre(decodedToken.user_name).pipe(switchMap(data => {
        console.log("buscarPorNombre "+data.usr_idUsuario);
        return this.usuarioCcmavenService.findUsuario_CcmavenbyidUsuario(data.usr_idUsuario);
      })).pipe(switchMap(p_vendedor => {
        this.ccmaven = p_vendedor.ccmaven;
        console.log(p_vendedor)
        return this.ordClienteService.buscarPedidosVendedor(this.ccmaven.idCcmaven);
      })).subscribe( p_oredenes => {
        console.log(p_oredenes);
        this.ordClienteService.Ord_ClientCambio.next(p_oredenes);

        this.isLoadingResults = false;
        this.isRateLimitReached = false;
      })
    }else if(rol_user === 'ADMIN' || rol_user === 'Gerente Vtas' || rol_user === 'Supervisor Vtas' || rol_user === 'Facturacion'){
      this.ordClienteService.buscarTodosPedidos().subscribe( p_ordenes => {
        
        this.ordClienteService.Ord_ClientCambio.next(p_ordenes);

        this.isLoadingResults = false;
        this.isRateLimitReached = false;
      })
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  descargarPedido(ord_Client : Ord_Client){
    this.famaordService.generarImpresion(ord_Client.famaord_enc.idFamaord_Enc).subscribe(data => {
      const url = window.URL.createObjectURL(data);
      const a = document.createElement('a');
      a.setAttribute('style', 'display:none');
      document.body.appendChild(a);
      a.href = url;
      a.download = 'pedido_'+ord_Client.famaord_enc.numpedi+'.pdf'
      a.click();
    });
  }

  modificarPedido(ord_Client : Ord_Client){
    let navigationExtras: NavigationExtras = {
      state: {
        fa: ord_Client.famaord_enc,
        cl: ord_Client.ccmacli,
        ac: "actualizar"
      }
    };
    this.router.navigate(['ingresarpedido'],navigationExtras);
  }

  ingresarpedido(){
    this.router.navigate(['ingresarpedido']);
  }
}
