import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-entregapedido',
  templateUrl: './entregapedido.component.html',
  styleUrls: ['./entregapedido.component.css']
})
export class EntregapedidoComponent implements OnInit {

  isLoadingResults = false;
  isRateLimitReached = false;

  constructor() { }

  ngOnInit() {
  }

}
