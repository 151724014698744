import { CcmacliService } from './../../../_service/ccmacli.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SelectCcmaclidialogComponent } from '../../clientes/select-ccmaclidialog/select-ccmaclidialog.component';
import { DialogselecionarclienteexportacionComponent } from './dialogselecionarclienteexportacion/dialogselecionarclienteexportacion.component';
import { Ccmacli } from 'src/app/_model/ccmacli';

@Component({
  selector: 'app-facturaexportacion',
  templateUrl: './facturaexportacion.component.html',
  styleUrls: ['./facturaexportacion.component.css']
})
export class FacturaexportacionComponent implements OnInit {

  isLoadingResults = false;
  isRateLimitReached = false;
  activarFormulario : boolean = false;

  selectCliente : Ccmacli;

  constructor(private dialog: MatDialog, private ccmacliService : CcmacliService) {
    
   }

  ngOnInit(): void {
    this.obtenerClientes();
  }

  obtenerClientes(){
    const dialogRef = this.dialog.open(DialogselecionarclienteexportacionComponent, {
      width: '1000px',
      height: '550px',
      disableClose: true
      /*,
      data: this.selection.selected*/
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result != null){
        this.activarFormulario = true;
        this.selectCliente = result.data
      }
    })
  }

  getTelefono(){
    if(this.selectCliente.celwapp != undefined ){
      return this.selectCliente.celwapp
    }else{
      return this.selectCliente.telnego
    }
  }
}
