import { CcmavenService } from 'src/app/_service/ccmaven.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Ccmaven } from 'src/app/_model/ccmaven';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialogselectvendedor',
  templateUrl: './dialogselectvendedor.component.html',
  styleUrls: ['./dialogselectvendedor.component.css']
})
export class DialogselectvendedorComponent implements OnInit {

  displayedColumns = ['codruta', 'nomruta', 'acciones'];
  dataSource: MatTableDataSource<Ccmaven>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private ccmavenService : CcmavenService,private dialogRef: MatDialogRef<DialogselectvendedorComponent>,private router: Router) { }

  ngOnInit() {
    this.ccmavenService.listar().subscribe(data =>{
      this.ccmavenService.ccmavenCambio.next(data);
    })

    this.ccmavenService.ccmavenCambio.subscribe(data =>{
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.filterPredicate = function(data, filter: string): boolean {
        let nombre : string
        if(data.nomruta !+ null){
          nombre = data.nomruta.toLowerCase();
        }else{
          nombre = nombre +"";
        }
        let codigo : string;
        if(data.codruta != null){
          codigo = data.codruta.toString();
        }else{
          codigo = "";
        }
        return codigo === filter || nombre.toLowerCase().includes(filter);
      };
      this.dataSource.sort = this.sort;
    });
  }

  seleccionar(p_ccmaven : Ccmaven){
    this.dialogRef.close({event:'close',data:p_ccmaven});
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  consultarPedidos(){
    this.dialogRef.close();
    this.router.navigate(['consultarpedido']);
  }

  closeDialog(){
    this.dialogRef.close();
    this.router.navigate(['consultarpedido']);
  }
}
