import { Injectable } from '@angular/core';
import { FaEnc_FaOrd } from 'src/app/_model/faenc_faord';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class FaencFaordService {

  FaEnc_FaOrdCambio = new Subject<FaEnc_FaOrd[]>();
  mensajeCambio = new Subject<string>();

  url: string = `${environment.HOST}/faenc_faoredenes`;
  
  constructor(private http: HttpClient) { }

  listar(){
    return this.http.get<FaEnc_FaOrd[]>(this.url,this.getToken());
  }

  listarPorId(idFaEnc_FaOrd : number){
    return this.http.get<FaEnc_FaOrd>(`${this.url}/${idFaEnc_FaOrd}`,this.getToken());
  }

  buscarDetalle(id : number){
    return this.http.get<FaEnc_FaOrd[]>(`${this.url}/detalle/${id}`,this.getToken());
  }

  registrar(famaord : FaEnc_FaOrd){
    return this.http.post(this.url, famaord,this.getToken());
  }

  modificar(famaord : FaEnc_FaOrd){
    return this.http.put(this.url, famaord,this.getToken());
  }

  eliminar(idFaEnc_FaOrd : number){
    return this.http.delete(`${this.url}/${idFaEnc_FaOrd}`,this.getToken());
  }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }
}
