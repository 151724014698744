import { Injectable } from '@angular/core';
import { Ccmacli_Clasificacion } from '../_model/ccmacli_clasificacion';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class CcmacliClasificacionService {

  ccmacliCambio = new Subject<Ccmacli_Clasificacion[]>();
  mensajeCambio = new Subject<string>();

  url: string = `${environment.HOST}/ccmacli_clasificacion`;
  
  constructor(private http: HttpClient) { }

  listar(){
    return this.http.get<Ccmacli_Clasificacion[]>(this.url,this.getToken());
  }

  listarPorId(idCcmacli_Clasificacion : number){
    return this.http.get<Ccmacli_Clasificacion>(`${this.url}/${idCcmacli_Clasificacion}`,this.getToken());
  }

  registrar(ccmacli_Clasificacion : Ccmacli_Clasificacion){
    return this.http.post(this.url, ccmacli_Clasificacion,this.getToken());
  }

  modificar(ccmacli_Clasificacion : Ccmacli_Clasificacion){
    return this.http.put(this.url, ccmacli_Clasificacion,this.getToken());
  }

  eliminar(idCcmacli_Clasificacion : number){
    return this.http.delete(`${this.url}/${idCcmacli_Clasificacion}`,this.getToken());
  }

  leerPorcodigo(codigo : string){
    return this.http.get<Ccmacli_Clasificacion>(`${this.url}/codigo/${codigo}`,this.getToken());
  }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }
}
