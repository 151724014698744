import { Ccmaven } from 'src/app/_model/ccmaven';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Usuario_Ccmaven } from '../_model/usuario_ccmaven';
import { Ccmacli_Ccmaven } from '../_model/vendedor/Ccmacli_Ccmaven';
import { Message } from '../_model/utils/message';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class CcmavenService {

  ccmavenCambio = new Subject<Ccmaven[]>();
  mensajeCambio = new Subject<string>();

  url: string = `${environment.HOST}/vendedores`;
  
  constructor(private http: HttpClient) { }

  listar(){
    return this.http.get<Ccmaven[]>(this.url,this.getToken());
  }

  listarPorId(idccmaven : number){
    return this.http.get<Ccmaven>(`${this.url}/${idccmaven}`,this.getToken());
  }

  buscarPorUsuario(iduser : number){
    return this.http.get<Usuario_Ccmaven>(`${this.url}/usuario/${iduser}`,this.getToken());
  }

  registrar(ccmaven : Ccmaven){
    return this.http.post(this.url, ccmaven,this.getToken());
  }

  modificar(ccmaven : Ccmaven){
    return this.http.put(this.url, ccmaven,this.getToken());
  }

  eliminar(idccmaven : number){
    return this.http.delete(`${this.url}/${idccmaven}`,this.getToken());
  }

  procesarcliente(){
    return this.http.get(`${this.url}/procesarcliente`,this.getToken());
  }

  ImportarCSV(data: File) {
    let formdata : FormData = new FormData();
    formdata.append('csvfile',data);

    return this.http.post<Message[]>(`${this.url}/importarcsv`, formdata, this.getToken());
  }

  findbyCodruta(codigo : String){
    return this.http.get<Ccmaven>(`${this.url}/findbyCodruta/${codigo}`,this.getToken());
  }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }
}
