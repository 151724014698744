<p>Clientes a ser actualizados: {{numeroclientes()}}</p>
<mat-list role="list">
    <mat-list-item role="listitem" *ngFor="let cliente of clientes">{{cliente.codclie}} - {{cliente.nomclie}}</mat-list-item>
</mat-list>
<mat-grid-list cols="2" rowHeight="100px">
    <mat-grid-tile>
        <button mat-flat-button color="primary"  (click)="closeDialog(1)">Aceptar</button>
    </mat-grid-tile>
    <mat-grid-tile>
        <button mat-flat-button color="primary"  (click)="closeDialog(2)">Cancelar</button>
    </mat-grid-tile>
</mat-grid-list>


