<div class="example-loading-shade"
    *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
    {{msj_spinner}}
    </div>
</div>

<div *ngIf="activeTable">
    <div class="example-header">
        <mat-form-field>
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar Proveedor">
        </mat-form-field>
    </div>
    <div class="example-container">
        <mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

            <ng-container matColumnDef="pvcodig" >
                <mat-header-cell *matHeaderCellDef>Codigo</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.pvcodig}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="pvnombr" >
                <mat-header-cell *matHeaderCellDef>Nombre</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.pvnombr}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="pvnit" >
                <mat-header-cell *matHeaderCellDef>NIT</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.pvnit}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="pvmail" >
                <mat-header-cell *matHeaderCellDef>Correo</mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.pvmail}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectProv(row)" [ngClass]="getRowClass(row)">
            </mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
        </div>        
</div>
<div>
    <button mat-flat-button color="primary" (click)="cerrarDialog()">Cerrar</button>
</div>