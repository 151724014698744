import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subject } from 'rxjs/internal/Subject';
import { CtMaDoc } from 'src/app/_model/ctmadoc';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EncabeadoFacturasService {

  ctmadocCambio = new Subject<CtMaDoc[]>();
  mensajeCambio = new Subject<string>();

  url: string = `${environment.HOST}/encabezado_facturacion`;
  
  constructor(private http: HttpClient) { }

  importarDBF(id : number, fecha1 : string, fecha2 : string){
    return this.http.get<CtMaDoc[]>(`${this.url}/importardbf/${id}/${fecha1}/${fecha2}`,this.getToken());
  }

  guardarDBF(lista : CtMaDoc[]){
    return this.http.post<CtMaDoc[]>(`${this.url}/guardardbf`, lista, this.getToken());
  }

  listar(){
    return this.http.get<CtMaDoc[]>(this.url,this.getToken());
  }

  listarRangoFecha(fecha1 : string, fecha2 : string){
    return this.http.get<CtMaDoc[]>(`${this.url}/listarrangofecha/${fecha1}/${fecha2}`,this.getToken());
  }

  listarRangoFechaPendientes(fecha1 : string, fecha2 : string){
    return this.http.get<CtMaDoc[]>(`${this.url}/listarrangofechapendientes/${fecha1}/${fecha2}`,this.getToken());
  }

  listarRangoFechaNC(fecha1 : string, fecha2 : string){
    return this.http.get<CtMaDoc[]>(`${this.url}/listarrangofechanc/${fecha1}/${fecha2}`,this.getToken());
  }

  registrar(ctmadoc : CtMaDoc){
    return this.http.post(this.url, ctmadoc,this.getToken());
  }

  modificar(ctmadoc : CtMaDoc){
    return this.http.put(this.url, ctmadoc,this.getToken());
  }

  buscarbuscarPorCodDocuExacto(coddocu : string){
    return this.http.get<CtMaDoc>(`${this.url}/buscarporcoddocuexacto/${coddocu}`,this.getToken());
  }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }
}
