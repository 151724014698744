import { FamaordEncService } from './../../../_service/famaord-enc.service';
import { RolService } from './../../../_service/seguridad/rol.service';
import { UsuariorolService } from './../../../_service/rol/usuariorol.service';
import { switchMap } from 'rxjs/operators';
import { UsuarioService } from 'src/app/_service/seguridad/usuario.service';
import { Usuario } from 'src/app/_model/seguridad/usuario';
import { Famaord_Enc } from 'src/app/_model/famaord_enc';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-dialogvalidarclave',
  templateUrl: './dialogvalidarclave.component.html',
  styleUrls: ['./dialogvalidarclave.component.css']
})
export class DialogvalidarclaveComponent implements OnInit {

  famaord_enc : Famaord_Enc;
  usuario : string;
  clave : string;
  usuario_obj : Usuario;

  constructor(private dialogRef: MatDialogRef<DialogvalidarclaveComponent>, @Inject(MAT_DIALOG_DATA) private data: Famaord_Enc, private usuarioService : UsuarioService,
  private usuariorolservice : UsuariorolService, private rolService : RolService, private famaordencservice : FamaordEncService,
  private snackBar: MatSnackBar) {
    this.famaord_enc = data;
    this.usuario_obj = new Usuario();
   }

  ngOnInit() {
  
  }

  validarClave(){
    this.usuarioService.validarUsuario(this.usuario,this.clave).subscribe(data => {
      if(data){
        this.usuarioService.buscarPorNombre(this.usuario).pipe(switchMap(data => {
          return this.usuariorolservice.buscarporusuario(data.usr_idUsuario);
        })).subscribe(roles => {
          roles.forEach(element => {
            this.rolService.listarPorId(element.rol_idrol).subscribe(rol => {
              if(rol.rol_nomrol == "ADMIN" || rol.rol_nomrol == "Gerente Vtas"){
                console.log("es un usuario permitido");
                this.dialogRef.close({event:'close',data:true})
              }else{
                this.snackBar.open("USUARIO SIN PRIVILEGIOS", 'AVISO',{
                  duration:2000
                });
                console.log("es un usuario NO permitido");
                this.dialogRef.close({event:'close',data:false})
              }
            })
          });
        })
      }else{
        this.snackBar.open("USUARIO SIN PRIVILEGIOS", 'AVISO',{
          duration:2000
        });
        console.log("es un usuario NO valido");
        this.dialogRef.close({event:'close',data:false})
      }
    });
  }

}
