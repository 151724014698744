import { Ccmaven } from './../../../_model/ccmaven';
import { element } from 'protractor';
import { DialogErrorComponent } from './../../utils/dialog-error/dialog-error.component';
import { Ccmacli } from './../../../_model/ccmacli';
import { FamaordEncService } from './../../../_service/famaord-enc.service';
import { Famaord_Enc } from '../../../_model/famaord_enc';
import { Component, OnInit, Inject } from '@angular/core';
import { CcmacliService } from 'src/app/_service/ccmacli.service';
import { tap, switchMap, startWith, debounceTime, scan } from 'rxjs/operators';
import { FamaordService } from 'src/app/_service/famaord.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { UsuarioService } from 'src/app/_service/seguridad/usuario.service';
import { CcmavenService } from 'src/app/_service/ccmaven.service';
import { Router, NavigationExtras } from '@angular/router';
import { FaencFaordService } from 'src/app/_service/pedido/faenc-faord.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CurrencyPipe } from '@angular/common';
import { stringToKeyValue } from '@angular/flex-layout/extended/typings/style/style-transforms';
import { Observable } from 'rxjs/internal/Observable';
import { pipe } from 'rxjs';
import { ordenDTO } from 'src/app/_model/DTO/ordenDTO';

@Component({
  selector: 'app-dialog-pedido',
  templateUrl: './dialog-pedido.component.html',
  styleUrls: ['./dialog-pedido.component.css']
})
export class DialogPedidoComponent implements OnInit {

  isLoadingResults = false;
  isRateLimitReached = false;

  fecha : Date = new Date();
  ccmacli : Ccmacli;
  ccmaven : Ccmaven;
  famaord_enc : Famaord_Enc;

  saldoPendiente : number = 0

  isContibuyente : boolean;
  isConsumidor : boolean;

  saldo$: Observable<string>;

  claseDcocuemnto : number = 0;

  constructor(private dialogRef: MatDialogRef<DialogPedidoComponent>, @Inject(MAT_DIALOG_DATA) private data: ordenDTO,
  private ccmacliService : CcmacliService, private famaordEncService : FamaordEncService, private snackBar: MatSnackBar,
  private famaordService : FamaordService, private usuarioService : UsuarioService,private ccmavenService : CcmavenService,
  private router: Router, private faencFaordService : FaencFaordService,private dialog: MatDialog) { 
    this.ccmacli = new Ccmacli();
    this.ccmacli = this.data.ccmacli;
    this.ccmaven = this.data.ccmaven;
    this.saldo$ = this.ccmacliService.CalcularsaldoArray(this.ccmacli).pipe(
      debounceTime(500), // wait 1/2 sec until user stops
      startWith("0"), // initial value
      // tap(t => console.log(t)),
      // Accumlate input values; clear when input is empty
      //scan((acc, t) => t ? acc.concat(t) : [], [])
    );
  }

  ngOnInit() {
    
    let code = this.ccmacli.codclie.split('');
    if(code[0] == "1"){
      this.isContibuyente = true;
      this.isConsumidor = true;
    }else if(code[0] == "2"){
      this.isContibuyente = false;
      this.isConsumidor = true;
    }
    
    this.famaord_enc = new Famaord_Enc();

    this.famaordEncService.mensajeCambio.subscribe(data => {
      this.snackBar.open(data, 'AVISO',{
        duration:2000
      });
    });
  }

  creditoFiscal(ccmacli?: Ccmacli){
    this.claseDcocuemnto = 0;
            this.ccmacliService.ClienteSobregirado(ccmacli).subscribe(data => {
              console.log(data);
              let v_saldo : number;
              v_saldo = (Number(data[1]))

              const dialogRef = this.dialog.open(DialogErrorComponent, {
                disableClose: true,
                width: '450px',
                data: data[0] + " $" + v_saldo.toFixed(2)
              });

              dialogRef.afterClosed().subscribe(result => {
                //this.aprobacionsobregiro(ccmacli, "CCF");
                this.dialogRef.close();
                this.registrar(ccmacli,true);
              })
            });
  }

  factura(ccmacli?: Ccmacli){
    
    this.claseDcocuemnto = 1;
    this.ccmacliService.ClienteSobregirado(ccmacli).subscribe(data => {
      console.log(data);
      let v_saldo : number;
      v_saldo = (Number(data[1]))

      const dialogRef = this.dialog.open(DialogErrorComponent, {
        disableClose: true,
        width: '450px',
        data: data[0] + " $" + v_saldo.toFixed(2)
      });

      dialogRef.afterClosed().subscribe(result => {
        //this.aprobacionsobregiro(ccmacli, "CCF");
        this.dialogRef.close();
        this.registrar(ccmacli,true);
      })
    });
  }

  registrar(ccmacli?: Ccmacli, sobregiro? : boolean){
    this.isLoadingResults = true;
    this.isRateLimitReached = true;

    this.famaordEncService.inicializarPedido(ccmacli.idccmacl,1,9,this.claseDcocuemnto,sobregiro,this.ccmaven.codruta,this.ccmaven.idCcmaven).subscribe(data => {
      this.famaordEncService.listarPorId(data).subscribe(famaordEnc => {
        this.famaordEncService.mensajeCambio.next("Registro inicial completado");
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
        let navigationExtras: NavigationExtras = {
          state: {
            fa: famaordEnc,
            cl: this.ccmacli
          }
        };
        //this.dialogRef.close();
        this.router.navigate(['ingresarpedido'],navigationExtras);
      })
    });
    
  }

  CalcularSaldo(p_ccmacli : Ccmacli){
    let saldo : number = 0;
    this.ccmacliService.Calcularsaldo(p_ccmacli).subscribe(data => {
      saldo = data;
    })
    return saldo;
  }

  notificaionSobreGiro(ccmacli? : Ccmacli, tipdoc? : string){
    /*
      let sld = "";
      if(sobregiro == true){
        sld = "Sobregiro de credito: $"+this.saldo$;
      }else{
        sld = "Credito disponible es de: $"+this.saldo$;
      }
      var msj = "APROBACION DE SOBREGIRO DE SALDO <br>"+
                  "Cliente: "+ccmacli.nomclie+"<br>"+
                  sld + "<br>"+
                  "Tipo Documento: "+tipdoc;
                  
        this.famaordService.enviarNotificacion(msj).subscribe(data =>{
          
        })
    */
  }

  cerrarVentana(){
    this.dialogRef.close();
  }

}
