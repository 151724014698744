import { Famaord } from './../../../_model/famaord';
import { Component, OnInit, Inject } from '@angular/core';
import { FamaordService } from 'src/app/_service/famaord.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-dialog-famaord-cant',
  templateUrl: './dialog-famaord-cant.component.html',
  styleUrls: ['./dialog-famaord-cant.component.css']
})
export class DialogFamaordCantComponent implements OnInit {

  famaord : Famaord;

  constructor(private dialogRef: MatDialogRef<DialogFamaordCantComponent>, @Inject(MAT_DIALOG_DATA) private data: Famaord,
  private famaordService : FamaordService,private snackBar: MatSnackBar) { 
    this.famaord = new Famaord();
    this.famaord = data;
  }

  ngOnInit() {
    this.famaordService.mensajeCambio.subscribe(data => {
      this.snackBar.open(data, 'AVISO',{
        duration:2000
      });
    });
  }

  modificar(){
    this.famaordService.modificar(this.famaord).subscribe(() =>{
      this.dialogRef.close();
    })
  }

  cerrar(){
    this.dialogRef.close();
  }

}
