<div class="example-loading-shade"
       *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
      
    </div>
</div>
<mat-card class="login-card" style="text-align: center;">
    S I S 2 0 0 0
</mat-card>
<div class="divCenter">
    <mat-card class="login-card" style="text-align: center;">
            <mat-card-title>LogIn</mat-card-title>
        <mat-card-content>
            <mat-form-field >
                <input matInput placeholder="Usuario" [(ngModel)]="usuarioAuth.username">
            </mat-form-field>
            <br>
            <mat-form-field >
                <input matInput placeholder="Contraseña" type="password" [(ngModel)]="usuarioAuth.password">
            </mat-form-field>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button (click)="autenticar();">Login</button>
        </mat-card-actions>
    </mat-card>
</div>