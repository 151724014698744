import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent {

  @Output() animationCompleted = new EventEmitter<void>();

  onAnimationEnd(): void {
    this.animationCompleted.emit();
  }

  constructor() { }

}
