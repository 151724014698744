<div>
    <div class="example-container mat-elevation-z8">
    </div>
    <mat-card class="example-card center">
        <mat-card-header class="centerContent">
            <mat-card-title>Catalogo de Clientes</mat-card-title>
        </mat-card-header>

            <mat-tab-group dynamicHeight>
                <mat-tab label="Clientes">

                    <mat-grid-list cols="5" rowHeight="120px">
                        <mat-grid-tile colspan="1" rowspan="1">
                            <button mat-raised-button color="primary" disabled="true">Buscar Codigo</button>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <mat-label>Clasificacion</mat-label>
                                <mat-select [(ngModel)]="idccmacli_clasificacion_select">
                                    <mat-option *ngFor="let clase of ccmacliClasificacion" [value]="clase.idccmacli_clasificacion">
                                        {{clase.clase}}
                                        </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <label for="ccmacli.codclie">Codigo cliente</label>
                                <input matInput type="text" id="ccmacli.codclie" [(ngModel)]="ccmacli.codclie" name="ccmacli.codclie" required>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="2" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <label for="ccmacli.nomnego">Nombre Negocio</label>
                                <input matInput type="text" id="ccmacli.nomnego" [(ngModel)]="ccmacli.nomnego" name="ccmacli.nomnego" required>
                            </mat-form-field>
                        </mat-grid-tile>
                    </mat-grid-list>

                    <mat-grid-list cols="4" rowHeight="120px">
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <label for="ccmacli.nomclie">Nombre NRC</label>
                                <input matInput type="text" id="ccmacli.nomclie" [(ngModel)]="ccmacli.nomclie" name="ccmacli.nomclie" required>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="3" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <label for="ccmacli.dirclie">Direccion</label>
                                <input matInput type="text" id="ccmacli.dirclie" [(ngModel)]="ccmacli.dirclie" name="ccmacli.dirclie" required>
                            </mat-form-field>
                        </mat-grid-tile>

                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <label for="contacto_cliente">Contacto</label>
                                <input matInput type="text" id="contacto_cliente" name="contacto_cliente" required>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <mat-label>Departamento</mat-label>
                                <mat-select [(ngModel)]="departamento_select">
                                    <mat-option *ngFor="let departamento of departamentos" [value]="departamento.coddep">
                                        {{departamento.nomdep}}
                                        </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <mat-label>Municipio</mat-label>
                                <mat-select [(ngModel)]="municipio_select">
                                    <mat-option *ngFor="let municipio of municipios" [value]="municipio">
                                        {{municipio}}
                                        </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <label for="ccmacli.telclie">Telefono</label>
                                <input matInput type="text" id="ccmacli.telclie" [(ngModel)]="ccmacli.telclie" name="ccmacli.telclie" required>
                            </mat-form-field>
                        </mat-grid-tile>

                    </mat-grid-list>
                </mat-tab>
              
                <mat-tab label="Datos Venta">
                    <mat-grid-list cols="5" rowHeight="100px">

                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <mat-label>Ruta</mat-label>
                                <mat-select>
                                    <mat-option>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-grid-tile>

                        <mat-grid-tile colspan="1" rowspan="1">
<!--                        <mat-form-field class="example-full-width">
                               <label for="vendedor.nomruta">Vendedor</label>
                                <input matInput type="text" id="vendedor.nomruta" [(ngModel)]="vendedor.nomruta" name="vendedor.nomruta" required>
                            </mat-form-field>-->
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <label for="canal_venta">Canal de Venta</label>
                                <input matInput type="text" id="canal_venta" required>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <label for="zona_venta">Zona de Venta</label>
                                <input matInput type="text" id="zona_venta" required>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field>
                                <label for="vsta">Visita</label>
                                <input matInput type="text" id="vsta" required>
                            </mat-form-field>
                        </mat-grid-tile>

                    </mat-grid-list>
                </mat-tab>

                <mat-tab label="Lista de Precio">
                    <mat-grid-list cols="5" rowHeight="120px">
                        <mat-grid-tile colspan="1" rowspan="1">
                            <button mat-raised-button color="primary" (click)="leerListaPrecio()">Lista de precio</button>
                        </mat-grid-tile>
                    </mat-grid-list>
                </mat-tab>
   <!--              
                <mat-tab label="Informe Fiscal">
                    <mat-grid-list cols="4" rowHeight="120px">

                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field>
                                <label></label>
                                <input matInput type="text" matInput placeholder="NRC" required>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <label for="nit.cliente"></label>
                                <input name="nit.cliente" id="nit.cliente" type="text" matInput placeholder="N.I.T" data-mask="0000" required>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <label for="giro"></label>
                                <input name="giro" id="giro" type="text" matInput placeholder="Giro" required>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <label for="nombre_nit"></label>
                                <input name="nombre_nit" id="nombre_nit" type="text" matInput placeholder="Nombre NIT" required>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <section class="example-full-width">
                                <p>
                                    <mat-checkbox formControlName="pepperoni">Gran Contribuidor</mat-checkbox>
                                </p>
                                <p>
                                    <mat-checkbox formControlName="extracheese">Mediano Contribuidor</mat-checkbox>
                                </p>
                                <p>
                                    <mat-checkbox formControlName="mushroom">Pequeño Contribuidor</mat-checkbox>
                                </p>
                            </section>
                        </mat-grid-tile>

                    </mat-grid-list>
                </mat-tab>
                <mat-tab label="Condiciones de Credito">
                    <mat-grid-list cols="4" rowHeight="120px">

                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <label for="garantia"></label>
                                <input name="garantia" id="garantia" type="text" matInput placeholder="Garantia" required>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <label for="diaz_plazo"></label>
                                <input name="diaz_plazo" id="diaz_plazo" type="text" matInput placeholder="Diaz plazo" required>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field appearance="fill">
                                <mat-label>Inicio</mat-label>
                                <input matInput [matDatepicker]="picker">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <label for="limite"></label>
                                <input name="limite" id="limite" type="text" matInput placeholder="Limite" required>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <label for="documentos"></label>
                                <input name="documentos" id="documentos" type="text" matInput placeholder="Documentos" required>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <label for="cta_contable"></label>
                                <input name="cta_contable" id="cta_contable" type="text" matInput placeholder="Cuenta contable" required>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <label for="precio"></label>
                                <input name="precio" id="precio" type="text" matInput placeholder="Precio" required>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field>
                                <mat-label>Estado</mat-label>
                                <mat-select>
                                    <mat-option>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <label for="ultimo_pago"></label>
                                <input name="ultimo_pago" id="ultimo_pago" type="text" matInput placeholder="Ultimo Pago" required>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field>
                                <mat-label>Fecha</mat-label>
                                <input matInput [matDatepicker]="otro">
                                <mat-datepicker-toggle matSuffix [for]="otro"></mat-datepicker-toggle>
                                <mat-datepicker #otro></mat-datepicker>
                            </mat-form-field>
                        </mat-grid-tile>

                    </mat-grid-list>
                </mat-tab>
                <mat-tab label="Movimientos">
                    <mat-grid-list cols="4" rowHeight="100px">

                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <label for="saldo_inicial"></label>
                                <input name="saldo_inicial" id="saldo_inicial" type="text" matInput placeholder="Sal Inicial" required>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <label></label>
                                <input matInput type="text" matInput placeholder="Compras" required>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <label></label>
                                <input matInput type="text" matInput placeholder="Pagos" required>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <label></label>
                                <input matInput type="text" matInput placeholder="Sal Final" required>
                            </mat-form-field>
                        </mat-grid-tile>

                    </mat-grid-list>
                </mat-tab>
                <mat-tab label="Antiguedad">
                    <mat-grid-list cols="5" rowHeight="100px">

                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <label></label>
                                <input matInput type="text" matInput placeholder="No Vencido" required>
                            </mat-form-field>
                        </mat-grid-tile>

                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <label></label>
                                <input matInput type="text" matInput placeholder="1 + Dias" required>
                            </mat-form-field>
                        </mat-grid-tile>

                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <label></label>
                                <input matInput type="text" matInput placeholder="Pagos" required>
                            </mat-form-field>
                        </mat-grid-tile>

                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <label></label>
                                <input matInput type="text" matInput placeholder="Sal Final" required>
                            </mat-form-field>
                        </mat-grid-tile>

                        <mat-grid-tile colspan="1" rowspan="1">
                            <mat-form-field class="example-full-width">
                                <label></label>
                                <input matInput type="text" matInput placeholder="Mas de 90 Dias" required>
                            </mat-form-field>
                        </mat-grid-tile>

                    </mat-grid-list>
                    
                </mat-tab>
                -->
            </mat-tab-group>
  <!--
            <mat-card-actions align="center">
                <button type="submit" mat-raised-button [disabled]="myform.invalid" color="primary">Cancelar</button>
                <button type="submit" mat-raised-button [disabled]="myform.invalid" color="warn">Eliminar</button>
            </mat-card-actions>
-->          
        

    </mat-card>
</div>
