import { switchMap } from 'rxjs/operators';
import { UsuarioCcmaven } from 'src/app/_model/vendedor/UsuarioCcmaven';
import { UsuarioCcmavenService } from './../../../_service/usuario/vendedor/usuario-ccmaven.service';
import { CcmavenService } from './../../../_service/ccmaven.service';
import { UsuarioService } from './../../../_service/seguridad/usuario.service';
import { Ccmaven } from './../../../_model/ccmaven';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Usuario } from 'src/app/_model/seguridad/usuario';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-asignarusauariodialog',
  templateUrl: './asignarusauariodialog.component.html',
  styleUrls: ['./asignarusauariodialog.component.css']
})
export class AsignarusauariodialogComponent implements OnInit {

  usuario : Usuario;
  ccmaven : Ccmaven;
  usuarioCcmaven : UsuarioCcmaven;

  displayedColumns = ['username', 'acciones'];
  dataSource: MatTableDataSource<Usuario>;

  displayedColumnsVendedor = ['vendedor', 'acciones'];
  dataSourceVendedor: MatTableDataSource<Ccmaven>;

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  constructor(private usuarioService : UsuarioService, private ccmavenService : CcmavenService, private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<AsignarusauariodialogComponent>, private usuarioCcmavenService : UsuarioCcmavenService) {
    this.usuario = new Usuario();
    this.ccmaven = new Ccmaven();
    this.usuarioCcmaven = new UsuarioCcmaven();
  }

  ngOnInit() {

    this.usuarioService.usuarioCambio.subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator.toArray()[0];
      this.dataSource.sort = this.sort.toArray()[0];
    });

    this.usuarioService.mensajeCambio.subscribe(data => {
      this.snackBar.open(data, 'AVISO',{
        duration:2000
      });
    });

    this.usuarioService.listar().subscribe(data => {
      this.usuarioService.usuarioCambio.next(data);
    });

    this.ccmavenService.ccmavenCambio.subscribe(data => {
      this.dataSourceVendedor = new MatTableDataSource(data);
      this.dataSourceVendedor.paginator = this.paginator.toArray()[1];
      this.dataSourceVendedor.sort = this.sort.toArray()[1];
    });

    this.ccmavenService.listar().subscribe(data => {
      this.ccmavenService.ccmavenCambio.next(data);
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  seleccionarUsuario(row : Usuario){
    this.usuario = row;
  }

  selecionarVendedor(row : Ccmaven){
    this.ccmaven = row;
  }

  guardar(){
    this.usuarioCcmaven.ccmaven = this.ccmaven;
    this.usuarioCcmaven.usuario = this.usuario;

    this.usuarioCcmavenService.registrar(this.usuarioCcmaven).pipe(switchMap(()=>{
      return this.usuarioCcmavenService.listar();
    })).subscribe(data => {
      this.usuarioCcmavenService.usuarioCcmavenCambio.next(data);
      this.dialogRef.close();
    })
  }

  cancelar(){
    this.dialogRef.close();
  }
}
