import { Component, OnInit, ViewChildren, QueryList, Inject } from '@angular/core';

import { Ccmasuc } from 'src/app/_model/cliente/ccmasuc';
import { Router, ActivatedRoute } from '@angular/router';
import { CcmasucService } from 'src/app/_service/cliente/ccmasuc.service';
import { Ccmacli } from 'src/app/_model/ccmacli';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-sucursaldialog',
  templateUrl: './sucursaldialog.component.html',
  styleUrls: ['./sucursaldialog.component.css']
})
export class SucursaldialogComponent implements OnInit {

  displayedColumns = ['dirsucs','acciones'];
  dataSource: MatTableDataSource<Ccmasuc>;

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  ccmacli : Ccmacli;
  ccmasuc : Ccmasuc;
  direccionPrincipal : Ccmasuc;
  ccmasucList : Ccmasuc[] = [];

  constructor(private dialogRef: MatDialogRef<SucursaldialogComponent> ,@Inject(MAT_DIALOG_DATA) private p_ccmacli: Ccmacli,private router: Router,private route: ActivatedRoute, private ccmasucService : CcmasucService) { 
    this.ccmacli = new Ccmacli();
    this.ccmacli = p_ccmacli;
    this.direccionPrincipal = new Ccmasuc();
    this.ccmasucService.listaSucursalesiIdccmacl(this.ccmacli.idccmacl).subscribe(data => {
      this.ccmasucList = data;
      this.direccionPrincipal.ccmacli = this.ccmacli;
      this.direccionPrincipal.codclie = this.ccmacli.codclie;
      this.direccionPrincipal.dirsucs = this.ccmacli.dirnego;
      this.direccionPrincipal.nomsucs = "Oficinas"
      this.direccionPrincipal.ciusucs = this.ccmacli.munclie;
      //if(!this.direccionPrincipal.dirsucs == null){
        this.ccmasucList.push(this.direccionPrincipal);
      //}
      //console.log(this.ccmasucList);
      this.dataSource = new MatTableDataSource(this.ccmasucList);
      this.dataSource.paginator = this.paginator.toArray()[0];
      this.dataSource.sort = this.sort.toArray()[0];
    });
  }

  ngOnInit() {
    this.ccmasucService.ccmasucCambio.subscribe(data => {
      this.ccmasucList = data;
      this.direccionPrincipal.ccmacli = this.ccmacli;
      this.direccionPrincipal.codclie = this.ccmacli.codclie;
      this.direccionPrincipal.dirsucs = this.ccmacli.dirnego;
      if(!this.direccionPrincipal.dirsucs == null){
        this.ccmasucList.push(this.direccionPrincipal);
      }
      this.dataSource = new MatTableDataSource(this.ccmasucList);
      this.dataSource.paginator = this.paginator.toArray()[0];
      this.dataSource.sort = this.sort.toArray()[0];
    });
  }

  asignarSucursal(p_ccmasuc : Ccmasuc){
    this.ccmasuc = p_ccmasuc;
    this.cerrarDialog();
  }

  cerrarDialog(){
    this.dialogRef.close({event:'close',data:this.ccmasuc});
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
