import { Ccmacli_Ccmaven } from './../../../_model/vendedor/Ccmacli_Ccmaven';
import { CcmasucService } from './../../../_service/cliente/ccmasuc.service';
import { Ccmasuc } from './../../../_model/cliente/ccmasuc';
import { switchMap } from 'rxjs/operators';
import { CcmacliccmavenserviceService } from './../../../_service/cliente/ccmacliccmavenservice.service';
import { CcmacliService } from './../../../_service/ccmacli.service';
import { CcmavenService } from './../../../_service/ccmaven.service';
import { Ccmacli } from './../../../_model/ccmacli';
import { Ccmaven } from './../../../_model/ccmaven';
import { Component, OnInit, Inject, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { SucursaldialogComponent } from '../sucursaldialog/sucursaldialog.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-select-ccmaclidialog',
  templateUrl: './select-ccmaclidialog.component.html',
  styleUrls: ['./select-ccmaclidialog.component.css']
})
export class SelectCcmaclidialogComponent implements OnInit {

  isLoadingResults = true;
  isRateLimitReached = true;

  ccmaven : Ccmaven;
  ccmacliCcmaven : Ccmacli_Ccmaven;
  ccmacli_CcmavenList : Ccmacli_Ccmaven[] = [];
  ccmacliList : Ccmacli [] = [];
  ccmasucList : Ccmasuc [] = [];
  ccmasuc : Ccmasuc;

  displayedColumns = ['codclie', 'nomclie', 'acciones'];
  dataSource: MatTableDataSource<Ccmacli>
  dataSourceActual: MatTableDataSource<Ccmacli_Ccmaven>

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  constructor(private dialogRef: MatDialogRef<SelectCcmaclidialogComponent>, @Inject(MAT_DIALOG_DATA) private p_vendedor: Ccmaven, private ccmavenService : CcmavenService, private ccmacliService : CcmacliService,
  private ccmacliccmavenService : CcmacliccmavenserviceService,private snackBar: MatSnackBar, private ccmasucService : CcmasucService, private dialog: MatDialog) { 
    this.ccmaven = new Ccmaven();
    this.ccmaven = p_vendedor;
    this.ccmacliCcmaven = new Ccmacli_Ccmaven();
    this.ccmasuc = new Ccmasuc();
  }

  ngOnInit() {
    this.ccmacliccmavenService.buscarClientePorVendedor(this.p_vendedor.idCcmaven).subscribe(data => {
      this.ccmacliccmavenService.ccmacliCcmavenCambio.next(data);
      this.isLoadingResults = false;
      this.isRateLimitReached = false;
    });

    this.ccmacliccmavenService.ccmacliCcmavenCambio.subscribe(data =>{
      this.dataSourceActual = new MatTableDataSource(data);
      this.dataSourceActual.paginator = this.paginator.toArray()[0];
      this.dataSourceActual.sort = this.sort.toArray()[0];
    });

    this.ccmacliccmavenService.mensajeCambio.subscribe(data => {
      this.snackBar.open(data, 'AVISO',{
        duration:2000
      });
    });

    this.ccmacliService.listar().subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator.toArray()[1];
      this.dataSource.sort = this.sort.toArray()[1];
    });
    
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  selecionarCliente(ccmacli : Ccmacli){
    this.isLoadingResults = true;
    this.isRateLimitReached = true;

    this.ccmacliCcmaven.ccmacli = ccmacli;
    this.ccmacliCcmaven.ccmaven = this.ccmaven;
    this.ccmacliCcmaven.estado = 1;
    this.ccmacliccmavenService.registrar(this.ccmacliCcmaven).pipe(switchMap(data => {
      this.ccmacliCcmaven = data;
      //console.log(this.ccmacliCcmaven);
      return this.ccmacliccmavenService.buscarClientePorVendedor(this.p_vendedor.idCcmaven);
    })).subscribe(data => {
      this.ccmacliccmavenService.ccmacliCcmavenCambio.next(data);
      this.ccmacliccmavenService.mensajeCambio.next("Agregado");
      if(Number(ccmacli.sucursal) > 0){
        //this.verSucursales(this.ccmacliCcmaven);
      }
      this.isLoadingResults = false;
      this.isRateLimitReached = false;
    });
    
  }

  eliminarCliente(p_ccmacliCcmaven : Ccmacli_Ccmaven){
    //console.log(p_ccmacliCcmaven);
    p_ccmacliCcmaven.estado = 0;
    this.ccmacliccmavenService.modificar(p_ccmacliCcmaven).pipe(switchMap(() => {
      return this.ccmacliccmavenService.buscarClientePorVendedor(this.p_vendedor.idCcmaven);
    })).subscribe(data => {
      this.ccmacliccmavenService.ccmacliCcmavenCambio.next(data);
      this.ccmacliccmavenService.mensajeCambio.next("Desactivado");
    })
    
  }

  tieneSucursales(ccmacli : Ccmacli){
    if(ccmacli.sucursal == "1"){
      return true;
    }else{
      return false;
    }
  }

  verSucursales(p_ccmacliCcmaven : Ccmacli_Ccmaven){
    //console.log(p_ccmacli);
    const dialogRef = this.dialog.open(SucursaldialogComponent, {
      width: '750px',
      height: '600px',
      data: p_ccmacliCcmaven.ccmacli
    });

    dialogRef.afterClosed().subscribe(result => {
      this.ccmasuc = result.data;
      p_ccmacliCcmaven.ccmasuc = this.ccmasuc;
      console.log(p_ccmacliCcmaven)
      this.ccmacliccmavenService.modificar(p_ccmacliCcmaven).subscribe(data => {
        this.ccmacliccmavenService.mensajeCambio.next(data.ccmasuc.nomsucs + " agregada");
      });
    });

  }
}
