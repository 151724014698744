    <mat-toolbar>Modificar Cantidad</mat-toolbar>
        <mat-card-header class="centerContent">
            <mat-card-title>Producto: {{famaord.inbogrl.descrip_ln}}</mat-card-title>
        </mat-card-header>
      <form #myform="ngForm" class="example-form">
        <mat-grid-list cols="1" rowHeight="100px">
          <mat-grid-tile  colspan="1" rowspan="1" >
            <mat-form-field>
                <label for="famaord.totunid">Cantidad</label>
                <input matInput type="number" [(ngModel)]="famaord.totunid" name="famaord.totunid">
            </mat-form-field>
            </mat-grid-tile>
        </mat-grid-list>
        <mat-card-actions align="center">
          <mat-grid-list cols="2" rowHeight="100px">
            <mat-grid-tile  colspan="1" rowspan="1" >
              <button type="submit" mat-raised-button [disabled]="myform.invalid" color="primary" (click)="modificar()" >Modificar</button>
            </mat-grid-tile>
            <mat-grid-tile  colspan="1" rowspan="1" >
              <button type="submit" mat-raised-button [disabled]="myform.invalid" color="warn" (click)="cerrar()" >Cerrar</button>
            </mat-grid-tile>
          </mat-grid-list>
        </mat-card-actions>
      </form>