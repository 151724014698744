import { FamaordService } from './../../../_service/famaord.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-dialog-error',
  templateUrl: './dialog-error.component.html',
  styleUrls: ['./dialog-error.component.css']
})
export class DialogErrorComponent implements OnInit {

  mensaje : string;

  constructor(private dialogRef: MatDialogRef<DialogErrorComponent>,@Inject(MAT_DIALOG_DATA) private data: string) { 
    this.mensaje = data;
  }

  ngOnInit() {

  }

  continuar(){
    this.dialogRef.close();
  }
}
