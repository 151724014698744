import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Cpmaprv } from './../../../../_model/cpmaprv';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialogimportarcpmaprv',
  templateUrl: './dialogimportarcpmaprv.component.html',
  styleUrls: ['./dialogimportarcpmaprv.component.css']
})
export class DialogimportarcpmaprvComponent implements OnInit {

  cpmaprv : Cpmaprv[];

  constructor(private dialogRef: MatDialogRef<DialogimportarcpmaprvComponent>,@Inject(MAT_DIALOG_DATA) private data: Cpmaprv[]) { 
    this.cpmaprv = data;
  }

  ngOnInit(): void {
  }

  closeDialog(opcion : number){
    if(opcion==1){
      this.dialogRef.close({event:'close',data:this.cpmaprv});
    }else{
      this.dialogRef.close();
    } 
  }

  numeroclientes(){
    return this.cpmaprv.length;
  }
}
