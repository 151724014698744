export class Inbogrl{
idinbogrl : number;
codigo_ln : string;
codbarr_ln : string;
cbarrde_ln : string;
descrip_ln : string;
present_ln : string;
codcate_ln : string;
unipeso_ln : number;
pesoxun_ln : number;
unixcaj_ln : number;
unixdis_ln : number;
porcreg_ln : number;
impuest_ln : number;
grucomi_ln : number;
eximini_ln : number;
eximaxi_ln : number;
exifisi_ln : number;
ultcost_ln : number;
cospini_ln : number;
cospfin_ln : number;
cospstd_ln : number;
preunid_ln : number;
preuni1_ln : number;
preuni2_ln : number;
preuni3_ln : number;
preuni4_ln : number;
preuni5_ln : number;
precaja_ln : number;
preplan_ln : number;
exiante_ln : number;
uniingr_ln : number;
uniegre_ln : number;
uingtem_ln : number;
uegrtem_ln : number;
exiactu_ln : number;
uexicon_ln : number;
uniinac_ln : number;
uniegac_ln : number;
finvfis_ln : string;
fultmov_ln : string;
valante_ln : number;
valingr_ln : number;
valegre_ln : number;
vingtem_ln : number;
vrgrtem_ln : number;
vingacu_ln : number;
vegracu_ln : number;
cuecost_ln : string;
cuevtas_ln : string;
cuecons_ln : string;
linestd_ln : number;
uniestd_ln : number;
unicomp_ln : number;
uniorde_ln : number;
fingord_ln : string;
sabores_ln : string;
tipcodi_ln : string;
exiaver_ln : number;
coduser_ln : string;
ctrcier_ln : string;
estado_ln : number;
idmarca : string;
controla : string;
detprod : string;
codline_ln : string;
unirete_ln : number;
codproy : string;
modubic : string;
imgurl_ln : string;
desc_max : number;
desc_min : number;
uni_d_vta : string;
}