import { InborutService } from './../../../_service/inventario/inborut.service';
import { FacturadteService } from './../../../_service/dte/facturadte.service';
import { HistorialPagosComponent } from './../historial-pagos/historial-pagos.component';
import { WhatsappComponent } from './../../utils/whatsapp/whatsapp.component';
import { CcmacliService } from './../../../_service/ccmacli.service';
import { DialogmenuimpresionComponent } from './../dialogmenuimpresion/dialogmenuimpresion.component';
import { DialogvalidarclaveComponent } from './../../control/dialogvalidarclave/dialogvalidarclave.component';
import { LocationService } from './../../../_service/utils/location.service';
import { DialogErrorComponent } from './../../utils/dialog-error/dialog-error.component';
import { Ccmasuc } from './../../../_model/cliente/ccmasuc';
import { DialogFamaordDescFactuComponent } from './../dialog-famaord-desc-factu/dialog-famaord-desc-factu.component';
import { Famaord } from './../../../_model/famaord';
import { FamaordEncService } from './../../../_service/famaord-enc.service';
import { OrdClienteService } from './../../../_service/ord-cliente.service';
import { Ccmacli } from '../../../_model/ccmacli';
import { Inbogrl } from '../../../_model/inbogrl';
import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { FamaordService } from 'src/app/_service/famaord.service';
import { DialogProductosComponent } from '../dialog-productos/dialog-productos.component';
import { InbogrlService } from 'src/app/_service/inbogrl.service';
import { FaEnc_FaOrd } from 'src/app/_model/faenc_faord';
import { Famaord_Enc } from 'src/app/_model/famaord_enc';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { Ord_Client } from 'src/app/_model/ord_client';
import { DialogFamaordCantComponent } from '../dialog-famaord-cant/dialog-famaord-cant.component';
import { DialogFamaordPreComponent } from '../dialog-famaord-pre/dialog-famaord-pre.component';
import { DialogFamaordCantCajaComponent } from '../dialog-famaord-cant-caja/dialog-famaord-cant-caja.component';
import { DialogFamaordDescProdComponent } from '../dialog-famaord-desc-prod/dialog-famaord-desc-prod.component';
import { SucursaldialogComponent } from '../../clientes/sucursaldialog/sucursaldialog.component';
import { DialogProductoComponent } from '../dialog-producto/dialog-producto.component';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GetosService } from 'src/app/_service/utils/getos.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CcmacobService } from 'src/app/_service/cliente/ccmacob.service';
import { Ccmacob } from 'src/app/_model/CCTES/ccmacob';
import { WhastappconfigService } from 'src/app/_service/utils/whastappconfig.service';
import { element } from 'protractor';
import { DecimalRounderService } from 'src/app/_service/utils/decimal-rounder.service';

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}

@Component({
  selector: 'app-ingresarpedido',
  templateUrl: './ingresarpedido.component.html',
  styleUrls: ['./ingresarpedido.component.css']
})
export class IngresarpedidoComponent implements OnInit {

  displayedColumns = ['codprod', 'producto', 'cancaja', 'totunid','unidadestotales', 'valunid','descuentoProducto','descuentoEspecial' ,'subtotal'];
  displayedSumColumns = ['emptyFooter', 'emptyFooter', 'emptyFooter', 'emptyFooter','emptyFooter','emptyFooter','emptyFooter', 'sumastitle', 'valsumas'];
  displayedDescFactuColumns = ['emptyFooter', 'emptyFooter', 'emptyFooter', 'emptyFooter','emptyFooter','emptyFooter','emptyFooter', 'descfactutitle', 'valdescfactu'];
  displayRet1Columns = ['emptyFooter', 'emptyFooter', 'emptyFooter', 'emptyFooter','emptyFooter','emptyFooter','emptyFooter','ret1title', 'valret1'];
  displayedIVAColumns = ['emptyFooter', 'emptyFooter', 'emptyFooter', 'emptyFooter','emptyFooter','emptyFooter','emptyFooter', 'ivatitle', 'valiva'];
  displayedTOTALColumns = ['emptyFooter', 'emptyFooter', 'emptyFooter', 'emptyFooter','emptyFooter','emptyFooter','emptyFooter', 'totaltitle', 'valtotal'];
  displayto = this.displayedColumns;
  dataSource: MatTableDataSource<Famaord>;

  displayedSumColumnsAndroid = ['emptyFooter', 'emptyFooter', 'emptyFooter', 'emptyFooter','emptyFooter', 'sumastitle', 'valsumas'];
  displayedDescFactuColumnsAndroid = ['emptyFooter', 'emptyFooter', 'emptyFooter', 'emptyFooter','emptyFooter','descfactutitle', 'valdescfactu'];
  displayRet1ColumnsAndroid = ['emptyFooter', 'emptyFooter', 'emptyFooter', 'emptyFooter','emptyFooter','ret1title', 'valret1'];
  displayedIVAColumnsAndroid = ['emptyFooter', 'emptyFooter', 'emptyFooter', 'emptyFooter','emptyFooter','ivatitle', 'valiva'];
  displayedTOTALColumnsAndroid = ['emptyFooter', 'emptyFooter', 'emptyFooter', 'emptyFooter','emptyFooter','totaltitle', 'valtotal'];
  displayedColumnsAndroid = ['android-producto','android-unidades','android-precio','android-descuentoEspecial','android-subtotal'];
  dataSourceAndroid: MatTableDataSource<Famaord>;

  inbogrlList : Inbogrl [] = [];
  ccmacli : Ccmacli;
  numpedi : string;
  faEnc_FaOrd : FaEnc_FaOrd;
  famaord_enc : Famaord_Enc;
  ord_cliente : Ord_Client;
  dataR: Famaord_Enc;
  printAct : Boolean = false;
  printActFec : Boolean = false;
  printActDir : Boolean = false;
  actualizar : String = "";
  tipoDocumento : String;
  tiles: Tile[];
  dt : Date;
  fechaSeleccionada: Date = new Date();
  subTotalDinero : number = 0;
  impuestoIVA: number;
  descuentoDinero : number;
  totalDinero : number;
  famaordList : Famaord[] = [];
  ivaActCampo : Boolean = true;
  isAdmin : Boolean = false;
  userRol : string;
  msj_spinner : string;
  sistema_operativo : string;
  credito_disponible : number;
  SaldoPendienteTotal : Number;
  cantidaddocumentospendientes : number;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public innerWidth: any;

  isLoadingResults = true;
  isRateLimitReached = true;

  constructor(private famaordService : FamaordService, private dialog: MatDialog, private snackBar: MatSnackBar,
    private inbogrlService : InbogrlService,private route: ActivatedRoute,private router: Router, 
    private famaordEncService : FamaordEncService, private ordClientService : OrdClienteService,
    private locationService : LocationService, private ccmacliService : CcmacliService, private getosService : GetosService,
    private matIconRegistry: MatIconRegistry,private domSanitizer: DomSanitizer,private ccmacobService : CcmacobService,private whastappconfigService : WhastappconfigService, 
    private facturadteService : FacturadteService, private decimalRounderService : DecimalRounderService) {
      this.sistema_operativo = this.getosService.getOS().toUpperCase();
      this.route.queryParams.subscribe(params => {
        if (this.router.getCurrentNavigation().extras.state) {
          this.dataR = this.router.getCurrentNavigation().extras.state.fa;
          this.ccmacli = this.router.getCurrentNavigation().extras.state.cl;
          this.actualizar = this.router.getCurrentNavigation().extras.state.ac;
          this.ord_cliente = new Ord_Client();
          this.ord_cliente.famaord_enc = this.dataR;
          console.log(this.ord_cliente.famaord_enc);
          this.ord_cliente.ccmacli = this.ccmacli;
          
          this.ord_cliente.fecha = new Date().toISOString();
          if(this.ord_cliente.famaord_enc.cladocu==="0"){
            this.tipoDocumento = "CREDITO FISCAL";
            this.ivaActCampo = true;
          }else{
            this.ivaActCampo = false;
            this.tipoDocumento = "FACTURA";
          }
        }else{
          this.router.navigate(['pedidos']);
        }
      });

      this.matIconRegistry.addSvgIcon(
        "whatsapp",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/whatsapp_icon_138013.svg")
      );
    }

  ngOnInit() {
    console.log(this.sistema_operativo)
    let token;
    let decodedToken;
    const helper = new JwtHelperService();
    token = sessionStorage.getItem(environment.TOKEN_NAME);
    decodedToken = helper.decodeToken(token);
    this.userRol = decodedToken.authorities[0];
    if(decodedToken.authorities[0].includes("ADMIN") || decodedToken.authorities[0].includes("Gerente Vtas") || decodedToken.authorities[0].includes("Supervisor Vtas") ){
      this.isAdmin = true;
    }else{
      this.isAdmin = false;
    }

    this.innerWidth = window.innerWidth;
    //this.quitarColumnas(this.innerWidth);

    this.dt = new Date(this.ord_cliente.famaord_enc.fecpedi);
    
    this.tiles = [
      {text: 'Tipo Documento: '+this.tipoDocumento.toString(), cols: 1, rows: 1, color: ''},
      {text: 'Numero de Pedido: '+this.ord_cliente.famaord_enc.numpedi.toString(), cols: 1, rows: 1, color: ''},
    ];

    this.actualizarCreditoDisponible();

    this.famaordEncService.buscarPorNumPedido(this.dataR.numpedi).subscribe(dat=> {
      this.ord_cliente.famaord_enc = dat;
      this.ord_cliente.famaord_enc.autorizado = false;
        if(!this.actualizar){ 
          this.locationService.getPosition().then(pos=>
          {
            this.ord_cliente.famaord_enc.latitud = pos.lat;
            this.ord_cliente.famaord_enc.longitud = pos.lng;
          });
          this.ordClientService.registrar(this.ord_cliente).subscribe(() => {
            this.snackBar.open("Pedido "+this.dataR.numpedi, 'AVISO',{
              duration:2000
            });
          });
        }else{
          this.printAct = true;
          this.printActDir = true;
          this.printActFec = true;
        }
    });

    this.famaordService.buscarPorNumPedido(this.dataR.numpedi).subscribe(data => {
      this.famaordList = data;
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.isLoadingResults = false;
      this.isRateLimitReached = false;

      this.dataSourceAndroid = new MatTableDataSource(data);
      this.dataSourceAndroid.paginator = this.paginator;
      this.dataSourceAndroid.sort = this.sort;
      this.actualizarCreditoDisponible();
    });
    
    this.famaordService.mensajeCambio.subscribe(data => {
      this.snackBar.open(data, 'AVISO',{
        duration:2000
      });
    });

    this.inbogrlService.listar().subscribe(data => {
      this.inbogrlList = data;
    });
    
    this.famaordService.famaordCambio.subscribe(data => {
      let detalle : Famaord[] = [];
      data.forEach(item => {
        if(item.numpedi == this.dataR.numpedi){
          detalle.push(item);
        }
      });
      if(data.length > 0){
        this.printAct = true;
      }else{
        this.printAct = false;
      }
      this.famaordList = detalle;
      this.dataSource = new MatTableDataSource(detalle);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      this.dataSourceAndroid = new MatTableDataSource(detalle);
      this.dataSourceAndroid.paginator = this.paginator;
      this.dataSourceAndroid.sort = this.sort;

      this.ccmacliService.Calcularsaldo(this.ccmacli).subscribe(data=>{
        this.credito_disponible = this.ccmacli.limcred - (data + Number(this.getTOTAL()));
      })
    });
    
    this.ccmacobService.saldototalpendiente(this.ccmacli.codclie).subscribe(data => {
      this.SaldoPendienteTotal = data;
    });

    this.ccmacobService.cantidaddocumentospendientes(this.ccmacli.codclie).subscribe(data=>{
      this.cantidaddocumentospendientes = data;
    });
  }

  getProductoNombre(codigo_ln? : string){
      for (let i=0; i < this.inbogrlList.length; i++) {
        if (this.inbogrlList[i].codigo_ln === codigo_ln) {
            return this.inbogrlList[i].descrip_ln;
        }
    }
  }

  openDialog() {
    
      if(this.isAutorizado()){
        this.dialog.open(DialogProductosComponent, {
          width: '600px',
          data: this.ord_cliente
        });
      }else{
        if(this.isAdmin){
          this.dialog.open(DialogProductosComponent, {
            width: '600px',
            data: this.ord_cliente
          });
        }else{
          this.famaordService.mensajeCambio.next("Documento procesado, no se permite agregar productos, Consulte con GERENTE DE VENTAS");
        }/*
        this.dialog.open(DialogErrorComponent, {
          width: '600px',
          height: '90%',
          data: "Documento procesado, no se permite agregar productos, Consulte con GERENTE DE VENTAS"
        });*/
      }
     
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSourceAndroid.filter = filterValue.trim().toLowerCase();
  }

  actualizarCantidadCaja(row? : Famaord){
      if(this.isAutorizado()){
        const dialog = this.dialog.open(DialogFamaordCantCajaComponent, {
          width: '450px',
          disableClose: true,
          data: row
        });
        dialog.afterClosed().subscribe(()=>{
          this.actualizarCreditoDisponible();
        });
      }else{
        if(this.isAdmin){
          const dialog = this.dialog.open(DialogFamaordCantCajaComponent, {
            width: '450px',
            disableClose: true,
            data: row
          });
          dialog.afterClosed().subscribe(()=>{
            this.actualizarCreditoDisponible();
          });
        }else{
          this.famaordService.mensajeCambio.next("Documento procesado, no se permite agregar productos, Consulte con GERENTE DE VENTAS");
        }
      }
  }

  actualizarCantidad(row? : Famaord){
      if(this.isAutorizado()){
        const dialog = this.dialog.open(DialogFamaordCantComponent, {
          width: '450px',
          data: row
        });
        dialog.afterClosed().subscribe(()=>{
          this.actualizarCreditoDisponible();
        });
      }else{
        if(this.isAdmin){
          const dialog = this.dialog.open(DialogFamaordCantComponent, {
            width: '450px',
            data: row
          });
          dialog.afterClosed().subscribe(()=>{
            this.actualizarCreditoDisponible();
          });
        }else{
          this.famaordService.mensajeCambio.next("Documento procesado, no se permite cambios.")
        }
      }
  }

  actualizarPrecio(row? : Famaord){
    if(this.userRol == 'ADMIN'){
      if(this.isAutorizado()){
          const dialog = this.dialog.open(DialogFamaordPreComponent, {
          width: '450px',
          disableClose : true,
          data: row
        });
          dialog.afterClosed().subscribe(()=>{
          this.actualizarCreditoDisponible();
        });
      }else{
        this.famaordService.mensajeCambio.next("Documento procesado, no se permite cambios.")
      }
    }else{
      this.famaordService.mensajeCambio.next("Permisos Insuficientes.");
    }  
  }

  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  }

  guardarFecha(){
    if(this.isAutorizado()){
      this.printActFec = true;
      this.ord_cliente.famaord_enc.fecentrega = this.fechaSeleccionada.toISOString();
      this.famaordEncService.modificar(this.ord_cliente.famaord_enc).subscribe(()=>{
        this.famaordService.mensajeCambio.next("Fecha Actualizada");
      })
    }else{
      this.famaordService.mensajeCambio.next("Documento procesado, no se permite cambios.");
    }
  }

  

  getPrecio(precio : number){
    if(this.ord_cliente.famaord_enc.cladocu.includes("0")){
      //return precio.toFixed(2);
      return this.decimalRounderService.roundToTwoDecimals(precio);
    }else if(this.ord_cliente.famaord_enc.cladocu.includes("1")){
      return (this.decimalRounderService.roundToTwoDecimals(precio*1.13));
      
    }
  }

  getCantidadTotal(p_famaord : Famaord){
    //p_famaord.uniped = Number(((p_famaord.cancaja*p_famaord.inbogrl.unixcaj_ln) + p_famaord.totunid).toFixed(2));
    p_famaord.uniped = this.decimalRounderService.roundToTwoDecimals((p_famaord.cancaja*p_famaord.inbogrl.unixcaj_ln) + p_famaord.totunid);
    return p_famaord.uniped;
  }

  getSubTotal(p_famaord : Famaord){
    let valS : number = 0;
    let precio = this.getPrecio(p_famaord.valnuni);
    valS = (((p_famaord.cancaja*p_famaord.inbogrl.unixcaj_ln) + p_famaord.totunid)*Number(precio));
    //p_famaord.subtotal = Number(valS.toFixed(3));
    p_famaord.subtotal = this.decimalRounderService.roundToTwoDecimals(valS);
    return p_famaord.subtotal;
  }

  getSumas(){
     let sumas : number = 0;
      this.famaordList.forEach(item => {
        sumas += Number(this.getSubTotal(item));
      });
      //return sumas.toFixed(2);
      return this.decimalRounderService.roundToTwoDecimals(sumas);
  }

  getRet1(){
    if(this.ord_cliente.famaord_enc.ccmacli.clacont == "G"){
      return this.decimalRounderService.roundToTwoDecimals((Number(this.getSumas())-Number(this.getDescuentoFactu()))*0.01);
    }else{
      return 0.00
    }
  }

  getIVA(){
    if(this.ord_cliente.famaord_enc.cladocu.includes("0")){
      let sumas : number = 0;
      this.famaordList.forEach(item => {
        //sumas += (((item.cancaja*item.inbogrl.unixcaj_ln) + item.totunid)*item.valnuni);
        sumas += Number(this.getSubTotal(item));
      });
      let iva : number
      iva = (sumas - Number(this.getDescuentoFactu()))*0.13;
      return (iva).toFixed(6);
    }else if(this.ord_cliente.famaord_enc.cladocu.includes("1")){
      return 0;
    }
  }

  getTOTAL(){
    if(this.ord_cliente.famaord_enc.cladocu.includes("0")){
      let sumas : number = 0;
      sumas = (Number(this.getSumas())+Number(this.getIVA()))-Number(this.getDescuentoFactu());
      //return sumas.toFixed(2);
      return this.decimalRounderService.roundToTwoDecimals(sumas);
    }else if(this.ord_cliente.famaord_enc.cladocu.includes("1")){
      let sumas : number = 0;
      sumas = Number(this.getSumas())-Number(this.getDescuentoFactu());
      //return sumas.toFixed(2);
      return this.decimalRounderService.roundToTwoDecimals(sumas);
    }
  }

  actualizarDescProd(row? : Famaord){
    if(this.isAdmin){
      if(this.isAutorizado()){
        const dialogRef = this.dialog.open(DialogFamaordDescProdComponent, {
          width: '450px',
          disableClose: true,
          data: row
        });
        dialogRef.afterClosed().subscribe(()=>{
          this.actualizarCreditoDisponible();
        });
      }else{
        this.famaordService.mensajeCambio.next("Documento procesado, no se permite cambios.")
      }
    }else{
      this.famaordService.mensajeCambio.next("Permisos Insuficientes.");
    }
  }

  getDescuentoFactu(){
      if(this.famaordList.length > 0){
        let descFactura : number;
        let otrosDescuentos : number =0;
        descFactura = Number(this.getSumas())*(this.famaordList[0].desfact/100);
        this.famaordList.forEach(element => {
          if(this.ord_cliente.famaord_enc.cladocu.includes("0")){
            otrosDescuentos += (((element.desprod/100)*(element.valnuni))*element.uniped); //Descuento por producto
            otrosDescuentos += (element.desespe * element.uniped); //Descuento Especial
          }else if(this.ord_cliente.famaord_enc.cladocu.includes("1")){
            otrosDescuentos += (((element.desprod/100)*(this.getPrecio(element.valnuni)))*element.uniped); //Descuento por producto
            otrosDescuentos += (element.desespe * element.uniped); //Descuento Especial
          }
        });
        
        //return Number((descFactura+otrosDescuentos).toFixed(2));
        return this.decimalRounderService.roundToTwoDecimals(descFactura+otrosDescuentos);
      }else{
        return 0;
      }
  }

  actualizarDescfact(){
    if(this.userRol == 'ADMIN'){
      if(this.isAutorizado()){
        let descFactura : number;
        if(this.famaordList.length > 0){
          descFactura = (this.famaordList[0].desfact);
        }else{
          descFactura = 0;
        }
        const dialogRef = this.dialog.open(DialogFamaordDescFactuComponent, {
          width: '450px',
          data: descFactura
        });

        dialogRef.afterClosed().subscribe(result => {
          this.famaordList.forEach(element => {
            element.desfact = Number(result.data);
            this.famaordService.modificar(element).subscribe(()=>{
              
            });
          });
        });
      }else{
        this.famaordService.mensajeCambio.next("Documento procesado, no se permite cambios.");
      }
    }else{
      this.famaordService.mensajeCambio.next("Permisos Insuficientes.");
    }
  }

  opendialogMenuImpresion(famaord_enc?: Famaord_Enc){
    this.famaordEncService.listarPorId(famaord_enc.idFamaord_Enc).subscribe(data => {
      if(data.estado == 3){
        const dialogRef = this.dialog.open(DialogmenuimpresionComponent, {
          width: '450px',
          height: '450px',
          data: famaord_enc
        });
        
        dialogRef.afterClosed().subscribe(result => {
          let rs = result.data
          if(rs == 1){
            this.descargarReporte(famaord_enc);
          }else if(rs == 2){
            this.printDialog(famaord_enc);
          }else if(rs == 3){
            this.sendToQuickPrinter();
          }else if(rs == 4){
            this.snackBar.open("NO HABILITADO", 'AVISO',{
              duration:2000
            });
          }
          else if(rs == 5){
            this.factura_DTE(famaord_enc);
          }
          else if(rs == 6){
            this.ccf_DTE(famaord_enc);
          }
        });
        
      }else{
        this.snackBar.open("PEDIDO NO AUTORIZADO", 'AVISO',{
          duration:2000
        });
      }
    })
    
  }

  printDialog(famaord_enc?: Famaord_Enc){
    
   }
 
   descargarReporte(famaord_enc?: Famaord_Enc) {
    this.ord_cliente.famaord_enc.sumas = Number(this.getSumas());
    this.ord_cliente.famaord_enc.descfactu = Number(this.getDescuentoFactu());
    this.ord_cliente.famaord_enc.iva = Number(this.getIVA());
    this.ord_cliente.famaord_enc.total = Number(this.getTOTAL());
      /**Actualiza los datos */
      //this.ActualizarValoresFamaord(famaord_enc);
    this.famaordEncService.modificar(this.ord_cliente.famaord_enc).subscribe(fa =>{
      if(this.isAdmin){
        if(this.isAutorizado()){
          this.famaordList.forEach(element => {
            element.uniped = this.getCantidadTotal(element);
            element.subtotal = this.getSubTotal(element);
            this.famaordService.modificar(element).subscribe(()=>{
              this.famaordService.generarImpresion(this.ord_cliente.famaord_enc.idFamaord_Enc).subscribe(data => {
      
                const url = window.URL.createObjectURL(data);
                 const a = document.createElement('a');
                 a.setAttribute('style', 'display:none');
                 document.body.appendChild(a);
                 a.href = url;
                 a.download = 'pedido_'+this.ord_cliente.famaord_enc.numpedi+'.pdf'
                 //a.click();
                 //a.target = '_blank';
                 //window.URL.revokeObjectURL(a.href);
                 window.open(a.href,'_blank');
                 this.ord_cliente.famaord_enc.estado = 2;
               });
            });
          });
        }else{
          this.famaordService.mensajeCambio.next("Documento procesado, no se permite cambios.");
        }
     }else{
      this.famaordService.mensajeCambio.next("Permisos Insuficientes.");
     }
    })

      this.famaordEncService.modificar(this.ord_cliente.famaord_enc).subscribe(()=>{
      });
   }

  factura_DTE(famaord_enc?: Famaord_Enc) {
      this.facturadteService.generarfacturadte(this.ord_cliente.famaord_enc.idFamaord_Enc).subscribe(data => {
        const url = window.URL.createObjectURL(data);
         const a = document.createElement('a');
         a.setAttribute('style', 'display:none');
         document.body.appendChild(a);
         a.href = url;
         window.open(a.href,'_blank');
         this.ord_cliente.famaord_enc.estado = 2;
      })
  }
  
  ccf_DTE(famaord_enc?: Famaord_Enc) {
    this.facturadteService.generarfacturadte(this.ord_cliente.famaord_enc.idFamaord_Enc).subscribe(data => {
      const url = window.URL.createObjectURL(data);
       const a = document.createElement('a');
       a.setAttribute('style', 'display:none');
       document.body.appendChild(a);
       a.href = url;
       window.open(a.href,'_blank');
       this.ord_cliente.famaord_enc.estado = 2;
    })
  }

  ActualizarValoresFamaord(famaord_enc?: Famaord_Enc){
    if(this.isAdmin){
      if(this.isAutorizado()){
        this.famaordList.forEach(element => {
          element.uniped = this.getCantidadTotal(element);
          element.subtotal = this.getSubTotal(element);
          this.famaordService.modificar(element).subscribe(()=>{
            
          });
        });
      }else{
        this.famaordService.mensajeCambio.next("Documento procesado, no se permite cambios.");
      }
   }else{
    this.famaordService.mensajeCambio.next("Permisos Insuficientes.");
   }
  }

  guardarDireccion(){
    if(this.isAutorizado()){
    const dialogRef = this.dialog.open(SucursaldialogComponent, {
      width: '450px',
      data: this.ord_cliente.ccmacli
    });

    dialogRef.afterClosed().subscribe(result => {
      let sucursal : Ccmasuc = result.data;
      this.ord_cliente.famaord_enc.direntrega = sucursal.dirsucs;
      this.famaordEncService.modificar(this.ord_cliente.famaord_enc).subscribe(()=>{
        this.snackBar.open("Dirección Actualizada", 'AVISO',{
          duration:2000
        });
      });
    });
    }else{
      this.famaordService.mensajeCambio.next("Documento procesado, no se permite cambios.");
    }  
  }

  getprintAct(){
    if(this.ord_cliente.famaord_enc.estado == 3 && this.isAdmin == false){
      return true
    }else if(this.isAdmin){
      return true
    }
    //return this.isAdmin
  }

  isAndroid(){
    //console.log(this.sistema_operativo)
    let str_os = "Android";
    if(this.sistema_operativo === str_os.toUpperCase()){
      return true;
    }else{
      return false;
    }
  }

  eliminarProducto(row? : Famaord){
    this.isLoadingResults = true;
    this.isRateLimitReached = true;
    if(this.isAutorizado()){
      this.famaordService.eliminar(row.idfamaord).pipe(switchMap(()=>{
        return this.famaordService.buscarPorNumPedido(this.dataR.numpedi);
      })).pipe(switchMap(dat=>{
        this.famaordService.famaordCambio.next(dat);
        this.ord_cliente.ccmacli.vcarmes = this.ord_cliente.ccmacli.vcarmes - this.getSubTotal(row);
        return this.ccmacliService.modificar(this.ord_cliente.ccmacli);
      })).subscribe(()=>{
        this.famaordService.mensajeCambio.next("SALDO ACTUALIZADO.");
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
      });
    }else{
      this.famaordService.mensajeCambio.next("Documento procesado, no se permite cambios.");
      this.isLoadingResults = false;
        this.isRateLimitReached = false;
     }
  }

  dialogProducto(row? : Famaord){
    if(this.isAdmin){
      if(this.isAutorizado()){
        
        const dialogRef = this.dialog.open(DialogProductoComponent, {
          width: '450px',
          data: row,
        });

        dialogRef.afterClosed().subscribe(result => {
          if(result.data != undefined){
            let fa : Famaord = new Famaord();
            fa = result.data;

            row.cancaja = fa.cancaja;
            row.totunid = fa.totunid;
            row.desprod = fa.desprod;
            row.desespe = fa.desespe;

            this.isLoadingResults = true;
            this.isRateLimitReached = true;

            this.famaordService.modificar(row).pipe(switchMap(()=>{
              return  this.famaordService.buscarPorNumPedido(this.dataR.numpedi);
            })).subscribe(ordenes => {
              this.famaordService.famaordCambio.next(ordenes);
              this.famaordService.mensajeCambio.next("Producto Modificado");
              this.isLoadingResults = false;
              this.isRateLimitReached = false;
            })
          }
        });
      }else{
        this.famaordService.mensajeCambio.next("Documento procesado, no se permite cambios.");
      }
   }else{
    this.famaordService.mensajeCambio.next("Permisos Insuficientes.");
   }
  }

  isAutorizado() {
    let value : boolean;
    if(this.ord_cliente.famaord_enc.autorizado == false && this.ord_cliente.famaord_enc.estado == 1 || this.ord_cliente.famaord_enc.estado == 4){
      value = true;
    }else{

      if(this.isAdmin){
        value = true;
      }else{
        value = false;
      }
    }
    return value;
  }

  getImpreso(){
    if(this.ord_cliente.famaord_enc.estado == 2){
      return false;
    }else{
      return true;
    }
  }

  desbloquearPedido(famaord_enc : Famaord_Enc){
    const dialogRef = this.dialog.open(DialogvalidarclaveComponent,{
      width: '250px',
      data: famaord_enc,
    });

    dialogRef.afterClosed().subscribe(result => {
      let valido : boolean = result.data;
      if(valido == true){
        this.ord_cliente.famaord_enc.estado = 1;
        this.famaordEncService.modificar(this.ord_cliente.famaord_enc).subscribe(()=>{
          this.snackBar.open("AUTORIZADO", 'AVISO',{
            duration:2000
          });
        })
      }else{
        this.snackBar.open("NO AUTORIZADO", 'AVISO',{
          duration:2000
        });
      }
    });
  }

  sendToQuickPrinter(){
    let detalle = "";
    let total = this.getTOTAL().toString();
    let sumas = this.getSumas();
    let iva =this.getIVA();
    let desc = this.getDescuentoFactu();
    let retencion1 = "";
    this.famaordList.forEach(element => {
      
      if(element.famaord_enc.cladocu == "0"){
      detalle = detalle +
                "<SMALL><LEFT>"+element.inbogrl.descrip_ln+"-"+element.inbogrl.uni_d_vta+"<br>"+
                element.cancaja+";;"+element.totunid+";;$"+this.decimalRounderService.roundToTwoDecimals(element.valnuni)+";;$"+element.subtotal+"<br>"
                if(element.famaord_enc.ccmacli.clacont == "G"){
                  retencion1 = "<br><RIGHT>1% Ret;;$"+ this.decimalRounderService.roundToTwoDecimals(((Number(sumas)-Number(desc))*0.01))+""
                  total = (Number(total) - this.decimalRounderService.roundToTwoDecimals(((Number(sumas)-Number(desc))*0.01))).toString();
                }else{
                  retencion1 = "";
                }
      }else if(element.famaord_enc.cladocu == "1"){
        detalle = detalle +
                "<SMALL><LEFT>"+element.inbogrl.descrip_ln+"-"+element.inbogrl.uni_d_vta+"<br>"+
                element.cancaja+";;"+element.totunid+";;$"+this.decimalRounderService.roundToTwoDecimals((element.valnuni*1.13))+";;$"+element.subtotal+"<br>"
      }          
    });
    let text =  "<big><CENTER>D U R A N G O<br>"+
                "<br>"+
                "<CENTER>SIS2000"+
                "<br>"+
                "<big><CENTER>Pedido #"+this.ord_cliente.famaord_enc.numpedi.toString()+"<br>"+
                "<br>"+
                "<SMALL><LEFT>Cliente:"+this.ccmacli.codclie+"   V-"+this.ord_cliente.famaord_enc.ccmaven.codruta+"   "+this.ord_cliente.famaord_enc.fecpedi.slice(0,10)+"<br>"+
                "<SMALL><LEFT>"+this.ccmacli.nomclie+"<br>"+
                "NIT: "+this.ccmacli.nitclie+
                "<br>"+
                "NRC: "+this.ccmacli.nrcclie+
                "<br>"+
                "Emitir: "+this.tipoDocumento+
                "<br>"+
                "Condicion: "+this.ord_cliente.ccmacli.diaplaz +" Dias<br>"+
                "<SMALL><LEFT>"+ this.ord_cliente.famaord_enc.direntrega+"<br>"+
                "<br>"+
                "<LEFT>Cjas;;Unids;;P. Unit;;Total<br>"+
                "<LINE>"+
                detalle+
                "<br>"+
                "<LINE>"+
                "<br>"+
                "<RIGHT>Sumas;;$"+ sumas +
                "<br>"+
                "<RIGHT>Desc;;$"+ desc +
                retencion1+
                "<br>"+
                "<RIGHT><UNDERLINE>IVA;;$"+ this.decimalRounderService.roundToTwoDecimals(Number(iva)) +
                "<br><br>"+
                "<RIGHT>Total;;$"+ total +
                "<br>"+
                "<LINE>"+
                "<br>"+
                "<CENTER>GRACIAS POR SU COMPRA<br>"+
                "\x1B!\x6d"+
                "<cut>";

    let textEncoded = encodeURI("Hola APP");
    //window.location.href="intent:#Intent;action=com.sis2000.restapi.MainActivity;category=android.intent.category.DEFAULT;category=android.intent.category.BROWSABLE;S.msg_from_browser="+this.ord_cliente.famaord_enc.idFamaord_Enc+";end";
    window.location.href="quickprinter://"+text;
    
  }

  autorizarPedido(op : number){
    let token;
    let decodedToken;
    const helper = new JwtHelperService();
    token = sessionStorage.getItem(environment.TOKEN_NAME);
    decodedToken = helper.decodeToken(token);
    this.ord_cliente.famaord_enc.autorizadopor = decodedToken.user_name;
    switch(op){
      case 1:
        this.ord_cliente.famaord_enc.autorizado = true;
        this.ord_cliente.famaord_enc.estado = 3;
        break
      case 2:
        this.ord_cliente.famaord_enc.autorizado = false;
        this.ord_cliente.famaord_enc.estado = 4;
        break
    }
    
    
      this.famaordEncService.modificar(this.ord_cliente.famaord_enc).subscribe(()=>{
        if(this.ord_cliente.famaord_enc.autorizado == true){
          this.snackBar.open("PEDIDO "+this.ord_cliente.famaord_enc.numpedi+" AUTORIZADO", 'AVISO',{
            duration:2000
          });
          this.whastappconfigService.enviarWS(1).subscribe(da =>{

          })
        }else{
          this.snackBar.open("PEDIDO "+this.ord_cliente.famaord_enc.numpedi+" DESAUTORIZADO", 'AVISO',{
            duration:2000
          });
        }
        this.router.navigate(['consultarpedido']);
    })
  }

  enviarSoicitudAutorizacion(){
    let token;
    let decodedToken;
    this.msj_spinner = "Enviando solicitud de aprobacion";
    this.isLoadingResults = true;
    this.isRateLimitReached = true;
    const helper = new JwtHelperService();
    token = sessionStorage.getItem(environment.TOKEN_NAME);
    decodedToken = helper.decodeToken(token);
    let mensaje : String;
    if(this.getDescuentoFactu() > 0){
      mensaje = "El usuario "+ decodedToken.user_name + " solicita aprobacion del pedido "+ this.ord_cliente.famaord_enc.numpedi +" - "+ this.ord_cliente.famaord_enc.ccmacli.nomclie + "<br><br> Pendiente de aprobacion";
      this.famaordService.enviarNotificacion(mensaje).subscribe(() => {
        this.ord_cliente.famaord_enc.estado = 1;
        this.famaordEncService.modificar(this.ord_cliente.famaord_enc).subscribe(dt => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.router.navigate(['consultarpedido']);
        })
      })
    }else{
      mensaje = "El usuario "+ decodedToken.user_name + " solicita aprobacion del pedido "+ this.ord_cliente.famaord_enc.numpedi +" - "+ this.ord_cliente.famaord_enc.ccmacli.nomclie + "<br><br> Aprobacion Completa (PEDIDO SIN DESCUENTOS)";
      this.famaordService.enviarNotificacion(mensaje).subscribe(() => {
        this.ord_cliente.famaord_enc.estado = 3;
        this.famaordEncService.modificar(this.ord_cliente.famaord_enc).subscribe(dt => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.router.navigate(['consultarpedido']);
        })
      })
    }
  }

  actualizarCreditoDisponible(){
    this.ccmacliService.Calcularsaldo(this.ccmacli).subscribe(data=>{
      this.credito_disponible = this.ccmacli.limcred - (data + Number(this.getTOTAL()));
      
    })
  }

  openLink(){
    window.open("https://issuu.com/vaneduran23/docs/catalogo_2023")
  }

  enviarWhatsapp(){
    this.ord_cliente.famaord_enc.sumas = Number(this.getSumas());
    this.ord_cliente.famaord_enc.descfactu = Number(this.getDescuentoFactu());
    this.ord_cliente.famaord_enc.iva = Number(this.getIVA());
    this.ord_cliente.famaord_enc.total = Number(this.getTOTAL());
    //this.ActualizarValoresFamaord(this.ord_cliente.famaord_enc);
    this.famaordEncService.modificar(this.ord_cliente.famaord_enc).subscribe(fa =>{

      this.famaordList.forEach(element => {
        element.uniped = this.getCantidadTotal(element);
        element.subtotal = this.getSubTotal(element);
        this.famaordService.modificar(element).subscribe(()=>{
          
          const dialogRef = this.dialog.open(WhatsappComponent,{
            width: '250px',
            disableClose: true,
            data: this.ord_cliente.famaord_enc
          });
      
          dialogRef.afterClosed().subscribe(result => {
            if(result.data == undefined){
      
            }else{
              this.ccmacli = result.data;
              this.ccmacliService.modificar(this.ccmacli).subscribe(data => {
                
              })
            }
          })

        });
      });
    })
  }

  revisarFecha(row? : Ccmacob){
    let fultabo : Date = new Date(row.fultabo)
    if(fultabo.toISOString().localeCompare("2000-01-01T00:00")){
      return "Sin Abono";
    }else{
      return fultabo.toISOString();
    }
  }

  cincodocmasantiguos(){
    const dialogRef = this.dialog.open(HistorialPagosComponent,{
      width: '850px',
      /*disableClose: true,*/
      data: this.ccmacli
    });
  }


}
