import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Ccmasuc } from 'src/app/_model/cliente/ccmasuc';
import { CcmasucService } from 'src/app/_service/cliente/ccmasuc.service';
import { DialogimportarccmasucComponent } from './dialogimportarccmasuc/dialogimportarccmasuc.component';

@Component({
  selector: 'app-subirsucursales',
  templateUrl: './subirsucursales.component.html',
  styleUrls: ['./subirsucursales.component.css']
})
export class SubirsucursalesComponent implements OnInit {

  isLoadingResults = false;
  isRateLimitReached = false;
  activeTable = false;
  msj_spinner : string = "Cargando";
  lista : Ccmasuc[];
  isActualizarCliente : boolean = false;
  clientesConError : number = 0;

  displayedColumns = ['select','rutsucs','codclie','codsucs','nomclie','deptosuc','munisuc','dirsucs'];
  dataSource: MatTableDataSource<Ccmasuc>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  selection = new SelectionModel<Ccmasuc>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Ccmasuc): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.codclie + 1}`;
  }

  constructor(private ccmasucService : CcmasucService, private snackBar: MatSnackBar, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.ccmasucService.mensajeCambio.subscribe(data => {
      this.snackBar.open(data, 'AVISO',{
        duration:2000
      });
    });

    this.ccmasucService.ccmasucCambio.subscribe(data=>{
      if(data != null){
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.activeTable = true;
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
      }
    })
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
   
  }

  importarDBF(){
    this.isLoadingResults = true;
    this.isRateLimitReached = true;
    this.ccmasucService.importarDBF(1).subscribe(data => {
      this.ccmasucService.ccmasucCambio.next(data);
      //data.forEach(element => {
        //if(element.nrcclie === null || element.nitclie === '' || element.mail_dte === '' || element.celwapp === ''){
          this.clientesConError++;
        //}
      //});
      this.isLoadingResults = false;
      this.isRateLimitReached = false;
      this.isActualizarCliente = true;
    });
  }

  guardarDBF(){
    const dialogRef = this.dialog.open(DialogimportarccmasucComponent, {
      width: '650px',
      height: '450px',
      data: this.selection.selected
    });

    dialogRef.afterClosed().subscribe(result => {
      this.isLoadingResults = true;
      this.isRateLimitReached = true;
      if(result != null){
        this.ccmasucService.GuardarDBF(result.data).subscribe(data => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.dataSource.data = [];
          
          this.activeTable = false;
          this.snackBar.open(data.length +" Clientes Actualizados", 'AVISO',{
            duration:2000
          });

          location.reload();
        });
      }else{
        // TODO document why this block is empty
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
      }
    })
  }

  getRowClass(row: Ccmasuc): string {
    return row.codclie === '' || row.rutsucs === '' ? 'special-row' : '';
    
  }
}