import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialogfacturas',
  templateUrl: './dialogfacturas.component.html',
  styleUrls: ['./dialogfacturas.component.css']
})
export class DialogfacturasComponent implements OnInit {

  readonly range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  constructor(private dialogRef: MatDialogRef<DialogfacturasComponent>) { }

  ngOnInit(): void {
    
  }

  consultarFechas(){
    this.dialogRef.close({event:'close',data:this.range});
  }

  cerrarDialog(){
    this.dialogRef.close();
  }

}
