<div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
      
  </div>
</div>

<mat-toolbar>Cliente: {{ccmacli.codclie}}
<br>{{ccmacli.nomclie}}</mat-toolbar>

<mat-grid-list cols="1" rowHeight="50px">
  <mat-grid-tile>
    Limite de Credito: {{ccmacli.limcred | currency:'$'}}
  </mat-grid-tile>
  <mat-grid-tile>
    Saldo: {{saldo$ | async | currency:'$'}}
  </mat-grid-tile>
</mat-grid-list>

<mat-grid-list cols="2" rowHeight="70px">
  <mat-grid-tile>
    <button type="submit" mat-raised-button color="primary" *ngIf="isContibuyente" (click)="creditoFiscal(ccmacli)" >Credito Fiscal</button>
  </mat-grid-tile>
  <mat-grid-tile>
    <button type="submit" mat-raised-button color="primary" *ngIf="isConsumidor" (click)="factura(ccmacli)" >Factura</button>
  </mat-grid-tile>
  <mat-grid-tile>
    <button type="submit" mat-raised-button color="primary" (click)="cerrarVentana()" >CERRAR</button>
  </mat-grid-tile>
</mat-grid-list>