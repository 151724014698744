import { Ccmacob } from './../../_model/CCTES/ccmacob';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class CcmacobService {

  ccmacobCambio = new Subject<Ccmacob[]>();
  mensajeCambio = new Subject<string>();

  url: string = `${environment.HOST}/cobros`;
  
  constructor(private http: HttpClient) { }

  importarDBF(id : number){
    return this.http.get<Ccmacob[]>(`${this.url}/importardbf/${id}`,this.getToken());
  }

  saldototalpendiente(codclie : string){
    return this.http.get<number>(`${this.url}/saldototalpendiente/${codclie}`,this.getToken());
  }

  cantidaddocumentospendientes(codclie : string){
    return this.http.get<number>(`${this.url}/cantidaddocumentospendientes/${codclie}`,this.getToken());
  }

  top5(codclie : string){
    return this.http.get<Ccmacob[]>(`${this.url}/top5/${codclie}`,this.getToken());
  }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }
}
