import { Component, OnInit } from '@angular/core';
import { Btn } from 'src/app/_model/utils/btn';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-procesodatos',
  templateUrl: './procesodatos.component.html',
  styleUrls: ['./procesodatos.component.css']
})
export class ProcesodatosComponent implements OnInit {

btns : Btn[] =[];

  constructor(private router: Router) { }

  ngOnInit() {
    
    let btn : Btn;
    btn = new Btn();
    btn.id = 1;
    btn.name ="ACTUALIZAR BODEGA GENERAL";
    btn.link ="importarinventario";
    this.btns.push(btn);
    /*btn = new Btn();
    btn.id = 2;
    btn.name ="IMPORTAR VENDEDORES";
    btn.link ="importarvendedor";
    this.btns.push(btn);
    btn = new Btn();
    btn.id = 3;
    btn.name ="ASIGNAR CLIENTES A VENDEDOR";
    btn.link ="asignarclientes";
    this.btns.push(btn);
    btn = new Btn();
    btn.id = 4;
    btn.name ="IMPORTAR COBROS";
    btn.link ="importarcobros";
    this.btns.push(btn);
    btn = new Btn();
    */
    
    btn = new Btn();
    btn.id = 5;
    btn.name ="ACTUALIZAR BODEGA DE RUTAS";
    btn.link ="importarinventarioruta";
    this.btns.push(btn);

    btn = new Btn();
    btn.id = 6;
    btn.name ="ACTUALIZAR CLIENTES";
    btn.link ="importardbf";
    this.btns.push(btn);

    btn = new Btn();
    btn.id = 7;
    btn.name ="ACTUALIZAR SUCURSALES";
    btn.link ="subirsucursales";
    this.btns.push(btn);

    btn = new Btn();
    btn.id = 8;
    btn.name ="SUBIR JSON SCHEMAS";
    btn.link ="subirjsonschemasmh";
    this.btns.push(btn);

    btn = new Btn();
    btn.id = 9;
    btn.name ="SUBIR REPORTES";
    btn.link ="subirreportes";
    this.btns.push(btn);

    btn = new Btn();
    btn.id = 10;
    btn.name ="SUBIR PROVEEDORES";
    btn.link ="subirproveedores";
    this.btns.push(btn);

  }

  openlinkbtn(link : String){
    console.log(link)
    let navigationExtras: NavigationExtras = {
      state: {
        //ord: distrucion
      }
    };
    this.router.navigate([link],navigationExtras);
  }
}
