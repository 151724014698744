import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DecimalRounderService {

  url: string = `${environment.HOST}/decimal_rounder`;
  
  constructor(private http: HttpClient) { }

  roundtofourdecimalplaces(value : number){
    return this.http.get<number>(`${this.url}/roundtofourdecimalplaces/${value}`,this.getToken());
  }

  roundtofourdecimalplacesfloat(value : number){
    return this.http.get<number>(`${this.url}/roundtofourdecimalplacesfloat/${value}`,this.getToken());
  }

  truncatetofourdecimalplacesfloat(value : number){
    return this.http.get<number>(`${this.url}/truncatetofourdecimalplacesfloat/${value}`,this.getToken());
  }

  roundto8decimalplaces(value : number){
    return this.http.get<number>(`${this.url}/roundto8decimalplaces/${value}`,this.getToken());
  }

  // Función para redondear a 2 decimales
  roundToTwoDecimals(value: number): number {
    return Math.round(value * 100) / 100;
  }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }
}
