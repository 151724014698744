import { Ccmacli } from './../ccmacli';
export class Ccmasuc{

    idccmasuc : number;
    ccmacli : Ccmacli;
    codclie : string;
	codsucs : string;
	nomsucs : string;
	nomcort : string;
	telsucs : string;
	dirsucs : string;
	munisuc : string;
	ciusucs : string;
	deptosuc : string;
	deppais : string;
	enccomp : string;
	rutsucs : string;
	estsucs : string;
	vtamens : string;
	vtaacum : string;
	pte : string;
	codant : string;
}