import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotacreditodteService {

  url: string = `${environment.HOST}/notacredito_dte`;
  
  constructor(private http: HttpClient) { }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }

  generarnotacreditoDTE(coddocu : string){
    console.log('generarnotacreditoDTE');
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    return this.http.get(`${this.url}/generarnotacreditoDTE/${coddocu}`, {
        responseType: 'blob' as 'json',
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    }).pipe(
        catchError((error: HttpErrorResponse) => {
            if (error.status === 400 && error.error instanceof Blob) {
                // Assuming the error response is a JSON string in a Blob
                return new Observable(observer => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        const text = reader.result as string;
                        console.log(text);
                        //const jsonResponse = JSON.parse(text);
                        observer.error(text);  // Emit the JSON error response
                    };
                    reader.onerror = () => {
                        observer.error('Failed to read error response');
                    };
                    reader.readAsText(error.error);
                });
            } else {
                return throwError(error);
            }
        })
    );
  }
}
