import { WhastappconfigService } from './../../../_service/utils/whastappconfig.service';
import { FamaordEncService } from './../../../_service/famaord-enc.service';
import { Ccmacob } from './../../../_model/CCTES/ccmacob';
import { CcmacobService } from 'src/app/_service/cliente/ccmacob.service';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Ord_Client } from 'src/app/_model/ord_client';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Ccmacli } from 'src/app/_model/ccmacli';

@Component({
  selector: 'app-historial-pagos',
  templateUrl: './historial-pagos.component.html',
  styleUrls: ['./historial-pagos.component.css']
})
export class HistorialPagosComponent implements OnInit {

  Ord_client : Ord_Client;
  SaldoPendienteTotal : Number;
  cantidaddocumentospendientes : number;
  cliente : Ccmacli;

  displayedColumns = ['codclie', 'nomclie', 'fechadocumento','fechacobro',  'fultabo', 'ultimoabono', 'acciones'];
  dataSource: MatTableDataSource<Ccmacob>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private route: ActivatedRoute,private router: Router, private ccmacobService : CcmacobService, private famaordEncService : FamaordEncService
    ,private whastappconfigService : WhastappconfigService,private dialogRef: MatDialogRef<HistorialPagosComponent>,@Inject(MAT_DIALOG_DATA) private data: Ccmacli ) {
      this.cliente = data;
   }

  ngOnInit(): void {
    this.ccmacobService.saldototalpendiente(this.cliente.codclie).subscribe(data => {
      this.SaldoPendienteTotal = data;
    });

    this.ccmacobService.cantidaddocumentospendientes(this.cliente.codclie).subscribe(data=>{
      this.cantidaddocumentospendientes = data;
    });

    this.ccmacobService.top5(this.cliente.codclie).subscribe(documentos => {
      this.dataSource = new MatTableDataSource(documentos);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  revisarFecha(row? : Ccmacob){
    let fultabo : Date = new Date(row.fultabo)
    if(fultabo.toISOString().localeCompare("2000-01-01T00:00")){
      return "Sin Abono";
    }else{
      return fultabo.toISOString();
    }
  }

  abonar(){
    console.log("abonar");
  }

}
