import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Archivo } from 'src/app/_model/archivo';
import { SubirreportesService } from 'src/app/_service/procesos/subirreportes.service';

@Component({
  selector: 'app-subirreportes',
  templateUrl: './subirreportes.component.html',
  styleUrls: ['./subirreportes.component.css']
})
export class SubirreportesComponent implements OnInit {

  selectedFile : FileList;
  currentfileUpload : File;

  isLoadingResults = false;
  isRateLimitReached = false;
  msj_spinner : string = "Cargando";

  archivos : Archivo[];

  descripcion : string;

  actualizarJsonBTN : boolean = false;

  displayedColumns = ['select','id','descripcion', 'filetype','acciones'];
  dataSource: MatTableDataSource<Archivo>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  selection = new SelectionModel<Archivo>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Archivo): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.idArchivo + 1}`;
  }

  constructor(private subirreportesService : SubirreportesService,private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.listarTodos();
  }

  actualizar(row : Archivo){
    this.isLoadingResults = true;
    this.isRateLimitReached = true;
    this.subirreportesService.modificar(row).subscribe(archivo =>{
      this.listarTodos();
      this.isLoadingResults = false;
      this.isRateLimitReached = false;
    })
  }

  borrar(row : Archivo){
    this.isLoadingResults = true;
    this.isRateLimitReached = true;
    this.subirreportesService.eliminar(row.idArchivo).subscribe(archivo =>{
      this.listarTodos();
      this.isLoadingResults = false;
      this.isRateLimitReached = false;
    })
  }

  actualizarJson(e : any){
    this.isLoadingResults = true;
    this.isRateLimitReached = true;
    this.selectedFile = e.target.files;
    this.currentfileUpload = this.selectedFile.item(0);
    console.log(this.selection.selected[0])
    if(this.selection.selected.length = 1){
      this.subirreportesService.actulizarjson(this.currentfileUpload,this.selection.selected[0]).subscribe(data => {
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
        this.listarTodos();
      })
    }else{
      this.snackBar.open('SELECCIONE SOLO 1', 'AVISO',{
        duration:2000
      });
    }
  }

  listarTodos(){
    this.subirreportesService.listar().subscribe(datos => {
      console.log(datos);
      this.dataSource = new MatTableDataSource(datos);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

  subirArchivo(e : any){
    this.selectedFile = e.target.files;
    this.currentfileUpload = this.selectedFile.item(0);
    this.isLoadingResults = true;
    this.isRateLimitReached = true
    this.subirreportesService.subirJson(this.currentfileUpload).subscribe(data => {
      this.isLoadingResults = false;
      this.isRateLimitReached = false;
      this.listarTodos();
      this.snackBar.open('Insertado correctamente', 'AVISO',{
        duration:2000
      });
    })
  }

}
