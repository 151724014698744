<div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"> Cargando </mat-spinner>
    <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
        
    </div>
  </div>
  
  <mat-toolbar>
    Nota Credito
  </mat-toolbar>
    <div class="button-container">
      <!--<button mat-raised-button (click)="ImportarFacturas();">Obtener Notas Credito</button>-->
      <!--<button mat-raised-button (click)="GuardarFacturas();">Actualizar Notas Credito</button>-->
  
      <!--<button mat-raised-button (click)="contingenciaDTE();">Contingencia</button>-->
      <!--<button mat-raised-button (click)="ImportarDetalle();">Importar Detalle Facturas</button>-->
      <!--<button mat-raised-button (click)="GuardarDetalle();">Guardar Detalle Facturas</button>-->
    </div>
  <div class="example-container mat-elevation-z8">
  
    <div class="example-header">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
      </mat-form-field>
      <button mat-flat-button color="primary" (click)="buscarFecha();" style="float: right; margin-right: 20px">
        Cambiar Fecha
      </button>
    </div>
  
    <mat-table [dataSource]="dataSource" matSort>
  
      <ng-container matColumnDef="tipodoc">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Tipo. Doc</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{GetTipoDoc(row)}} </mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="coddocu">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Cod. Documento</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.coddocu}} </mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="codclie">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Cod. Cliente</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.codclie}} </mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="cliente">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Nom. Cliente</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.coment1}} </mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="fecdocu">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Fecha</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.fecdocu | date : 'mediumDate'}} </mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="rutavta">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Ruta</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.rutavta}} </mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="acciones">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div class="button-container" *ngIf="!esInvalidado(row)">
            <!--<button mat-flat-button color="primary" (click)="verificarDatos(row);" *ngIf="getestadofac(row)">Generar DTE</button>-->
            <button mat-flat-button color="warn" *ngIf="!getestadofac(row)" (click)="InvalidarDTE(row)" >INVALIDAR</button>
            <button mat-flat-button color="primary" *ngIf="!getestadofac(row)" (click)="descargarPDF(row)" >PDF</button>
            <button mat-flat-button color="primary" *ngIf="!getestadofac(row)" (click)="descargarJson(row)" >JSON</button>
            <!--<button mat-flat-button color="primary" (click)="generarPDF(row)" >Gen. PDF</button>-->
          </div>
          <div *ngIf="esInvalidado(row)">
            <button mat-flat-button color="primary" (click)="generarNotaCredito(row);" *ngIf="!getestadofac(row)">Nota Credito DTE</button>
          </div>
          <div class="button-container" *ngIf="esInvalidado(row) && getestadofac(row)">
              <h2>NO ESTA FACTURADO</h2>
          </div>
        </mat-cell>
      </ng-container>
  
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"> 
      </mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[100, 200, 300]"></mat-paginator>
  </div>