import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Message } from 'src/app/_model/utils/message';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class S3AwsService {

  url: string = `${environment.HOST}/s3aws`;
  
  constructor(private http: HttpClient) { }

  subirImagen(data: File) {
    let formdata : FormData = new FormData();
    formdata.append('imagenfile',data);

    return this.http.post<Message[]>(`${this.url}/subirarchivo`, formdata,this.getToken());
  }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }
}
