export class CuerpoDocumento{
  numItem       : number;
  tipoItem      : number;
  cantidad      : number;
  codigo        : string;
  uniMedida     : number;
  descripcion   : string;
  precioUni     : number;
  montoDescu    : number;
  compra        : number;
}