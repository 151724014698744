import { Component, OnInit, Inject } from '@angular/core';
import { Rol } from 'src/app/_model/seguridad/rol';
import { RolService } from 'src/app/_service/seguridad/rol.service';
import { switchMap } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-rol-dialogo',
  templateUrl: './rol-dialogo.component.html',
  styleUrls: ['./rol-dialogo.component.css']
})
export class RolDialogoComponent implements OnInit {

  rol : Rol;

  constructor(private dialogRef: MatDialogRef<RolDialogoComponent>, @Inject(MAT_DIALOG_DATA) private data: Rol, private rolService : RolService) { }

  ngOnInit() {
    this.rol = new Rol();
    this.rol.rol_idRol = this.data.rol_idRol;
    this.rol.rol_nomrol = this.data.rol_nomrol;
    this.rol.rol_desrol = this.data.rol_desrol;
    this.rol.rol_estado = this.data.rol_estado;
  }

  cancelar() {
    this.dialogRef.close();
  }

  operar() {
    
    if (this.rol != null && this.rol.rol_idRol > 0) {
      //BUENA PRACTICA
      this.rolService.modificar(this.rol).pipe(switchMap(() => {        
        return this.rolService.listar();
      })).subscribe(roles => {
        this.rolService.rolCambio.next(roles);
        this.rolService.mensajeCambio.next("SE MODIFICO");
      });
    } else {
      //MALA PRACTICA SUBSCRIBE DENTRO DE OTOR SUBSCRIBE
      this.rolService.registrar(this.rol).subscribe(() => {
        this.rolService.listar().subscribe(roles => {
          this.rolService.rolCambio.next(roles);
          this.rolService.mensajeCambio.next("SE REGISTRO");
        });
      });
    }
    this.dialogRef.close();
  }
}