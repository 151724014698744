import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Ccmasuc } from 'src/app/_model/cliente/ccmasuc';

@Component({
  selector: 'app-dialogimportarccmasuc',
  templateUrl: './dialogimportarccmasuc.component.html',
  styleUrls: ['./dialogimportarccmasuc.component.css']
})
export class DialogimportarccmasucComponent implements OnInit {

  ccmasuc : Ccmasuc[];

  constructor(private dialogRef: MatDialogRef<DialogimportarccmasucComponent>,@Inject(MAT_DIALOG_DATA) private data: Ccmasuc[]) {
    this.ccmasuc = data;
  }
  ngOnInit(): void {
  }

  closeDialog(opcion : number){
    if(opcion==1){
      this.dialogRef.close({event:'close',data:this.ccmasuc});
    }else{
      this.dialogRef.close();
    } 
  }

  numeroclientes(){
    return this.ccmasuc.length;
  }

}
