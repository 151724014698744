import { CcmacliService } from 'src/app/_service/ccmacli.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Ccmacli } from 'src/app/_model/ccmacli';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialogselecionarclienteexportacion',
  templateUrl: './dialogselecionarclienteexportacion.component.html',
  styleUrls: ['./dialogselecionarclienteexportacion.component.css']
})
export class DialogselecionarclienteexportacionComponent implements OnInit {

  isLoadingResults = false;
  isRateLimitReached = false;

  activeTable = false;
  msj_spinner : string = "Cargando";

  displayedColumns = ['codclie','nomclie','mail_dte'];
  dataSource: MatTableDataSource<Ccmacli>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private ccmacliService : CcmacliService,
    private dialogRef: MatDialogRef<DialogselecionarclienteexportacionComponent>
  ) {

   }

  ngOnInit(): void {
    this.ccmacliService.clientesexportacion().subscribe(clientes => {
      this.dataSource = new MatTableDataSource(clientes);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.activeTable = true;
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
    })
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getRowClass(row: Ccmacli): string {
    return row.codclie === '' || row.nomclie === '' ? 'special-row' : '';
  }

  selectProv(row: Ccmacli){
    this.dialogRef.close({event:'close',data:row});
  }

  cerrarDialog(){
    this.dialogRef.close();
  }
}
