<div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
  <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
  {{msj_spinner}}
  </div>
</div>


    <mat-toolbar>Modificar Descuento de Factura</mat-toolbar>

      <form #myform="ngForm" class="example-form">
        <mat-grid-list cols="1" rowHeight="100px">
            <mat-grid-tile  colspan="1" rowspan="1" >
                <mat-form-field>
                    <label for="descuentoFactura">Descuento en Porcentaje</label>
                    <input matInput type="number" [(ngModel)]="descuentoFactura" name="descuentoFactura" required >
                </mat-form-field>
            </mat-grid-tile>
        </mat-grid-list>
        <mat-card-actions align="center">
          <div class="example-button-row">
            <button type="submit" mat-raised-button [disabled]="myform.invalid" color="primary" (click)="modificar()" >Modificar</button>
          </div>
        </mat-card-actions>
      </form>