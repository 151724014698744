import { Famaord } from 'src/app/_model/famaord';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Inbogrl } from 'src/app/_model/inbogrl';
import { InbogrlService } from 'src/app/_service/inbogrl.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-impresion',
  templateUrl: './impresion.component.html',
  styleUrls: ['./impresion.component.css']
})
export class ImpresionComponent implements OnInit {

  displayedColumns = ['codprod', 'producto', 'totunid'];
  dataSource: MatTableDataSource<Famaord>;
  inbogrlList : Inbogrl [] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private dialogRef: MatDialogRef<ImpresionComponent>, @Inject(MAT_DIALOG_DATA) private data: Famaord[],private inbogrlService : InbogrlService) { 
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    console.log(data);
    
  }

  ngOnInit() {
    this.inbogrlService.listar().subscribe(data => {
      this.inbogrlList = data;
    });
  }

  getProductoNombre(codigo_ln? : string){
    for (let i=0; i < this.inbogrlList.length; i++) {
      if (this.inbogrlList[i].codigo_ln === codigo_ln) {
          return this.inbogrlList[i].descrip_ln;
      }
    }
  }

  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('print-section-pedido').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <link rel="stylesheet" type="text/css" href="style.css" />
        </head>
        <body onload="window.print()">
            ${printContents}
        </body>
      </html>`
    );
    popupWin.document.close();
  }

}
