<div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
    {{msj_spinner}}
    </div>
</div>
    <mat-toolbar>Modificar Descuento</mat-toolbar>
        <mat-card-header class="centerContent">
            <mat-card-title>Producto: {{famaord.inbogrl.descrip_ln}}</mat-card-title>
        </mat-card-header>

            <label id="example-radio-group-label" for="descuento">Seleccione el tipo de descuento.</label>
            <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group"
                [(ngModel)]="descuento"
                (change)="radioChange()">
                <mat-radio-button class="example-radio-button" *ngFor="let tipo of tipoDescs" [value]="tipo">
                    {{tipo}}
                </mat-radio-button>
            </mat-radio-group>

      <form #myform="ngForm" class="example-form" *ngIf="desEna">
        <mat-grid-list cols="1" rowHeight="100px">
            <mat-grid-tile  colspan="1" rowspan="1" >
                <mat-form-field>
                    <label for="desc">Descuento</label>
                    <input matInput type="number" [(ngModel)]="desc" name="desc" (ngModelChange)="verificarDescuento($event)" required >
                    <mat-hint>{{mjs_precio}}</mat-hint>                    
                </mat-form-field>
            </mat-grid-tile>
        </mat-grid-list>
        <mat-card-actions align="center">
            <mat-grid-list cols="2" rowHeight="100px">
                <mat-grid-tile  colspan="1" rowspan="1" >
                    <button type="submit" mat-raised-button color="primary" (click)="modificar()" [disabled]="!btn_modificar">Modificar</button>
                </mat-grid-tile>
                <mat-grid-tile  colspan="1" rowspan="1" >
                    <button type="submit" mat-raised-button color="warn" (click)="cerrar()" >Cerrar</button>
                </mat-grid-tile>
            </mat-grid-list>
        </mat-card-actions>
      </form>