import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CtMaDoc } from 'src/app/_model/ctmadoc';
import { ResponseCCfDTE } from 'src/app/_model/dte/responseccfdte';
import { Famadoc } from 'src/app/_model/famadoc';
import { FacturadteService } from 'src/app/_service/dte/facturadte.service';
import { ResponseCCFDTEService } from 'src/app/_service/dte/response-ccf-dte.service';
import { EncabeadoFacturasService } from 'src/app/_service/facturacion/encabeado-facturas.service';
import { FacturasService } from 'src/app/_service/facturacion/facturas.service';
import { DialogfacturasComponent } from '../dialogfacturas/dialogfacturas.component';
import { DialoginvalidarDTEComponent } from '../dialoginvalidar-dte/dialoginvalidar-dte.component';
import { DialogvalidacionDTECFComponent } from '../dialogvalidacion-dte-cf/dialogvalidacion-dte-cf.component';
import { DialogvalidacionDTEComponent } from '../dialogvalidacion-dte/dialogvalidacion-dte.component';

@Component({
  selector: 'app-consultadte',
  templateUrl: './consultadte.component.html',
  styleUrls: ['./consultadte.component.css']
})
export class ConsultadteComponent implements OnInit {

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  isLoadingResults = false;
  isRateLimitReached = false;

  encabezados : CtMaDoc[] = [];
  detalles : Famadoc[] = [];

  documentos : CtMaDoc[] = [];

  displayedColumns = ['tipodoc','coddocu','codclie','cliente','fecdocu','rutavta','acciones'];
  dataSource: MatTableDataSource<CtMaDoc>;

  fechaIni : Date;
  fechaFin : Date;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private facturasService : FacturasService, private encabeadoFacturasService : EncabeadoFacturasService,
    private facturadteService : FacturadteService,private dialog: MatDialog,private datePipe: DatePipe, private responseCCFDTEService : ResponseCCFDTEService
  ) {
      this.encabeadoFacturasService.ctmadocCambio.subscribe(data =>{
        this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          // Configurar la dirección de ordenación inicial
          const sortState: Sort = {active: 'fecdocu', direction: 'desc'};
          this.sort.active = sortState.active;
          this.sort.direction = sortState.direction;
          this.sort.sortChange.emit(sortState);
      })
   }

  ngOnInit(): void {
    const dialogRef = this.dialog.open(DialogfacturasComponent, {
      width: '450px',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result.data!=null){
        this.range = result.data;
        this.fechaIni = this.range.value.start;
        this.fechaFin = this.range.value.end;
        this.fechaIni.setDate(this.fechaIni.getDate() - 1);
        this.fechaFin.setDate(this.fechaFin.getDate() + 1);
        this.isLoadingResults = true;
        this.isRateLimitReached = true;
        this.encabeadoFacturasService.listarRangoFecha(this.datePipe.transform(this.fechaIni,"yyyy-MM-dd"),this.datePipe.transform(this.fechaFin,"yyyy-MM-dd")).subscribe(data => {
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          // Configurar la dirección de ordenación inicial
          const sortState: Sort = {active: 'fecdocu', direction: 'desc'};
          this.sort.active = sortState.active;
          this.sort.direction = sortState.direction;
          this.sort.sortChange.emit(sortState); 

          this.isLoadingResults = false;
          this.isRateLimitReached = false;
        })
    }})
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  GetTipoDoc( ctmadoc : CtMaDoc ){
    let tipoDocumento = ctmadoc.tipdoca+ctmadoc.cladoca
    //console.log(tipoDocumento)
    let tip : String;
    switch(tipoDocumento) { 
      case '10': { 
        tip = 'CCF'
         break; 
      } 
      case '11': { 
        tip = 'FAC'
         break; 
      } 
      default: { 
         //statements; 
         break; 
      }  
   }
   return tip; 
  }

  descargarPDF(ctmadoc : CtMaDoc){
    let responseCCF_DTE : ResponseCCfDTE;
    ///this.responseCCFDTEService.buscarcoddocu(ctmadoc.coddocu).subscribe(obj => {
      console.log(ctmadoc.url_pdf)
      const a = document.createElement('a');
      a.setAttribute('style', 'display:none');
      document.body.appendChild(a);
      a.href = ctmadoc.url_pdf;
      window.open(a.href,'_blank');
   // })
    
  }

  descargarJson(ctmadoc : CtMaDoc){
    let responseCCF_DTE : ResponseCCfDTE;
    //this.responseCCFDTEService.buscarcoddocu(ctmadoc.coddocu).subscribe(obj => {
      const a = document.createElement('a');
      a.setAttribute('style', 'display:none');
      document.body.appendChild(a);
      a.href = ctmadoc.url_json;
      window.open(a.href,'_blank');
    //})
    
  }

  generarPDF(ctmadoc : CtMaDoc){
    let tipoDocumento = ctmadoc.tipdoca+ctmadoc.cladoca
    //console.log(tipoDocumento)
    let tip : String;
    switch(tipoDocumento) { 
      case '10': { 
        tip = 'CCF'
        this.facturadteService.generapdfccf_reposicion(ctmadoc.coddocu).subscribe(obj => {
          const url = window.URL.createObjectURL(obj);
              const a = document.createElement('a');
              a.setAttribute('style', 'display:none');
              document.body.appendChild(a);
              a.href = url;
              window.open(a.href,'_blank');
        })
         break; 
      } 
      case '11': { 
        tip = 'FAC'
        this.facturadteService.generapdfcf_reposicion(ctmadoc.coddocu).subscribe(obj => {
          const url = window.URL.createObjectURL(obj);
              const a = document.createElement('a');
              a.setAttribute('style', 'display:none');
              document.body.appendChild(a);
              a.href = url;
              window.open(a.href,'_blank');
        })
         break; 
      } 
      default: { 
         //statements; 
         break; 
      }  
   }
    
  }

  getestadofac(ctmadoc : CtMaDoc){
    switch (ctmadoc.facesta) {
      case 0:
        //0 = A no generado
        return true
        break;
      case 1:
        //1 = A no generado
        return false
        break;
      default:
        return false
        break;
    }
  }

  verTodos(){
    this.isLoadingResults = true;
    this.isRateLimitReached = true;
    this.encabeadoFacturasService.listarRangoFecha(this.datePipe.transform(this.fechaIni,"yyyy-MM-dd"),this.datePipe.transform(this.fechaFin,"yyyy-MM-dd")).subscribe(data => {
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          // Configurar la dirección de ordenación inicial
          const sortState: Sort = {active: 'fecdocu', direction: 'desc'};
          this.sort.active = sortState.active;
          this.sort.direction = sortState.direction;
          this.sort.sortChange.emit(sortState); 

          this.isLoadingResults = false;
          this.isRateLimitReached = false;
    })
  }

  verPendiendtes(){
    this.isLoadingResults = true;
    this.isRateLimitReached = true;
    this.encabeadoFacturasService.listarRangoFechaPendientes(this.datePipe.transform(this.fechaIni,"yyyy-MM-dd"),this.datePipe.transform(this.fechaFin,"yyyy-MM-dd")).subscribe(data => {
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          // Configurar la dirección de ordenación inicial
          const sortState: Sort = {active: 'fecdocu', direction: 'desc'};
          this.sort.active = sortState.active;
          this.sort.direction = sortState.direction;
          this.sort.sortChange.emit(sortState); 

          this.isLoadingResults = false;
          this.isRateLimitReached = false;
    })
  }

  buscarFecha(){
    const dialogRef = this.dialog.open(DialogfacturasComponent, {
      width: '450px',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result.data!=null){
        this.range = result.data;
        this.fechaIni = this.range.value.start;
        this.fechaFin = this.range.value.end;
        this.fechaIni.setDate(this.fechaIni.getDate() - 1);
        this.fechaFin.setDate(this.fechaFin.getDate() + 1);
        this.isLoadingResults = true;
        this.isRateLimitReached = true;
        this.encabeadoFacturasService.listarRangoFecha(this.datePipe.transform(this.fechaIni,"yyyy-MM-dd"),this.datePipe.transform(this.fechaFin,"yyyy-MM-dd")).subscribe(data => {
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          // Configurar la dirección de ordenación inicial
          const sortState: Sort = {active: 'fecdocu', direction: 'desc'};
          this.sort.active = sortState.active;
          this.sort.direction = sortState.direction;
          this.sort.sortChange.emit(sortState); 

          this.isLoadingResults = false;
          this.isRateLimitReached = false;
        })
    }})
  }

  esInvalidado(ctmadoc : CtMaDoc){
    if(ctmadoc.invalidado == '1'){
      return true;
    }else{
      return false;
    }
  }
}
