import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CtMaDoc } from 'src/app/_model/ctmadoc';
import { CuerpoDocumento } from 'src/app/_model/dte/cuerpodocumento';
import { Resumen } from 'src/app/_model/dte/resumen';
import { DetalleFacturaService } from 'src/app/_service/facturacion/detalle-factura.service';

@Component({
  selector: 'app-dialogvalidacion-dte-cf',
  templateUrl: './dialogvalidacion-dte-cf.component.html',
  styleUrls: ['./dialogvalidacion-dte-cf.component.css']
})
export class DialogvalidacionDTECFComponent implements OnInit {

  displayedColumns = ['cantidad','descripcion','precioUni','montoDescu','ventaGravada'];
  dataSource: MatTableDataSource<CuerpoDocumento>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  detalleDoc : CuerpoDocumento[] = [];
  total : number = 0;
  totalDescu : number =0;
  resumen : Resumen;
  isVisible : boolean = false;
  constructor(
    private detalleFacturaService :DetalleFacturaService,
    private dialogRef: MatDialogRef<DialogvalidacionDTECFComponent> ,@Inject(MAT_DIALOG_DATA)private ctmadoc :CtMaDoc
  ) {

   }

  ngOnInit(): void {
    
        this.isVisible = false;
        this.detalleFacturaService.detalle_dte_cf(this.ctmadoc.coddocu).subscribe(detalle => {
          console.log(detalle)
          detalle.forEach(element => {
            this.totalDescu=this.totalDescu+element.montoDescu
          });
          this.dataSource = new MatTableDataSource(detalle);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          console.log(this.totalDescu)
          this.detalleFacturaService.resumen_dte_cf(this.ctmadoc.coddocu,this.totalDescu).subscribe(resumen_p =>{
            console.log(resumen_p)
            this.resumen = resumen_p;
          })
        })
  }

  cerrarDialog(){
    this.dialogRef.close();
  }

  facturar(){
    this.dialogRef.close({event:'close',data:true});
  }

}
