import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subject } from 'rxjs';
import { UsuarioRol } from 'src/app/_model/seguridad/usuariorol';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsuariorolService {

  rolCambio = new Subject<UsuarioRol[]>();
  mensajeCambio = new Subject<string>();

  url: string = `${environment.HOST}/usuariorol`;

  constructor(private http: HttpClient) { }

  listar(){
    return this.http.get<UsuarioRol[]>(this.url,this.getToken());
  }

  listarPorId(idUsuarioRol : number){
    return this.http.get<UsuarioRol>(`${this.url}/${idUsuarioRol}`,this.getToken());
  }

  registrar(rol : UsuarioRol){
    return this.http.post(this.url, rol,this.getToken());
  }

  modificar(rol : UsuarioRol){
    return this.http.put(this.url, rol,this.getToken());
  }

  eliminar(idUsuarioRol : number){
    return this.http.delete(`${this.url}/${idUsuarioRol}`,this.getToken());
  }

  buscarporusuario(idusuario : number){
    return this.http.get<UsuarioRol[]>(`${this.url}/buscarporusuario/${idusuario}`,this.getToken());
  }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }
}
