<div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
        {{msj_spinner}}
    </div>
</div>

<div >
    <mat-card>
        <mat-grid-list cols="3" rowHeight="100px">
            <mat-grid-tile *ngIf="false">
                <button type="button" mat-raised-button  color="primary" onclick="document.getElementById('flArchivo').click()">Importar Archivo Bodega General CSV</button>
                <input id="flArchivo" type="file" accept=".csv" style="display: none;" (change)="subirArchivo($event)">
            </mat-grid-tile>
            <mat-grid-tile>
                <button mat-flat-button color="primary"  (click)="importarDBF()">Obtener datos</button>
            </mat-grid-tile>
            <mat-grid-tile>
                <button mat-flat-button color="primary"  (click)="guardarDBF()" *ngIf="isActualizarInvetario">Actualizar</button>
            </mat-grid-tile>
            
        </mat-grid-list>
    </mat-card>    
    <div *ngIf="activeTable">
        <div class="example-header">
            <mat-form-field>
              <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar Producto">
            </mat-form-field>
        </div>
        <div>
            <mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                    <mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                                        [aria-label]="checkboxLabel()">
                        </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(row) : null"
                                        [checked]="selection.isSelected(row)"
                                        [aria-label]="checkboxLabel(row)">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>
            
                <ng-container matColumnDef="codRutaRt" >
                    <mat-header-cell *matHeaderCellDef>codRutaRt</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.codRutaRt}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="codProdRt" >
                    <mat-header-cell *matHeaderCellDef>codProdRt</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.codProdRt}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="preUnitRt" >
                    <mat-header-cell *matHeaderCellDef>preUnitRt</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.preUnitRt | currency}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="existencia" >
                    <mat-header-cell *matHeaderCellDef>Existencia</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{getExistencia(row)}}.U </mat-cell>
                </ng-container>

                <ng-container matColumnDef="estadoRt" >
                    <mat-header-cell *matHeaderCellDef>estadoRt</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.estadoRt}} </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
                </mat-row>
            </mat-table>
            <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
        </div>        
</div>

