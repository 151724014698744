import { CcmasucService } from './../../../_service/cliente/ccmasuc.service';
import { Ccmasuc } from './../../../_model/cliente/ccmasuc';
import { Ccmacli } from './../../../_model/ccmacli';
import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'app-sucursales',
  templateUrl: './sucursales.component.html',
  styleUrls: ['./sucursales.component.css']
})
export class SucursalesComponent implements OnInit {

  ccmacli : Ccmacli;

  displayedColumns = ['codclie', 'nomsucs', 'ciusucs','dirsucs','acciones'];
  dataSource: MatTableDataSource<Ccmasuc>;

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  
  constructor(private router: Router,private route: ActivatedRoute, private ccmasucService : CcmasucService) { 
    
    this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.ccmacli = new Ccmacli();
        this.ccmacli = this.router.getCurrentNavigation().extras.state.cliente;
        this.ccmasucService.listaSucursalesCodclie(this.ccmacli.codclie).subscribe(data => {
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator.toArray()[0];
          this.dataSource.sort = this.sort.toArray()[0];
        });
      }else{
        this.router.navigate(['clientes']);
      }
    });
  }

  ngOnInit() {
    this.ccmasucService.ccmasucCambio.subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator.toArray()[0];
      this.dataSource.sort = this.sort.toArray()[0];
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
