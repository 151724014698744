<p>Productos a ser actualizados: {{numeroproductos()}}</p>
<mat-list role="list">
    <mat-list-item role="listitem" *ngFor="let producto of productos">{{producto.codigo_ln}} - {{producto.descrip_ln}}</mat-list-item>
</mat-list>
<mat-grid-list cols="2" rowHeight="100px">
    <mat-grid-tile>
        <button mat-flat-button color="primary"  (click)="closeDialog(1)">Aceptar</button>
    </mat-grid-tile>
    <mat-grid-tile>
        <button mat-flat-button color="primary"  (click)="closeDialog(2)">Cancelar</button>
    </mat-grid-tile>
</mat-grid-list>