import { Famaord_Enc } from './famaord_enc';
import { Inbogrl } from './inbogrl';

export class Famaord{
	idfamaord : number;
	numpedi : number;
	codprod : string;
	lineart : number;
	totunid : number;
	cancaja : number;
	fracaja : number;
	cajafac : number;
	fracfac : number;
	valnuni : number;
	selereg : number;
	cdprinv : string;
	tipdesc : string;
	desprod : number;
	desfact : number;
	desespe : number;
	valinco : number;
	clasvta : string;
	tipvtas : string;
	tipempq : string;
	porimpu : number;
	ctrlcvs : string;
	cocobol : string;
	fecdocu : string;
	ctrlrec : string;
	conpart : string;
	bodsale : string;
	ctrlret : number;
	estaord : number;
	coment1 : string;
	coment2 : string;
	coment3 : string;
	limiret : number;
	poriret : number;
	nunmesa : string;
	valefec : number;
	porcomi : number;
	idusuario : number;
	famaord_enc : Famaord_Enc;
	inbogrl : Inbogrl;
	uniped : number;
	subtotal : number;
	latitud : string;
	longitud : string;
	altitud : string;
}