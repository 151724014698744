import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Rol } from '../../_model/seguridad/rol';
import { Subject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class RolService {

  rolCambio = new Subject<Rol[]>();
  mensajeCambio = new Subject<string>();

  url: string = `${environment.HOST}/roles`;

  constructor(private http: HttpClient) { }

  listar(){
    return this.http.get<Rol[]>(this.url,this.getToken());
  }

  listarPorId(idRol : number){
    return this.http.get<Rol>(`${this.url}/${idRol}`,this.getToken());
  }

  registrar(rol : Rol){
    return this.http.post(this.url, rol,this.getToken());
  }

  modificar(rol : Rol){
    return this.http.put(this.url, rol,this.getToken());
  }

  eliminar(idRol : number){
    return this.http.delete(`${this.url}/${idRol}`,this.getToken());
  }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }
}
