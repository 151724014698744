<div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
      
    </div>
</div>

<div class="main-div">
    <mat-card>
       <div class="example-button-row">
            <button mat-flat-button color="accent"  (click)="procesarClientes()" >PROCESAR ASIGNACION DE CLIENTES</button>
        </div>
    </mat-card>
</div>
