import { NotacreditodteService } from './../../../../_service/dte/notacreditodte.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ResponseCCfDTE } from 'src/app/_model/dte/responseccfdte';
import { ResponseCCFDTEService } from 'src/app/_service/dte/response-ccf-dte.service';

@Component({
  selector: 'app-dialognotacredito',
  templateUrl: './dialognotacredito.component.html',
  styleUrls: ['./dialognotacredito.component.css']
})
export class DialognotacreditoComponent implements OnInit {

  isLoadingResults = false;
  isRateLimitReached = false;

  responseCCfDTE_list : ResponseCCfDTE[];

  fechaIni : Date;
  fechaFin : Date;

  displayedColumns = ['no_pedido','dte_fecha','dte_codigo_generacion','dte_numero_control','dte_sello'];
  dataSource: MatTableDataSource<ResponseCCfDTE>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private responseCCFDTEService : ResponseCCFDTEService, private notacreditodteService : NotacreditodteService,
    private dialogRef: MatDialogRef<DialognotacreditoComponent>
  ) { }

  ngOnInit(): void {
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  cargarDatos(fecha_inicial : string, fecha_final : string){
    this.isLoadingResults = true;
    this.isRateLimitReached = true;
    this.responseCCFDTEService.buscarCreditoFiscal(fecha_inicial,fecha_final).subscribe(data => {
      this.responseCCfDTE_list = data;
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      this.isLoadingResults = false;
      this.isRateLimitReached = false;
    })
  }

  selectDoc(responseCCfDTE_p : ResponseCCfDTE){
    console.log(responseCCfDTE_p)
    this.notacreditodteService.generarnotacreditoDTECodGen(responseCCfDTE_p.numeroControl).subscribe(data => {
      
    })
  }

  buscarPorFecha(){
    let fecha_inicial =
      this.fechaIni.toString().substring(8,10)+"-"+
      this.fechaIni.toString().substring(5,7)+"-"+
      this.fechaIni.toString().substring(0,4);

      let fecha_final =
      this.fechaFin.toString().substring(8,10)+"-"+
      this.fechaFin.toString().substring(5,7)+"-"+
      this.fechaFin.toString().substring(0,4);
      
      console.log("Fecha Inicio: "+fecha_inicial+" - Fecha Fin: "+fecha_final);

      this.cargarDatos(fecha_inicial,fecha_final);
      
  }

  cerrarDialog(){
    this.dialogRef.close();
  }
}
