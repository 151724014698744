export class CtMaDoc{
    idctmadoc : number;
    coddocu : string;
    autfisc : string;
    numfisc : number;
    clasnot : string;
    fecdocu : string;
    codclie : string;
    codsvta : string;
    forpago : number;
    conreci : string;
    numpedi : number;
    fecpedi : string;
    numnrem : number;
    diascre : number;
    rutavta : string;
    totvtas : number;
    totivav : number;
    totiva1 : number;
    totdepp : number;
    totdees : number;
    descfac : number;
    vtalcex : string;
    valpago : number;
    valefec : number;
    codbnco : string;
    numcheq : string;
    coment1 : string;
    coment2 : string;
    limiret : number;
    poriret : number;
    totpeso : number;
    coduser : string;
    tipdoca : string;
    cladoca : string;
    numdoca : string;
    idcotrc : string;
    tracking : number;
    comicob : number;
    comivta : number;
    emihora : string;
    claprod : number;
    cdtrans : string;
    fctrans : string;
    estadoc : string;
    pivarop : number;
    facesta : number;
    codigoGeneracion : string;
	selloRecibido : string;
    fhProcesamiento : string;
    numeroControl : string;
    url_pdf : string;
    url_json : string;
    //Invalidacion
    codigoGeneracionInvalidacion : string;
	selloRecibidoInvalidacion : string;
	invalidado : string;
    tipoinvalidacion : number;
    motivoinvalidacion : number;
    //Notacredito
    notacredito : string;
    codigoGeneracionNotaCredito : string;
    selloRecibidoNotaCredito : string;
    numeroControlNotaCredito : string;
    url_pdf_notacredito : string;
    url_json_notacredito : string;
}