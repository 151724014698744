<div class="example-loading-shade"
*ngIf="isLoadingResults || isRateLimitReached">
<mat-spinner *ngIf="isLoadingResults"></mat-spinner>
<div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
{{msj_spinner}}
</div>
</div>

<div class="main-div">
    <mat-card>
        <mat-grid-list cols="3" rowHeight="100px">
            <mat-grid-tile>
                <button mat-flat-button color="primary"  (click)="obtenerProveedores()">Obtener datos</button>
            </mat-grid-tile>
            <mat-grid-tile>
                <button mat-flat-button color="primary"  (click)="guardarDBF()" *ngIf="isActualizarProvedores">Actualizar</button>
            </mat-grid-tile>
        </mat-grid-list>
    </mat-card>  
    
    <div *ngIf="activeTable">
        <div class="example-header">
            <mat-form-field>
              <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar Sucursal">
            </mat-form-field>
        </div>
        <div>
            <mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                    <mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                                        [aria-label]="checkboxLabel()">
                        </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(row) : null"
                                        [checked]="selection.isSelected(row)"
                                        [aria-label]="checkboxLabel(row)">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="pvcodig" >
                    <mat-header-cell *matHeaderCellDef>Codigo</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.pvcodig}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="pvnombr" >
                    <mat-header-cell *matHeaderCellDef>Nombre</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.pvnombr}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="pvnit" >
                    <mat-header-cell *matHeaderCellDef>Negocio</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.pvnit}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="pvmail" >
                    <mat-header-cell *matHeaderCellDef>Correo</mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.pvmail}} </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)" [ngClass]="getRowClass(row)">
                </mat-row>
            </mat-table>
            <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
            </div>        
    </div>
    
</div>

