import { Component, OnInit, ViewChild } from '@angular/core';
import { Rol } from 'src/app/_model/seguridad/rol';
import { RolService } from 'src/app/_service/seguridad/rol.service';
import { switchMap } from 'rxjs/operators';
import { RolDialogoComponent } from './rol-dialogo/rol-dialogo.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'app-rol',
  templateUrl: './rol.component.html',
  styleUrls: ['./rol.component.css']
})
export class RolComponent implements OnInit {

  isLoadingResults = true;
  isRateLimitReached = true;

  displayedColumns = ['rol_idRol', 'rol_nomrol', 'rol_desrol', 'rol_estado', 'acciones'];
  dataSource: MatTableDataSource<Rol>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private rolService: RolService, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  ngOnInit() {

    this.rolService.rolCambio.subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });

    this.rolService.mensajeCambio.subscribe(data => {
      this.snackBar.open(data, 'AVISO',{
        duration:2000
      });
    });

    this.rolService.listar().subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.isLoadingResults = false;
      this.isRateLimitReached = false;
    });

  }

  openDialog(rol?: Rol) {

    let med = rol != null ? rol : new Rol();

    this.dialog.open(RolDialogoComponent, {
      width: '250px',
      data: med
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  eliminar(rol: Rol) {

    this.rolService.eliminar(rol.rol_idRol).pipe(switchMap(() => {
      return this.rolService.listar();
    })).subscribe(data => {
      this.rolService.rolCambio.next(data);
    });
  }

  getEstado(estado : number){
    let estStr : string;
    if(estado == 1){
      estStr = "Activo";
    }else{
      estStr = "DesActivo";
    }
    return estStr;
  }

}
