import { Injectable } from '@angular/core';
import { Ccmasuc } from 'src/app/_model/cliente/ccmasuc';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Message } from 'src/app/_model/utils/message';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class CcmasucService {

  ccmasucCambio = new Subject<Ccmasuc[]>();
  mensajeCambio = new Subject<string>();

  url: string = `${environment.HOST}/sucursales`;
  
  constructor(private http: HttpClient) { }

  listar(){
    return this.http.get<Ccmasuc[]>(this.url,this.getToken());
  }

  listarPorId(idccmasuc : number){
    return this.http.get<Ccmasuc>(`${this.url}/${idccmasuc}`,this.getToken());
  }

  listaSucursalesCodclie(codclie : String){
    return this.http.get<Ccmasuc[]>(`${this.url}/cod/${codclie}`,this.getToken());
  }

  listaSucursalesiIdccmacl(idccmacl : number){
    return this.http.get<Ccmasuc[]>(`${this.url}/ccmacl/${idccmacl}`,this.getToken());
  }

  registrar(ccmasuc : Ccmasuc){
    return this.http.post(this.url, ccmasuc,this.getToken());
  }

  modificar(ccmasuc : Ccmasuc){
    return this.http.put(this.url, ccmasuc,this.getToken());
  }

  eliminar(idccmasuc : number){
    return this.http.delete(`${this.url}/${idccmasuc}`,this.getToken());
  }

  subirArchivoSucursal(data: File) {
    let formdata : FormData = new FormData();
    formdata.append('file',data);
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);

    return this.http.post(`${this.url}/archivoccmasuc`, formdata,{
      responseType : 'text',
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token,
      })
    });
  }

  ImportarCSV(data: File) {
    let formdata : FormData = new FormData();
    formdata.append('csvfile',data);
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);

    return this.http.post<Message[]>(`${this.url}/importarcsv`, formdata, {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    });
  }

  importarDBF(id : number){
    return this.http.get<Ccmasuc[]>(`${this.url}/importardbf/${id}`,this.getToken());
  }
  GuardarDBF(lista : Ccmasuc[]){
    return this.http.post<Ccmasuc[]>(`${this.url}/guardardbf`, lista, this.getToken());
  }

  buscarporcodigocliente(codclie : string){
    return this.http.get<Ccmasuc>(`${this.url}/buscarporcodigocliente/${codclie}`,this.getToken());
  }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }
}
