import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InmapreService {

  //ccmacliCambio = new Subject<Ccmacli[]>();
  mensajeCambio = new Subject<string>();

  url: string = `${environment.HOST}/listaprecio`;
  
  constructor(private http: HttpClient) { }

  listar(){
    return this.http.get(this.url,this.getToken());
  }

  importarDBF(id : number){
    return this.http.get(`${this.url}/importardbf/${id}`,this.getToken());
  }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }

}
