import { Ccmaven } from './ccmaven';
import { Ccmacli } from './ccmacli';

export class Famaord_Enc{
    idFamaord_Enc : number;
	coddocu : number;
	tipdocu : string;
	cladocu : string;
	numdocu : string;
	clasnot : string;
	tipdafe : string;
	numdafe : string;
	forpago : number;
	numpedi : number;
	fecpedi : string;
	numnrem : number;
	diacred : number;
	rutavta : string;	
	codtien : string;
	estado : number;
	ccmaven : Ccmaven;
	ccmacli : Ccmacli;
	fecentrega : string;
	sumas : number;
	descfactu : number;
	iva : number;
	total : number;
	direntrega : string;
	latitud : string;
	longitud : string;
	altitud : string;
	autorizado : boolean;
	autorizadopor : string;
	sobregiro : boolean;
	sobgautorizadopor : string;

}