import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt/src/jwthelper.service';
import { Subject } from 'rxjs';
import { ResponseCCfDTE } from 'src/app/_model/dte/responseccfdte';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResponseCCFDTEService {

  famaordCambio = new Subject<ResponseCCfDTE[]>();
  mensajeCambio = new Subject<string>();

  url: string = `${environment.HOST}/responseccf_dte`;
  
  constructor(private http: HttpClient) { }

  buscarcoddocu(coddocu : string){
    return this.http.get<ResponseCCfDTE>(`${this.url}/buscarcoddocu/${coddocu}`,this.getToken());
  }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }
}
