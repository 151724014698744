import { WhatsAppMsg } from './../../_model/WhatsAppMsg';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subject } from 'rxjs';
import { WhatsAppUltraMsg } from 'src/app/_model/WhatsAppUltraMsg';
import { Ccmacli_Ccmaven } from 'src/app/_model/vendedor/Ccmacli_Ccmaven';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WhatsappultramsgService {

  ccmacliCcmavenCambio = new Subject<WhatsAppUltraMsg[]>();
  mensajeCambio = new Subject<string>();

  url: string = `${environment.HOST}/whatsappultramsg`;
  
  constructor(private http: HttpClient) { }

  listar(){
    return this.http.get<WhatsAppUltraMsg[]>(this.url,this.getToken());
  }

  listarPorId(idccmacli : number){
    return this.http.get<WhatsAppUltraMsg>(`${this.url}/${idccmacli}`,this.getToken());
  }

  registrar(WhatsAppUltraMsg : WhatsAppUltraMsg){
    return this.http.post<WhatsAppUltraMsg>(this.url, WhatsAppUltraMsg,this.getToken());
  }

  modificar(WhatsAppUltraMsg : WhatsAppUltraMsg){
    return this.http.put<WhatsAppUltraMsg>(this.url, WhatsAppUltraMsg,this.getToken());
  }

  eliminar(idccmacli : number){
    return this.http.delete(`${this.url}/${idccmacli}`,this.getToken());
  }

  enviarmensaje(whatsAppMsg : WhatsAppMsg){
    return this.http.post<Ccmacli_Ccmaven>(`${this.url}/enviarmensaje/`, whatsAppMsg,this.getToken());
  }

  enviarPedido(){
    
  }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }
}
