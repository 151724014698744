import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subject } from 'rxjs';
import { WhatsAppUltraMsgConfig } from 'src/app/_model/WhatsAppUltraMsgConfig';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WhatsappultramsgconfigService {

  ccmacliCcmavenCambio = new Subject<WhatsAppUltraMsgConfig[]>();
  mensajeCambio = new Subject<string>();

  url: string = `${environment.HOST}/whatsappultramsgconfig`;
  
  constructor(private http: HttpClient) { }

  listar(){
    return this.http.get<WhatsAppUltraMsgConfig[]>(this.url,this.getToken());
  }

  listarPorId(id : number){
    return this.http.get<WhatsAppUltraMsgConfig>(`${this.url}/${id}`,this.getToken());
  }

  registrar(whatsAppUltraMsgConfig : WhatsAppUltraMsgConfig){
    return this.http.post<WhatsAppUltraMsgConfig>(this.url, whatsAppUltraMsgConfig,this.getToken());
  }

  modificar(whatsAppUltraMsgConfig : WhatsAppUltraMsgConfig){
    return this.http.put<WhatsAppUltraMsgConfig>(this.url, whatsAppUltraMsgConfig,this.getToken());
  }

  eliminar(id : number){
    return this.http.delete(`${this.url}/${id}`,this.getToken());
  }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }
}
