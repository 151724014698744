import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subject } from 'rxjs/internal/Subject';
import { Cpmaprv } from 'src/app/_model/cpmaprv';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CpmaprvService {

  ccmasucCambio = new Subject<Cpmaprv[]>();
  mensajeCambio = new Subject<string>();

  url: string = `${environment.HOST}/proveedores`;

  constructor(private http: HttpClient) { }

  importarDBF(id : number){
    return this.http.get<any[]>(`${this.url}/importardbf/${id}`,this.getToken());
  }

  GuardarDBF(lista : Cpmaprv[]){
    return this.http.post<Cpmaprv[]>(`${this.url}/guardardbf`, lista, this.getToken());
  }

  listar(){
    return this.http.get<Cpmaprv[]>(this.url,this.getToken());
  }

  listarPorId(id : number){
    return this.http.get<Cpmaprv>(`${this.url}/${id}`,this.getToken());
  }

  eliminar(id : number){
    return this.http.delete(`${this.url}/${id}`,this.getToken());
  }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }
}
