import { switchMap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/_service/seguridad/login.service';
import { MenuService } from 'src/app/_service/seguridad/menu.service';
import { Usuario } from 'src/app/_model/seguridad/usuario';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { DatosempresaService } from 'src/app/_service/datosempresa/datosempresa.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  usuarioAuth : Usuario;
  isLoadingResults = false;
  isRateLimitReached = false;

  constructor(private router: Router, private loginservice : LoginService, private menuService : MenuService,private datosempresaService : DatosempresaService) { }

  ngOnInit() {
    this.usuarioAuth = new Usuario();
    sessionStorage.clear();
  }

  autenticar(){
    let token;
    let decodedToken;

    this.isLoadingResults = true;
    this.isRateLimitReached = true;

    this.loginservice.autenticar(this.usuarioAuth).pipe(switchMap(data =>{
      console.log(data)
      if (data) {
        const helper = new JwtHelperService();
        sessionStorage.setItem(environment.TOKEN_NAME, data.access_token);

        token = sessionStorage.getItem(environment.TOKEN_NAME);
        decodedToken = helper.decodeToken(token);
        
        let res : String = "0";
        return res;
      }else{
        let res : String = "1";
        return res;
      }
    })).subscribe(data => {
      if(data == "0"){
        this.menuService.listarPorUsuario(decodedToken.user_name).subscribe(data1 => {
          this.menuService.menuCambio.next(data1);

          this.datosempresaService.getEmpresa().subscribe(empresa => {
            this.datosempresaService.mensajeCambio.next(empresa);
            this.router.navigate(['dashboard']);
          })
          
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
        });

        
      }else{      
        
      }
    });
    this.isLoadingResults = false;
    this.isRateLimitReached = false;
  }
}
