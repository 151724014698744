<mat-toolbar>Seleccione vendedor</mat-toolbar>

<div class="example-container ">

  <div class="example-header">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar Vendedor">
    </mat-form-field>
  </div>
  <button mat-raised-button color="primary" (click)="consultarPedidos()">Consultar Pedidos</button>

  <mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="codruta">
      <mat-header-cell *matHeaderCellDef mat-sort-header>RUTA</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.codruta}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="nomruta" >
      <mat-header-cell *matHeaderCellDef mat-sort-header>VENDEDOR</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.nomruta}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="acciones">
      <mat-header-cell *matHeaderCellDef > Acciones </mat-header-cell>
      <mat-cell *matCellDef="let row"> 
          <button mat-raised-button color="primary" (click)="seleccionar(row)">CLIENTES</button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;">
    </mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[5]"></mat-paginator>
  <button mat-raised-button color="primary" (click)="closeDialog()">Cerrar</button>
</div>