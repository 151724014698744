import { Ccmacli } from './../_model/ccmacli';
import { Message } from './../_model/utils/message';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class CcmacliService {

  ccmacliCambio = new Subject<Ccmacli[]>();
  mensajeCambio = new Subject<string>();

  url: string = `${environment.HOST}/clientes`;
  
  constructor(private http: HttpClient) { }

  listar(){
    return this.http.get<Ccmacli[]>(this.url,this.getToken());
  }

  listarPorId(idccmacli : number){
    return this.http.get<Ccmacli>(`${this.url}/${idccmacli}`,this.getToken());
  }

  buscarPorVendedor(codruta : string){
    return this.http.get<Ccmacli[]>(`${this.url}/vendedor/${codruta}`,this.getToken());
  }

  registrar(ccmacli : Ccmacli){
    return this.http.post(this.url, ccmacli,this.getToken());
  }

  modificar(ccmacli : Ccmacli){
    return this.http.put(this.url, ccmacli,this.getToken());
  }

  eliminar(idccmacli : number){
    return this.http.delete(`${this.url}/${idccmacli}`,this.getToken());
  }

  buscarPorCodclie(codclie : string){
    return this.http.get<Ccmacli>(`${this.url}/buscarporcodclie/${codclie}`,this.getToken());
  }

  subirArchivoEmpleado(data: File) {
    let formdata : FormData = new FormData();
    formdata.append('file',data);
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    return this.http.post(`${this.url}/archivoccmaclie`, formdata, {
      responseType : 'text',
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    });
  }

  ImportarCSV(data: File) {
    let formdata : FormData = new FormData();
    formdata.append('csvfile',data);

    return this.http.post<Message[]>(`${this.url}/importarcsv`, formdata,this.getToken());
  }

  importarDBF(id : number){
    return this.http.get<Ccmacli[]>(`${this.url}/importardbf/${id}`,this.getToken());
  }

  GuardarDBF(lista : Ccmacli[]){
    return this.http.post<Ccmacli[]>(`${this.url}/guardardbf`, lista, this.getToken());
  }

  Calcularsaldo(ccmacli : Ccmacli){
    return this.http.post<number>(`${this.url}/Calcularsaldo`, ccmacli,this.getToken());
  }

  ClienteSobregirado(ccmacli : Ccmacli){
    return this.http.post<string[]>(`${this.url}/clientesobregirado`, ccmacli,this.getToken());
  }
  
  CalcularsaldoArray(ccmacli : Ccmacli){
    return this.http.post<string>(`${this.url}/Calcularsaldo`, ccmacli,this.getToken());
  }

  verificardatosclienteccf(codclie : string){
    return this.http.get<string>(`${this.url}/verificardatosclienteccf/${codclie}`,this.getToken());
  }

  verificardatosclientecf(codclie : string){
    return this.http.get<string>(`${this.url}/verificardatosclientecf/${codclie}`,this.getToken());
  }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }
}
