import { WhatsappultramsgconfigService } from './../../../_service/whatsapp/whatsappultramsgconfig.service';
import { WhatsAppUltraMsgConfig } from 'src/app/_model/WhatsAppUltraMsgConfig';
import { WhatsAppMsg } from './../../../_model/WhatsAppMsg';
import { WhatsAppUltraMsg } from './../../../_model/WhatsAppUltraMsg';
import { WhatsappultramsgService } from './../../../_service/whatsapp/whatsappultramsg.service';
import { Ccmacli } from './../../../_model/ccmacli';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Ord_Client } from 'src/app/_model/ord_client';
import { Famaord } from 'src/app/_model/famaord';
import { Famaord_Enc } from 'src/app/_model/famaord_enc';

@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.css']
})
export class WhatsappComponent implements OnInit {

  cliente: Ccmacli;
  whatsAppUltraMsg: WhatsAppUltraMsg = new WhatsAppUltraMsg();;
  whatsAppUltraMsgConfig: WhatsAppUltraMsgConfig = new WhatsAppUltraMsgConfig();
  whatsAppMsg : WhatsAppMsg;
  ord : Famaord_Enc;

  constructor(private dialogRef: MatDialogRef<WhatsappComponent>,@Inject(MAT_DIALOG_DATA) private data: Famaord_Enc, private whatsappultramsgService : WhatsappultramsgService,
   private whatsappultramsgconfigService : WhatsappultramsgconfigService) { 
    this.cliente = data.ccmacli;
    this.ord = data;
    this.whatsappultramsgconfigService.listarPorId(1).subscribe(config => {
      this.whatsAppUltraMsgConfig = config;
    })
  }

  ngOnInit(): void {
    //console.log(this.whatsAppUltraMsgConfig);
  }

  Enviar(){
    this.whatsAppUltraMsg = new WhatsAppUltraMsg();
    this.whatsAppUltraMsg.token = "atdjm9e2cyz1ojrl";
    this.whatsAppUltraMsg.telefono = "+503"+this.cliente.celwapp;
    this.whatsAppUltraMsg.caption = "PEDIDO- ";
    this.whatsAppUltraMsg.filename = "Pedido.pdf";
    this.whatsAppUltraMsg.document = "";
    this.whatsAppUltraMsg.idfamaord = this.ord.idFamaord_Enc;

    this.whatsAppMsg = new WhatsAppMsg();
    this.whatsAppMsg.whatsAppUltraMsgConfig = this.whatsAppUltraMsgConfig;
    this.whatsAppMsg.whatsAppUltraMsg = this.whatsAppUltraMsg;
    this.whatsappultramsgService.enviarmensaje(this.whatsAppMsg).subscribe(data =>{
      console.log(data);
    })

    this.cerrarDialog();
  }

  Cerrar(){
    this.cerrarDialog();
  }

  cerrarDialog(){
    this.dialogRef.close({event:'close',data:this.cliente});
  }
}
