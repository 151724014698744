import { SelectCcmaclidialogComponent } from './../../clientes/select-ccmaclidialog/select-ccmaclidialog.component';
import { Ccmaven } from './../../../_model/ccmaven';
import { CcmacliService } from './../../../_service/ccmacli.service';
import { CcmavenService } from 'src/app/_service/ccmaven.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Ccmacli_Ccmaven } from 'src/app/_model/vendedor/Ccmacli_Ccmaven';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-asignarcliente',
  templateUrl: './asignarcliente.component.html',
  styleUrls: ['./asignarcliente.component.css']
})
export class AsignarclienteComponent implements OnInit {

  isLoadingResults = true;
  isRateLimitReached = true;

  lista : Ccmacli_Ccmaven[] = [];

  displayedColumns = ['usuario', 'empleado', 'acciones'];
  dataSource: MatTableDataSource<Ccmaven>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private ccmavenService : CcmavenService, private dialog: MatDialog) { }

  ngOnInit() {
    this.ccmavenService.listar().subscribe(data=>{
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.isLoadingResults = false;
      this.isRateLimitReached = false;
    });

    this.ccmavenService.ccmavenCambio.subscribe(data=>{
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

  clientesDialog(p_ccmaven : Ccmaven){
    this.dialog.open(SelectCcmaclidialogComponent, {
      width: '750px',
      height: '600px',
      data: p_ccmaven
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
