<div class="example-loading-shade"
    *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
    {{msj_spinner}}
    </div>
</div>

<div *ngIf="activeTable">
    <div class="example-header">
        <mat-form-field>
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar Proveedor">
        </mat-form-field>
    </div>
    
    <mat-table [dataSource]="dataSource"  matSort>
        <ng-container matColumnDef="codclie" >
            <mat-header-cell *matHeaderCellDef>Codigo</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.codclie}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="nomclie" >
            <mat-header-cell *matHeaderCellDef>Nombre</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.nomclie}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="mail_dte" >
            <mat-header-cell *matHeaderCellDef>Correo</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.mail_dte}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectProv(row)" [ngClass]="getRowClass(row)">
        </mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</div>    
<div>
    <button mat-flat-button color="primary" (click)="cerrarDialog()">Cerrar</button>
</div>