<div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
      
    </div>
</div>

<div class="main-div">
    <mat-card>
       <div class="example-button-row">
            <button type="button" mat-raised-button  color="primary" onclick="document.getElementById('flArchivo').click()">Importar Archivo Vendedores CSV</button>
            <input id="flArchivo" type="file" accept=".csv" style="display: none;" (change)="subirArchivo($event)">
        </div>
        <div>

        </div>
    </mat-card>
</div>