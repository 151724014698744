import { Ccmaven } from './../_model/ccmaven';
import { Injectable } from '@angular/core';
import { Famaord_Enc } from '../_model/famaord_enc';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class FamaordEncService {

  Famaord_EncCambio = new Subject<Famaord_Enc[]>();
  mensajeCambio = new Subject<string>();

  url: string = `${environment.HOST}/enc_oredenes`;
  
  constructor(private http: HttpClient) { }

  listar(){
    return this.http.get<Famaord_Enc[]>(this.url,this.getToken());
  }

  listarPorId(idFamaord_Enc : number){
    return this.http.get<Famaord_Enc>(`${this.url}/${idFamaord_Enc}`,this.getToken());
  }

  buscarPorNumPedido(numpedido : number){
    return this.http.get<Famaord_Enc>(`${this.url}/pedido/${numpedido}`,this.getToken());
  }

  registrar(famaord : Famaord_Enc){
    return this.http.post<Famaord_Enc>(this.url, famaord,this.getToken());
  }

  modificar(famaord : Famaord_Enc){
    return this.http.put(this.url, famaord,this.getToken());
  }

  eliminar(idFamaord_Enc : number){
    return this.http.delete(`${this.url}/${idFamaord_Enc}`,this.getToken());
  }

  inicializarPedido(idccmacli : number, estado : number, tipdocu : number, cladocu : number, sobregiro : boolean, codruta : string, idccmaven : number ){
    return this.http.get<number>(`${this.url}/inicializarpedido/${idccmacli}/${estado}/${tipdocu}/${cladocu}/${sobregiro}/${codruta}/${idccmaven}`,this.getToken());
  }

  getToken(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return httpOptions
  }
}
