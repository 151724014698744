import { Ccmacli } from './../../../_model/ccmacli';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialogimportardbf',
  templateUrl: './dialogimportardbf.component.html',
  styleUrls: ['./dialogimportardbf.component.css']
})
export class DialogimportardbfComponent implements OnInit {

  clientes : Ccmacli[];

  constructor(private dialogRef: MatDialogRef<DialogimportardbfComponent>,@Inject(MAT_DIALOG_DATA) private data: Ccmacli[]) {
    this.clientes = data;
  }

  ngOnInit(): void {

  }

  closeDialog(opcion : number){
    if(opcion==1){
      this.dialogRef.close({event:'close',data:this.clientes});
    }else{
      this.dialogRef.close();
    } 
  }

  numeroclientes(){
    return this.clientes.length;
  }

}
