import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-descarga-dte',
  templateUrl: './descarga-dte.component.html',
  styleUrls: ['./descarga-dte.component.css']
})
export class DescargaDteComponent implements OnInit {

  isLoading = true;
  showExtraButtons = false;

  constructor() { }

  ngOnInit(): void {
  }

  onLoaderAnimationComplete(): void {
    this.isLoading = false;
  }

  showButtons(): void {
    this.showExtraButtons = true;
  }

}
