<div>
    <mat-card class="example-card center">
        <mat-card-header class="centerContent">
            <mat-card-title>Asignacion de Usuarios</mat-card-title>
        </mat-card-header>
        <form #myform="ngForm" class="example-form">
            <mat-tab-group dynamicHeight >
                <mat-tab label="Asignacion">
                    <mat-grid-list cols="2" >
                        <mat-grid-tile  colspan="1" rowspan="1" >
                            <mat-form-field class="example-full-width">
                                <label for="usuario.username">Usuario</label>
                                <input matInput type="text" [(ngModel)]="usuario.username" name="usuario.username" required readonly>
                            </mat-form-field> 
                        </mat-grid-tile>
                        <mat-grid-tile  colspan="1" rowspan="1" >
                            <mat-form-field class="example-full-width">
                                <label for="ccmaven.nomruta">Vendedor</label>
                                <input matInput type="text" [(ngModel)]="ccmaven.nomruta" name="ccmaven.nomruta" required readonly>
                            </mat-form-field>
                        </mat-grid-tile>
                    </mat-grid-list>
                </mat-tab>
                <mat-tab label="Usuarios">
                        <div class="example-container mat-elevation-z8">

                        <div class="example-header">
                            <mat-form-field>
                            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar usuario">
                            </mat-form-field>
                        </div>

                        <mat-table [dataSource]="dataSource" matSort>

                            <ng-container matColumnDef="username">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Usuario </mat-header-cell>
                            <mat-cell *matCellDef="let row"> {{row.username}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="acciones">
                            <mat-header-cell *matHeaderCellDef>Acciones</mat-header-cell>
                            <mat-cell *matCellDef="let row">        
                                <button mat-button color="warn" (click)="seleccionarUsuario(row)">Selecionar</button>
                            </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;">
                            </mat-row>
                        </mat-table>

                        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                        </div>
                </mat-tab>
                <mat-tab label="Vendedor">
                    <div class="example-container mat-elevation-z8">

                        <div class="example-header">
                            <mat-form-field>
                            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar usuario">
                            </mat-form-field>
                        </div>

                        <mat-table [dataSource]="dataSourceVendedor" matSort>

                            <ng-container matColumnDef="vendedor">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Vendedor </mat-header-cell>
                            <mat-cell *matCellDef="let row"> {{row.nomruta}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="acciones">
                            <mat-header-cell *matHeaderCellDef>Acciones</mat-header-cell>
                            <mat-cell *matCellDef="let row">        
                                <button mat-button color="warn" (click)="selecionarVendedor(row)">Selecionar</button>
                            </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="displayedColumnsVendedor"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumnsVendedor;">
                            </mat-row>
                        </mat-table>

                        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                        </div>
                </mat-tab>
            </mat-tab-group>
        </form>
    </mat-card>
    <mat-card-actions align="center">
        <button type="submit" mat-raised-button [disabled]="myform.invalid" color="primary" (click)="guardar()">Asignar</button>
        <button type="submit" mat-raised-button [disabled]="myform.invalid" color="warn" (click)="cancelar()">Cancelar</button>
    </mat-card-actions>
</div>
