import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Famaord } from 'src/app/_model/famaord';
import { FamaordService } from 'src/app/_service/famaord.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dialog-famaord-desc-prod',
  templateUrl: './dialog-famaord-desc-prod.component.html',
  styleUrls: ['./dialog-famaord-desc-prod.component.css']
})
export class DialogFamaordDescProdComponent implements OnInit {

  famaord : Famaord;
  desc : number;
  desEna : Boolean = false;

  descuento: string;
  tipoDescs: string[] = ['Porcentaje', 'Valor en Dinero'];
  btn_modificar : boolean = true;
  userRol : string;
  mjs_precio : string;
  des_hint : string;

  isLoadingResults = false;
  isRateLimitReached = false;

  constructor(private dialogRef: MatDialogRef<DialogFamaordDescProdComponent>, @Inject(MAT_DIALOG_DATA) private data: Famaord,
  private famaordService : FamaordService,private snackBar: MatSnackBar) { 
    this.famaord = new Famaord();
    this.famaord = data;
    let token;
    let decodedToken;
    const helper = new JwtHelperService();
    token = sessionStorage.getItem(environment.TOKEN_NAME);
    decodedToken = helper.decodeToken(token);
    this.userRol = decodedToken.authorities[0];
  }

  ngOnInit() {
    this.famaordService.mensajeCambio.subscribe(data => {
      this.snackBar.open(data, 'AVISO',{
        duration:2000
      });
    });
  }

  radioChange(){
    this.desEna = true;
    if(this.descuento.includes("Porcentaje")){
      this.desc = this.famaord.desprod;
    }else if(this.descuento.includes("Valor")){
      this.desc = this.famaord.desespe;
    }
  }

  modificar(){
    if(this.descuento.includes("Porcentaje")){
      if(this.famaord.desespe == 0){
        this.famaord.desprod = this.desc;
        this.modificarFamaord(this.famaord);
      }else{
        if(this.admin_btn_modificar(false)){
          this.modificarFamaord(this.famaord);
        }else{
          this.popUP("Ya existe un descuento especial")
        }
      }
    }else if(this.descuento.includes("Valor")){
      if(this.famaord.desprod == 0){
        this.famaord.desespe = this.desc;
        this.modificarFamaord(this.famaord);
      }else{
        if(this.admin_btn_modificar(false)){
          this.modificarFamaord(this.famaord);
        }else{
          this.popUP("Ya existe un descuento por porcentaje")
        }
      }
    }
  }

  cerrar(){
    this.dialogRef.close();
  }

  verificarDescuento(value){
    if(this.descuento.includes("Porcentaje")){
      //CONSUMIDOR FINAL
      if(this.famaord.famaord_enc.cladocu.includes("1")){
        let precioUni = this.famaord.inbogrl.preunid_ln
        let valor : number = precioUni - parseFloat((precioUni*(value/100)).toFixed(4));
        if(valor >= this.famaord.inbogrl.desc_max){
          this.btn_modificar = true;
          this.mjs_precio = "Precio Aprobado";
        }else{
          this.btn_modificar = this.admin_btn_modificar(false);
          this.mjs_precio = "Precio NO Aprobado.";
          let valdesc = ((1- (this.famaord.inbogrl.desc_max/precioUni))*100).toFixed(2);
          this.popUP("Descuento Maximo "+valdesc+"%");
        }
      
      }
      //CREDITO FISCAL
      else if(this.famaord.famaord_enc.cladocu.includes("0")){
        let valor : number = this.famaord.inbogrl.preunid_ln - parseFloat((this.famaord.inbogrl.preunid_ln*(value/100)).toFixed(4));
        if(valor >= this.famaord.inbogrl.desc_max){
          this.btn_modificar = true;
          this.mjs_precio = "Precio Aprobado";
        }else{
          this.btn_modificar = this.admin_btn_modificar(false);
          this.mjs_precio = "Precio NO Aprobado.";
          let valdesc = ((1- (this.famaord.inbogrl.desc_max/this.famaord.inbogrl.preunid_ln))*100).toFixed(2);
          this.popUP("Descuento Maximo "+valdesc+"%");
        }
      }
    }else if(this.descuento.includes("Valor")){
      if(this.famaord.famaord_enc.cladocu.includes("1")){
        if(value <= (this.famaord.inbogrl.preunid_ln - this.famaord.inbogrl.desc_max) ){
          this.btn_modificar = true;
          this.mjs_precio = "Precio Aprobado";
        }else{
          this.btn_modificar = this.admin_btn_modificar(false);
          this.mjs_precio = "Precio NO Aprobado";
          this.popUP(" Descuento Maximo $"+(this.famaord.inbogrl.preunid_ln - this.famaord.inbogrl.desc_max))
        }
        
      }else if(this.famaord.famaord_enc.cladocu.includes("0")){
        if(value <= (this.famaord.inbogrl.preunid_ln - this.famaord.inbogrl.desc_max) ){
          this.btn_modificar = true;
          this.mjs_precio = "Precio Aprobado";
        }else{
          this.btn_modificar = this.admin_btn_modificar(false);
          this.mjs_precio = "Precio NO Aprobado, Descuento Maximo $"+(this.famaord.inbogrl.preunid_ln - this.famaord.inbogrl.desc_max);
          this.popUP(" Descuento Maximo $"+(this.famaord.inbogrl.preunid_ln - this.famaord.inbogrl.desc_max))
        }
      }
    }
  }

  admin_btn_modificar(p_bool : boolean){
    if(this.userRol === "ADMIN"){
      return true
    }else{
      return p_bool
    }

  }

  popUP(msj : string){
    this.snackBar.open(msj, 'AVISO',{
      duration:2000
   });
  }

  modificarFamaord(p_famaord : Famaord){
    this.isLoadingResults = true;
    this.isRateLimitReached = true;
    this.famaordService.modificar(this.famaord).subscribe(() =>{
      this.isLoadingResults = false;
      this.isRateLimitReached = false;
      this.popUP("Descuento Modificado.")
      this.dialogRef.close();
    });
  }

}
