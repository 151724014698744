<div class="example-container mat-elevation-z8">
  <div class="example-loading-shade"
       *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
      
    </div>
  </div>

<div class="example-container mat-elevation-z4">
    <div class="example-header">
        <mat-form-field>
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar Cliente">
        </mat-form-field>
        <div class="example-button-row">
          <!-- <button type="button" class="btn_importarSucursales" mat-raised-button  color="primary" onclick="document.getElementById('flArchivoSucursal').click()">Importar Sucursales</button>
          <input id="flArchivoSucursal" type="file" style="display: none;" (change)="subirArchivoSucursales($event)">

          <button type="button" class="btn_importarClientes" mat-raised-button  color="primary" onclick="document.getElementById('flArchivo').click()">Importar Clientes</button>
          <input id="flArchivo" type="file" style="display: none;" (change)="subirArchivo($event)">

          <button type="button" class="btn_importarClientes" mat-raised-button  color="primary" (click)="IngresarClientes()">Crear Clientes</button> -->
        </div>
    </div>
    <mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="codclie">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Codigo Cliente</mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.codclie}} </mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="nomclie">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Cliente</mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.nomclie}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="acciones">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</mat-header-cell>
           <mat-cell *matCellDef="let row">
            <div class="example-button-row">
              <button mat-raised-button color="primary" (click)="consultaCliente(row)">Detalle</button>  
              <button mat-raised-button color="primary" (click)="selecionarCliente(row)">Sucursales</button>
            </div>
           </mat-cell>
        </ng-container>
                        
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]"></mat-paginator>
</div>
