import { ordenDTO } from './../../_model/DTO/ordenDTO';
import { GetosService } from './../../_service/utils/getos.service';
import { DialogselectvendedorComponent } from './dialogselectvendedor/dialogselectvendedor.component';
import { Ccmacli } from './../../_model/ccmacli';
import { CcmacliccmavenserviceService } from './../../_service/cliente/ccmacliccmavenservice.service';
import { UsuarioService } from 'src/app/_service/seguridad/usuario.service';
import { CcmavenService } from './../../_service/ccmaven.service';
import { DialogPedidoComponent } from './dialog-pedido/dialog-pedido.component';
import { CcmacliService } from './../../_service/ccmacli.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { switchMap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { Ccmaven } from 'src/app/_model/ccmaven';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';


@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.css']
})
export class PedidosComponent implements OnInit {

  isLoadingResults = false;
  isRateLimitReached = false;

  ccmaven : Ccmaven;

  displayedColumns = ['codclie', 'nomclie', 'acciones'];
  dataSource: MatTableDataSource<Ccmacli>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private ccmacliService : CcmacliService, private dialog: MatDialog, private snackBar: MatSnackBar,
    private ccmavenService : CcmavenService, private usuarioService : UsuarioService, private ccmacliccmavenService : CcmacliccmavenserviceService,
    private getosService : GetosService) { 
      this.ccmaven = new Ccmaven();
      this.getVendedor();
    }

  ngOnInit() {
    this.ccmacliService.ccmacliCambio.subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.filterPredicate = function(data, filter: string): boolean {
        let nombre : string
        if(data.nomnego !+ null){
          nombre = data.nomclie.toLowerCase();
        }else{
          nombre = nombre +"";
        }
        let codigo : string;
        if(data.codclie != null){
          codigo = data.codclie.toString();
        }else{
          codigo = "";
        }
        return codigo === filter || nombre.toLowerCase().includes(filter);
      };
      this.dataSource.sort = this.sort;
    });

    this.ccmacliService.mensajeCambio.subscribe(data => {
      this.snackBar.open(data, 'AVISO',{
        duration:2000
      });
    });
    
  }

  getVendedor(){
    const helper = new JwtHelperService();
    let token = sessionStorage.getItem(environment.TOKEN_NAME);
    let decodedToken = helper.decodeToken(token);
    //Aqui poner que si no es un vendedor pero es ADMIN o RECEPCION el usuario, pregunte que vendedor quiere seleccionar
    console.log(decodedToken.user_name)
    this.usuarioService.buscarPorNombre(decodedToken.user_name).subscribe(data => {
      
      if(decodedToken.authorities[0].includes("Vendedor Rutero") || decodedToken.authorities[0].includes("Vendedor Mayorista")){
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
        console.log(data.usr_idUsuario)
        this.ccmavenService.buscarPorUsuario(data.usr_idUsuario).subscribe(data2 => {
          this.ccmaven = data2.ccmaven;
          console.log(this.ccmaven)
          this.ccmacliService.buscarPorVendedor(this.ccmaven.codruta).subscribe(data => {
            this.ccmacliService.ccmacliCambio.next(data);
            this.isLoadingResults = false;
            this.isRateLimitReached = false;
          });
        });
      }else{
        this.seleccionarVendedor()
      }
      
    });
    return "Vendedor";
  }

  openDialog(ccmacli?: Ccmacli) {
    
    let ordendto : ordenDTO = new ordenDTO();
    ordendto.ccmacli = ccmacli != null ? ccmacli : new Ccmacli();
    ordendto.ccmaven = this.ccmaven;
    this.dialog.open(DialogPedidoComponent, {
      width: '450px',
      disableClose: true,
      data: ordendto
    });
    
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  seleccionarVendedor(){
    const dialogRef = this.dialog.open(DialogselectvendedorComponent, {
      width: '650px',
      disableClose: true
    });
    
    dialogRef.afterClosed().subscribe(result => {
      this.isLoadingResults = true;
      this.isRateLimitReached = true;
      if(result == undefined){
        this.isLoadingResults = false;
          this.isRateLimitReached = false;
      }else{
        this.ccmaven = result.data;
        console.log(this.ccmaven);
        this.ccmacliService.buscarPorVendedor(result.data.codruta).subscribe(data => {
          this.ccmacliService.ccmacliCambio.next(data);
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
        });
      }
    });
  }
}