<div class="example-loading-shade"
       *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
      
    </div>
  </div>

<mat-toolbar>Usuarios</mat-toolbar>

<div class="example-container mat-elevation-z8">

  <div class="example-header">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar usuario">
    </mat-form-field>
  </div>

  <mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="nombre">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.nombre}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="usr_usunomb">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Usuario </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.username}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef mat-sort-header> E-Mail </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.email}} </mat-cell>
    </ng-container>
    
    <ng-container matColumnDef="rol">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Rol </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.rol.rol_nomrol}} </mat-cell>
    </ng-container>
    
    <ng-container matColumnDef="usr_estado">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Estado </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.usr_estado}} </mat-cell>
    </ng-container>


    <ng-container matColumnDef="acciones">
      <mat-header-cell *matHeaderCellDef>Acciones</mat-header-cell>
      <mat-cell *matCellDef="let row">        
        <button mat-button color="primary" (click)="openDialog(row)">Actualizar</button>
        <button mat-button color="accent" (click)="eliminar(row)">Eliminar</button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;">
    </mat-row>
  </mat-table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>

<br>

<button mat-fab color="accent" (click)="openDialog()" style="float: right; margin-right: 20px">
  <mat-icon>create</mat-icon>
</button>