<div class="example-container mat-elevation-z4">
    <div class="example-header">
        <mat-form-field>
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar Sucursal">
        </mat-form-field>
    </div>
    <mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="codclie">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Codigo Cliente</mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.codclie}} </mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="nomsucs">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Sucursal</mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.nomsucs}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ciusucs">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Ciudad</mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.ciusucs}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="dirsucs">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Direccion</mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.dirsucs}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="acciones">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</mat-header-cell>
           <mat-cell *matCellDef="let row">
              
           </mat-cell>
        </ng-container>
                        
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]"></mat-paginator>
</div>
