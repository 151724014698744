import { Component, OnInit, Inject } from '@angular/core';
import { DialogFamaordDescProdComponent } from '../dialog-famaord-desc-prod/dialog-famaord-desc-prod.component';
import { FamaordService } from 'src/app/_service/famaord.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-dialog-famaord-desc-factu',
  templateUrl: './dialog-famaord-desc-factu.component.html',
  styleUrls: ['./dialog-famaord-desc-factu.component.css']
})
export class DialogFamaordDescFactuComponent implements OnInit {

  descuentoFactura : number;
  isLoadingResults = false;
  isRateLimitReached = false;

  constructor(private dialogRef: MatDialogRef<DialogFamaordDescProdComponent>,@Inject(MAT_DIALOG_DATA) private data: number,
  private famaordService : FamaordService,private snackBar: MatSnackBar) { 
    this.descuentoFactura = data;
  }

  ngOnInit() {
    this.famaordService.mensajeCambio.subscribe(data => {
      this.snackBar.open(data, 'AVISO',{
        duration:2000
      });
    });
  }

  modificar(){
    this.dialogRef.close({event:'close',data:this.descuentoFactura});
  }
}
