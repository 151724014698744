import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Famaord } from 'src/app/_model/famaord';
import { FamaordService } from 'src/app/_service/famaord.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dialog-famaord-pre',
  templateUrl: './dialog-famaord-pre.component.html',
  styleUrls: ['./dialog-famaord-pre.component.css']
})
export class DialogFamaordPreComponent implements OnInit {

  famaord : Famaord;
  mjs_precio : string;
  btn_modificar : boolean = true;
  userRol : string;
  isLoadingResults = false;
  isRateLimitReached = false;

  constructor(private dialogRef: MatDialogRef<DialogFamaordPreComponent>, @Inject(MAT_DIALOG_DATA) private data: Famaord,
  private famaordService : FamaordService,private snackBar: MatSnackBar) { 
    this.famaord = new Famaord();
    this.famaord = data;
    let token;
    let decodedToken;
    const helper = new JwtHelperService();
    token = sessionStorage.getItem(environment.TOKEN_NAME);
    decodedToken = helper.decodeToken(token);
    this.userRol = decodedToken.authorities[0];
  }

  ngOnInit() {
    this.famaordService.mensajeCambio.subscribe(data => {
      this.snackBar.open(data, 'AVISO',{
        duration:2000
      });
    });
  }

  modificar(){
    if(this.famaord.famaord_enc.cladocu.includes("1")){
      let valor : number = parseFloat((this.famaord.valnuni/1.13).toFixed(6));
      this.famaord.valnuni = valor;
      this.isLoadingResults = true;
      this.isRateLimitReached = true;
      this.famaordService.modificar(this.famaord).subscribe(() =>{
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
        this.dialogRef.close();
      });
    }else if(this.famaord.famaord_enc.cladocu.includes("0")){
      this.isLoadingResults = true;
      this.isRateLimitReached = true;
      this.famaordService.modificar(this.famaord).subscribe(() =>{
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
        this.dialogRef.close();
      });
    }
  }

  cerrar(){
    this.dialogRef.close();
  }

  verificarDescuento(value){
    if(this.famaord.famaord_enc.cladocu.includes("1")){
      let valor : number = parseFloat((this.famaord.valnuni/1.13).toFixed(6));
      if(value >= this.famaord.inbogrl.desc_max){
        this.btn_modificar = true;
        this.mjs_precio = "Precio Aprobado";
      }else{
        this.btn_modificar = this.admin_btn_modificar(false);;
        this.mjs_precio = "Precio NO Aprobado";
      }
      
    }else if(this.famaord.famaord_enc.cladocu.includes("0")){
      if(value >= this.famaord.inbogrl.desc_max){
        this.btn_modificar = true;
        this.mjs_precio = "Precio Aprobado";
      }else{
        this.btn_modificar = this.admin_btn_modificar(false);
        this.mjs_precio = "Precio NO Aprobado";
      }
    }
  }

  admin_btn_modificar(p_bool : boolean){
    if(this.userRol === "ADMIN"){
      return true
    }else{
      return p_bool
    }

  }
}
